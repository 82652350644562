import { useTranslation } from "react-multi-lang";

const EmptyOrder = () => {
  const t = useTranslation();
  return (
    <div className="w-full section-white text-center p-3">
      <h4 className="font-bold text-xl">{t("orders.order_empty_title")}</h4>
      <p className="text-lg">{t("orders.order_empty_subtitle")}</p>
      <img
        className="w-full max-h-[29rem] mt-3"
        src={process.env.REACT_APP_ASSETS_IMAGE + "empty-order.svg"}
        alt="empty-order"
      />
    </div>
  );
};

export default EmptyOrder;
