import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import ButtonSort from "../../../components/ButtonSort/ButtonSort";

import Action from "../redux/Action";
import Enum from "./Enum";

const Tabel = () => {
    const t = useTranslation();
    const dispatch = useDispatch();

    const list = useSelector((state) => state.reducer.list);
    const detailComplaint = useSelector((state) => state.reducer.detailComplaint);
    const postPerPage = useSelector(state => state.reducer.postPerPage);
    const search = useSelector(state => state.reducer.search);
    const statusFilter = useSelector(state => state.reducer.statusFilter);
    const fieldName = useSelector(state => state.reducer.fieldName);
    const orderBy = useSelector(state => state.reducer.orderBy);
    const loading = useSelector(state => state.reducer.loading);
    const startDateFilter = useSelector(state => state.reducer.startDateFilter);
    const endDateFilter = useSelector(state => state.reducer.endDateFilter);

    const customStyles = {
        transform: "scale(1.02)",
        boxShadow: "0px 15px 23px -8px rgba(0,0,0,0.1)"
    };

    const BorderCustom = () => {
        const customStyle = {
            top: "0px",
            left: "0px",
            width: "5px",
            background: "#144632",
            height: "100%",
            position: "absolute",
        };
        return (<span style={customStyle}></span>);
    };

    if (loading) {
        return <Skeleton count={7} height="40px" />;
    }

    return (
        <table className="table-auto w-full text-left">
            <thead>
                <tr className="text-green-secondary font-semibold">
                    {/* DATE */}
                    <th className="p-2 my-2">
                        <ButtonSort
                            title={t("complaints.date")}
                            isAsc={
                                fieldName === "date"
                                    ? orderBy === "asc"
                                    : false
                            }
                            isDesc={
                                fieldName === "date"
                                    ? orderBy === "desc"
                                    : false
                            }
                            sortAsc={() => {
                                Action.getList(dispatch, {
                                    fieldName: "date",
                                    orderBy: "asc",
                                    startDateFilter,
                                    endDateFilter,
                                    currentPage: 0,
                                    postPerPage,
                                    search,
                                    statusFilter,
                                });
                            }}
                            sortDesc={() => {
                                Action.getList(dispatch, {
                                    fieldName: "date",
                                    orderBy: "desc",
                                    startDateFilter,
                                    endDateFilter,
                                    currentPage: 0,
                                    search,
                                    postPerPage,
                                    statusFilter,
                                });
                            }}
                        />
                    </th>
                    {/* COMPLAINT ID */}
                    <th>
                        <ButtonSort
                            title={t("complaints.complaint_id")}
                            isAsc={
                                fieldName === "complaintId"
                                    ? orderBy === "asc"
                                    : false
                            }
                            isDesc={
                                fieldName === "complaintId"
                                    ? orderBy === "desc"
                                    : false
                            }
                            sortAsc={() => {
                                Action.getList(dispatch, {
                                    fieldName: "complaintId",
                                    orderBy: "asc",
                                    startDateFilter,
                                    endDateFilter,
                                    currentPage: 0,
                                    postPerPage,
                                    search,
                                    statusFilter,
                                });
                            }}
                            sortDesc={() => {
                                Action.getList(dispatch, {
                                    fieldName: "complaintId",
                                    orderBy: "desc",
                                    startDateFilter,
                                    endDateFilter,
                                    currentPage: 0,
                                    search,
                                    postPerPage,
                                    statusFilter,
                                });
                            }}
                        />
                    </th>
                    {/* CUSTOMER NAME */}
                    <th>
                        <ButtonSort
                            title={t("complaints.customer_name")}
                            isAsc={
                                fieldName === "customerName"
                                    ? orderBy === "asc"
                                    : false
                            }
                            isDesc={
                                fieldName === "customerName"
                                    ? orderBy === "desc"
                                    : false
                            }
                            sortAsc={() => {
                                Action.getList(dispatch, {
                                    fieldName: "customerName",
                                    orderBy: "asc",
                                    startDateFilter,
                                    endDateFilter,
                                    currentPage: 0,
                                    postPerPage,
                                    search,
                                    statusFilter,
                                });
                            }}
                            sortDesc={() => {
                                Action.getList(dispatch, {
                                    fieldName: "customerName",
                                    orderBy: "desc",
                                    startDateFilter,
                                    endDateFilter,
                                    currentPage: 0,
                                    search,
                                    postPerPage,
                                    statusFilter,
                                });
                            }}
                        />
                    </th>
                    {/* CATEGORY */}
                    <th>
                        <ButtonSort
                            title={t("complaints.category")}
                            isAsc={
                                fieldName === "category"
                                    ? orderBy === "asc"
                                    : false
                            }
                            isDesc={
                                fieldName === "category"
                                    ? orderBy === "desc"
                                    : false
                            }
                            sortAsc={() => {
                                Action.getList(dispatch, {
                                    fieldName: "category",
                                    orderBy: "asc",
                                    startDateFilter,
                                    endDateFilter,
                                    currentPage: 0,
                                    postPerPage,
                                    search,
                                    statusFilter,
                                });
                            }}
                            sortDesc={() => {
                                Action.getList(dispatch, {
                                    fieldName: "category",
                                    orderBy: "desc",
                                    startDateFilter,
                                    endDateFilter,
                                    currentPage: 0,
                                    search,
                                    postPerPage,
                                    statusFilter,
                                });
                            }}
                        />
                    </th>
                    {/* STATUS */}
                    <th>
                        <ButtonSort
                            title={t("complaints.status")}
                            isAsc={
                                fieldName === "status"
                                    ? orderBy === "asc"
                                    : false
                            }
                            isDesc={
                                fieldName === "status"
                                    ? orderBy === "desc"
                                    : false
                            }
                            sortAsc={() => {
                                Action.getList(dispatch, {
                                    fieldName: "status",
                                    orderBy: "asc",
                                    startDateFilter,
                                    endDateFilter,
                                    currentPage: 0,
                                    postPerPage,
                                    search,
                                    statusFilter,
                                });
                            }}
                            sortDesc={() => {
                                Action.getList(dispatch, {
                                    fieldName: "status",
                                    orderBy: "desc",
                                    startDateFilter,
                                    endDateFilter,
                                    currentPage: 0,
                                    search,
                                    postPerPage,
                                    statusFilter,
                                });
                            }}
                        />
                    </th>
                </tr>
            </thead>
            <tbody className="font-medium">
                {list.map((item, index) => (
                    <tr
                        key={index}
                        className="border-4 border-gray-primary bg-white cursor-pointer"
                        style={detailComplaint.id === item.id ? customStyles : {}}
                        onClick={() => {
                            if (detailComplaint.id !== item.id) {
                                Action.changeShowDetail(dispatch, true, item.id);
                            } else {
                                Action.changeShowDetail(dispatch, false);
                            }
                        }}>
                        <td className="p-2 my-2 relative">
                            {detailComplaint.id === item.id ? <BorderCustom /> : ""}
                            {item.date}
                        </td>
                        <td>{item.complaintId}</td>
                        <td>{item.customerName}</td>
                        <td>{item.category}</td>
                        <td>{Enum.status(t, item.status)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Tabel;
