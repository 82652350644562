import React from "react";

const ButtonGrey = ({ title, onClick }) => {
  return (
    <>
      <button
        className="button md:w-36 hover:bg-gray-primary hover:text-grey-button text-xs tracking-widest font-bold text-gray-primary border-2 border-grey-button bg-grey-button border-opacity-40 rounded-xl py-2"
        onClick={(e) => {
          onClick(e);
        }}
        type="submit"
      >
        {title.toUpperCase()}
      </button>
    </>
  );
};

export default ButtonGrey;
