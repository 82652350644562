import React from "react";

const Status = ({ status, changeStatus, statusProduct, type }) => {
  let filterTimeout;
  let defaultChecked;
  if (type === "variant") {
    defaultChecked = status;
  } else if (type === "product") {
    defaultChecked = status === 1 || status === 2 || status === "-1" ? true : false;
  }

  return (
    <label className={`inline-flex relative items-center ${statusProduct ? "opacity-50" : " cursor-pointer"}`}>
      <input
        type="checkbox"
        name="status"
        disabled={statusProduct}
        defaultChecked={defaultChecked}
        onChange={(e) => {
          if (!statusProduct) {
            clearTimeout(filterTimeout);
            filterTimeout = setTimeout(() => {
              if (type === "variant") {
                changeStatus(e.target.checked === true);
              } else if (type === "product") {
                changeStatus(e.target.checked === true ? 1 : 0);
              }
            }, 100);
          }
        }}
        className="sr-only peer "
      />
      <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-primary"></div>
    </label>
  );
};

export default Status;
