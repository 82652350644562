import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "holidayDate",
  initialState: {
    showForm: false,
    event: {},
    show: false,
  },
  reducers: {
    setShowForm: (state, action) => {
      state.showForm = action.payload;
    },
    setEvent: (state, action) => {
      state.event = action.payload;
    },
    setShow: (state, action) => {
      state.show = action.payload;
    },
  },
});

export const { setShowForm, setEvent, setShow } = reducerSlice.actions;
export default reducerSlice;
