import React, { useState } from "react";
import Stock from "../Stock";
import StatusVariant from "./StatusVariant";
import PriceVariant from "./PriceVariant";
import PromoPriceVariant from "./PromoPriceVariant";
import WeightVariant from "./WeightVariant";
import GeneralUtility from "../../../../utils/general-utility";

const TableVariant = ({ result, item, index, setTabel, image, typeAction }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleChange = (index, type, value) => {
    let data = [...result];
    if (type === "stock") {
      data[index].stock = GeneralUtility.decimalToInt(value);
    }

    if (type === "normalPrice") {
      data[index].normalPrice = GeneralUtility.decimalToInt(value);
    }

    if (type === "promotionalPrice") {
      data[index].promotionalPrice = GeneralUtility.decimalToInt(value);
    }

    if (type === "status") {
      data[index].status = value;
    }

    if (type === "weight") {
      data[index].weight = value;
    }

    if (type === "main") {
      for (let i = 0; i < data.length; i++) {
        data[i].main = false;
      }
      data[index].main = value;
    }

    setTabel(data);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <tr
      key={index}
      className={`${isHovered ? "bg-green-variant" : "bg-white"
        } "border-4 border-gray-primary cursor-pointer rounded-lg"`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {item.image || item.color ? (
        <td className={`p-2 my-2 ${typeAction === "edit" ? "opacity-50" : ""}`}>
          <div className="w-[4.5em] h-[4.5em] border-2 border:bg-gray-primary rounded-xl">
            <img
              className="w-full h-full rounded-xl"
              src={item.image ?? image}
              alt={item.alt}
            />
          </div>
        </td>
      ) : (
        ""
      )}
      {item.size ? (
        <td className={`p-2 my-2 ${typeAction === "edit" ? "opacity-50" : ""}`} width={70}>
          {item.size}
        </td>
      ) : null}
      {item.color ? (
        <td className={`p-2 my-2 ${typeAction === "edit" ? "opacity-50" : ""}`} width={70}>
          {item.color}
        </td>
      ) : null}
      <td className="p-2 my-2">
        <Stock
          stock={item.stock ?? 0}
          status={false}
          changeStock={(value) => {
            handleChange(index, "stock", value);
          }}
        />
      </td>
      <td className="p-2 my-2">
        <PriceVariant
          price={item.normalPrice ?? 0}
          status={false}
          changePrice={(value) => {
            handleChange(index, "normalPrice", value);
          }}
        />
      </td>
      <td className="p-2 my-2">
        <PromoPriceVariant
          promoPrice={item.promotionalPrice ?? 0}
          status={false}
          changePromoPrice={(value) => {
            handleChange(index, "promotionalPrice", value);
          }}
        />
      </td>
      <td className="p-2 my-2">
        <WeightVariant
          weight={item.weight ?? ""}
          changeWeight={(value) => {
            handleChange(index, "weight", value);
          }}
        />
      </td>
      <td className="p-2 my-2">
        {!item.main ? (
          <StatusVariant
            status={item.status}
            changeStatus={(value) => {
              handleChange(index, "status", value);
            }}
          />
        ) : ""}
      </td>
      {isHovered ? (
        <td
          className="p-2 my-2 text-center hover:text-green-primary hover:underline"
          width={100}
          onClick={() => {
            handleChange(index, "main", true);
          }}
        >
          Set As Main
        </td>
      ) : (
        <td className="p-2 my-2 text-center" width={100}>
          {item.main ? "Main" : ""}
        </td>
      )}
    </tr>
  );
};

export default TableVariant;
