import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "feedback",
  initialState: {
    loading: false,
    categoryList: [],
    category: "",
    topicList: [],
    topic: "",
    remarks: "",
    formErrors: {}
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setTopicList: (state, action) => {
      state.topicList = action.payload;
    },
    setTopic: (state, action) => {
      state.topic = action.payload;
    },
    setRemarks: (state, action) => {
      state.remarks = action.payload;
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    }
  },
});

export const {
  setLoading,
  setCategoryList,
  setCategory,
  setTopicList,
  setTopic,
  setRemarks,
  setFormErrors,
} = reducerSlice.actions;

export default reducerSlice;
