import React from "react";

const InputTextCustom = ({
  placeholder,
  name,
  value,
  error,
  onChange,
  maxLength,
  unit,
  disabled,
  onFocus
}) => {
  return (
    <>
      <label className="relative block">
        <input
          disabled={disabled}
          type="text"
          name={name}
          onChange={(e) => {
            onChange(e);
          }}
          placeholder={placeholder}
          className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${error ? "border-red-500 border-1" : "border-0"
            }`}
          value={value}
          maxLength={maxLength}
          onFocus={(e) => {
            if (onFocus) {
              onFocus(e);
            }
          }}
        />
        <span className="absolute inset-y-0 right-2.5 flex items-center">
          <p className="font-bold text-green-secondary">{unit}</p>
        </span>
      </label>
      <p className="text-red-500">{error}</p>
    </>
  );
};

export default InputTextCustom;
