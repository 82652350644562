import { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import { Provider, useDispatch, useSelector } from "react-redux";
import StoreHelper from "../../../../services/store-helper";
import Action from "./redux/Action";
import reducerSlice from "./redux/Reducer";
import ButtonGreen from "../../../../components/ButtonGreen/ButtonGreen";
import Cookies from "js-cookie";

const Feedback = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  const MainComponent = () => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.reducer.loading);
    const categoryList = useSelector((state) => state.reducer.categoryList);
    const topicList = useSelector((state) => state.reducer.topicList);
    const category = useSelector((state) => state.reducer.category);
    const topic = useSelector((state) => state.reducer.topic);
    const remarks = useSelector((state) => state.reducer.remarks);
    const formErrors = useSelector((state) => state.reducer.formErrors);

    useEffect(() => {
      // hapus cookies supaya tidak selalu pilih tab feeback 
      Cookies.remove("redirectedPageBySuspendAccount");
      Action.getCategoryAndTopic(store);
    }, []);

    return (
      <div className="bg-gray-primary rounded-xl p-3">
        {loading ? (
          <Skeleton count={3} height={"2rem"} />
        ) : (
          <div className="m-2">
            <label className="w-full flex gap-x-3 mb-3">
              <div className="basis-1/3">
                <p className="text-md font-bold my-2">
                  {t("helpcenter.category")}
                </p>
                <p>{t("helpcenter.category_subtitle")}</p>
              </div>
              <div className="basis-2/3">
                <select
                  onChange={(e) => {
                    Action.changeCategory(dispatch, {
                      category: e.target.value,
                    });
                  }}
                  className="px-3 w-full block sm:text-sm shadow-sm border border-gray-primary rounded-xl bg-white placeholder:text-slate-40"
                  value={category}
                >
                  <option hidden value="">
                    {t("helpcenter.dropdown_placeholder")}
                  </option>
                  {categoryList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                <p className=" text-red-500">{formErrors.category}</p>
              </div>
            </label>
            <label className="w-full flex gap-x-3 mb-3">
              <div className="basis-1/3">
                <p className="text-md font-bold my-2">
                  {t("helpcenter.topic")}
                </p>
                <p>{t("helpcenter.topic_subtitle")}</p>
              </div>
              <div className="basis-2/3">
                <select
                  onChange={(e) => {
                    Action.changeTopic(dispatch, { topic: e.target.value });
                  }}
                  className="px-3 w-full block sm:text-sm shadow-sm border border-gray-primary rounded-xl bg-white placeholder:text-slate-40"
                  value={topic}
                >
                  <option hidden value="">
                    {t("helpcenter.dropdown_placeholder")}
                  </option>
                  {topicList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                <p className=" text-red-500">{formErrors.topic}</p>
              </div>
            </label>
            <label className="w-full flex gap-x-3 mb-3">
              <div className="basis-1/3">
                <p className="text-md font-bold my-2">
                  {t("helpcenter.remarks")}
                </p>
                <p>{t("helpcenter.max_char")}</p>
              </div>
              <div className="basis-2/3">
                <textarea
                  className="w-full shadow-sm border border-gray-primary rounded-xl bg-white placeholder:text-slate-40 text-sm max-h-[10rem]"
                  rows="4"
                  maxLength="300"
                  placeholder={t("helpcenter.remarks")}
                  value={remarks}
                  onChange={(e) => {
                    Action.changeRemarks(dispatch, { remarks: e.target.value });
                  }}
                />
                <p className=" text-red-500">{formErrors.remarks}</p>
              </div>
            </label>
            <div className="flex justify-end">
              <ButtonGreen
                title={t("general.send")}
                onClick={() =>
                  Action.sendForm(dispatch, {
                    category: category,
                    topic: topic,
                    remarks: remarks,
                  })
                }
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default Feedback;
