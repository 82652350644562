import React from "react";
import { useTranslation } from "react-multi-lang";
import Action from "../redux/Action";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";

const HeaderTabel = ({
    datePickerOnOpen,
    datePickerOnChange,
    datePickerOnClose
}) => {
    const t = useTranslation();
    const dispatch = useDispatch();

    const startDateFilter = useSelector(state => state.reducer.startDateFilter);
    const endDateFilter = useSelector(state => state.reducer.endDateFilter);
    const postPerPage = useSelector(state => state.reducer.postPerPage);
    const search = useSelector(state => state.reducer.search);
    const statusFilter = useSelector(state => state.reducer.statusFilter);
    const fieldName = useSelector(state => state.reducer.fieldName);
    const orderBy = useSelector(state => state.reducer.orderBy);

    let filterTimeout;
    return (
        <>
            <h4 className="flex-1 opacity-60 font-bold">
                {t("complaints.list").toUpperCase()}
            </h4>
            <label className="relative block">
                <span className="absolute inset-y-0 left-1.5 flex items-center pl-2">
                    <img
                        className="h-3.5 w-5 fill-slate-300"
                        src={process.env.REACT_APP_ASSETS_IMAGE + "username-icon.svg"}
                        alt="username-icon"
                    />
                </span>
                <input
                    type="text"
                    placeholder={t("complaints.search")}
                    className="pl-10 pr-3 w-full block sm:text-sm shadow-sm border border-gray-primary rounded-xl bg-white  placeholder:text-slate-40 "
                    onChange={(e) => {
                        clearTimeout(filterTimeout);
                        filterTimeout = setTimeout(() => {
                            Action.getList(dispatch, {
                                currentPage: 0,
                                search: e.target.value,
                                postPerPage,
                                statusFilter,
                                fieldName,
                                orderBy,
                                startDateFilter,
                                endDateFilter
                            });
                        }, 200);
                    }}
                />
            </label>
            <DateRangePicker
                startDateValue={startDateFilter}
                endDateValue={endDateFilter}
                onOpen={() => {
                    datePickerOnOpen();
                }}
                onChange={(dateObject) => {
                    datePickerOnChange(dateObject);
                }}
                onClose={() => {
                    datePickerOnClose();
                }}
                format="DD MMM YYYY"
                placeholder={t("buyback.date_range_placeholder")}
                inputClass="block sm:text-sm shadow-sm border border-gray-primary rounded-xl bg-white  placeholder:text-slate-40 cursor-pointer"
            />
            <select
                className="sm:text-sm shadow-sm border border-gray-primary rounded-xl"
                name="status"
                id="status"
                onChange={(e) => {
                    Action.getList(dispatch, {
                        currentPage: 0,
                        statusFilter: e.target.value.toString(),
                        postPerPage,
                        search,
                        fieldName,
                        orderBy,
                        startDateFilter,
                        endDateFilter
                    });
                }}
            >
                <option value="">{t("complaints.all_status")}</option>
                <option value="-1">{t("complaints.declined")}</option>
                <option value="0">{t("complaints.opened")}</option>
                <option value="4">{t("complaints.completed")}</option>
                <option value="5">{t("complaints.investigation")}</option>
            </select>
            <div>
                <label htmlFor="postperview" className="pr-2 text-sm font-medium">
                    {t("complaints.view")}
                </label>
                <select
                    className="sm:text-sm shadow-sm border border-gray-primary rounded-xl"
                    name="postperview"
                    id="postperview"
                    onChange={(e) => {
                        Action.getList(dispatch, {
                            currentPage: 0,
                            search,
                            statusFilter,
                            postPerPage: parseInt(e.target.value),
                            fieldName,
                            orderBy,
                            startDateFilter,
                            endDateFilter
                        });
                    }}
                >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                </select>
            </div>
        </>
    );
};

export default HeaderTabel;
