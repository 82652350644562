import React, { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { Provider, useDispatch, useSelector } from "react-redux";
import ButtonGold from "../../components/ButtonGold/ButtonGold";
import StoreHelper from "../../services/store-helper";
import Active from "./components/active/Active";
import Add from "./components/addForm/Add";
import All from "./components/all/All";
import Unactive from "./components/unactive/Unactive";
import Action from "./redux/Action";
import reducerSlice from "./redux/Reducer";
import Takedown from "./components/takedown/Takedown";

const Product = ({ getBreadcrums }) => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  const MainComponent = () => {
    store.getState();
    const t = useTranslation();
    const dispatch = useDispatch();
    const page = useSelector((state) => state.reducer.page);
    const id = useSelector((state) => state.reducer.id);
    const typeForm = useSelector((state) => state.reducer.typeForm);

    const nav = [
      { title: t("products.all_product"), route: "all" },
      { title: t("products.active"), route: "active" },
      { title: t("products.inactive"), route: "inactive" },
      { title: t("products.takedown"), route: "takedown" },
    ];

    const Content = () => {
      if (page === "all") {
        return (
          <All
            onEdit={(id, typeForm) => {
              Action.onEdit(dispatch, id, typeForm);
            }}
          />
        );
      } else if (page === "active") {
        return (
          <Active
            onEdit={(id, typeForm) => {
              Action.onEdit(dispatch, id, typeForm);
            }}
          />
        );
      } else if (page === "inactive") {
        return (
          <Unactive
            onEdit={(id, typeForm) => {
              Action.onEdit(dispatch, id, typeForm);
            }}
          />
        );
      } else if (page === "takedown") {
        return (
          <Takedown
            onEdit={(id, typeForm) => {
              Action.onEdit(dispatch, id, typeForm);
            }}
          />
        );
      } else if (page === "add") {
        return (
          <Add
            type="add"
            onSucces={() => {
              Action.getPage(dispatch, { page: "all" });
            }}
          />
        );
      } else if (page === "edit") {
        return (
          <Add
            type="edit"
            id={id}
            cat={typeForm}
            onSucces={() => {
              Action.getPage(dispatch, { page: "all" });
            }}
          />
        );
      }
    };

    useEffect(() => {
      getBreadcrums(t("products.title"));
    }, []);

    return (
      <>
        <div className="flex flex-row space-x-0">
          <div className="flex flex-row items-center px-2 gap-x-2">
            <img
              className="h-full w-6"
              src={process.env.REACT_APP_ASSETS_IMAGE + "products-icon.svg"}
              alt="products-icon"
            />
            <h2 className="text-green-primary font-bold">
              {page === "all" ||
                page === "active" ||
                page === "inactive" ||
                page === "takedown"
                ? t("products.title")
                : ""}
              {page === "add" ? t("products.add") : ""}
              {page === "edit" ? t("products.edit_product") : ""}
            </h2>
          </div>
        </div>

        {page !== "add" && page !== "edit" ? (
          <div className="flex flex-row items-center">
            <div className="flex items-center pt-2">
              <nav className="bg-gray-primary rounded-xl p-2">
                <ul className="inline-flex items-center -space-x-px">
                  {nav.map((item, index) => (
                    <li
                      key={index}
                      className="cursor-pointer"
                      onClick={() => {
                        Action.getPage(dispatch, { page: item.route });
                      }}
                    >
                      <div
                        className={`${page === item.route
                          ? "bg-gold-secondary text-white shadow-md"
                          : ""
                          } block px-6 py-2 rounded-xl drop-shadow-md leading-tight`}
                      >
                        {item.title}
                      </div>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>

            <div className="flex-1 text-right">
              <ButtonGold
                title={t("products.add")}
                onClick={() => {
                  Action.getPage(dispatch, { page: "add" });
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="pt-2">
          <Content />
        </div>
      </>
    );
  };
  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};
export default Product;
