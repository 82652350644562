import ComplaintModel from "../pages/complaint/model/ComplaintModel";
import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";
import DateUtility from "../utils/date-utility";

const BASE_API = process.env.REACT_APP_BASE_API;

const getListData = async (data) => {
    const url = BASE_API + Endpoint.complaint_get_list;
    const response = await CallApi({ url, method: "POST", data });
    const response1 = typeof response != "undefined" ? response.data : null;
    const response2 =
        typeof response1 != "undefined" && response1 != null
            ? response1.data
            : null;

    const list =
        typeof response2 != "undefined" && response2 != null ? response2.data : [];

    let complaints = [];
    for (let i = 0; i < list.length; i++) {
        const element = list[i];
        const item = new ComplaintModel({
            id: element.id,
            complaintId: element.complaintId,
            category: element.category,
            customerName: element.customerName,
            status: element.status,
            statusName: element.statusName,
            date: DateUtility.formatDate(element.date, "half"),
        });
        complaints.push(item);
    }

    //mapping to object
    let dataComplaints = {};
    dataComplaints.error = response.error;
    dataComplaints.message = response.message;
    dataComplaints.page = response2 ? response2.page : 0;
    dataComplaints.per_page = response2 ? response2.size : 0;
    dataComplaints.total_pages = response2 ? response2.totalPage : 0;
    dataComplaints.total_data = response2 ? response2.totalData : 0;
    dataComplaints.data = complaints;

    return dataComplaints;
};

const getProductName = (type, product) => {
    const productEdition = product?.edition !== "" ? " - " + product.edition : "";

    if (type === "Jewellery") {
        return product?.name + " - " + product?.subCategory;
    } else {
        return product?.brandName + productEdition + ", " + product?.gramName;
    }
};

const getDetail = async (data) => {
    const url = BASE_API + Endpoint.complaint_get_detail + "/" + data.id;
    const response = await CallApi({ url, method: "POST", data });
    // memformat purchase date
    if (response?.data?.data?.orderDetail?.purchaseDate) {
        response.data.data.orderDetail.purchaseDate = DateUtility.formatDate(response.data.data.orderDetail.purchaseDate, "half");
    }
    // menambahkan product name
    if (response?.data?.data?.orderDetail?.product) {
        response.data.data.orderDetail.product.forEach((product) => {
            product.productName = getProductName(product.category, product);
        });
    }

    return response;
};

const changeStatus = async (data, status) => {
    const url = BASE_API + Endpoint.change_status + "/" + status;
    const response = await CallApi({ url, method: "POST", data });
    return response;
};

const getComplaintNotif = async () => {
    const url = BASE_API + Endpoint.complaint_notif;
    const response = await CallApi({ url, method: "POST", data: {} });
    return response;
};

const ComplaintRepository = {
    getListData,
    getDetail,
    changeStatus,
    getComplaintNotif
};

export default ComplaintRepository;