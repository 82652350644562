function TicketModel({
    titleTicket,
    date,
    productSalesRevenue,
    balanceReceived,
    withdrawalFee,
    balance,
    totalAmountWithdraw,
    productCommission,
    shipping,
    insurance,
    adminApplicationFee,
    transactionServiceFee,
    cancellationFee,
    cancellationFeePerItem,
    thirdPartyFee,
    type,
    status,
    customerName,
    totalPricePromo,
    totalShippingPromo,
    totalAdminPromo
}) {
    let obj = {};
    obj.titleTicket = titleTicket;
    obj.date = date;
    obj.productSalesRevenue = productSalesRevenue;
    obj.balanceReceived = balanceReceived;
    obj.withdrawalFee = withdrawalFee;
    obj.balance = balance;
    obj.totalAmountWithdraw = totalAmountWithdraw;
    obj.productCommission = productCommission;
    obj.shipping = shipping;
    obj.insurance = insurance;
    obj.adminApplicationFee = adminApplicationFee;
    obj.transactionServiceFee = transactionServiceFee;
    obj.cancellationFee = cancellationFee;
    obj.cancellationFeePerItem = cancellationFeePerItem;
    obj.thirdPartyFee = thirdPartyFee;
    obj.type = type;
    obj.status = status;
    obj.customerName = customerName;
    obj.totalPricePromo = totalPricePromo;
    obj.totalShippingPromo = totalShippingPromo;
    obj.totalAdminPromo = totalAdminPromo;
    return obj;
};

export default TicketModel;