function ComplaintModel({
    id,
    complaintId,
    category,
    customerName,
    status,
    statusName,
    date,
}) {
    let obj = {};
    obj.id = id;
    obj.complaintId = complaintId;
    obj.category = category;
    obj.customerName = customerName;
    obj.status = status;
    obj.statusName = statusName;
    obj.date = date;
    return obj;
}

export default ComplaintModel;