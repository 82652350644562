const status = (t, value) => {
    return Object.freeze({
        "-1": t("complaints.declined"),
        0: t("complaints.opened"),
        4: t("complaints.completed"),
        5: t("complaints.investigation"),
    })[value];
};

const Enum = {
    status
};

export default Enum;
