import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import PopupUtility from "../../../utils/popup-utility";

const TrackingModal = ({ onCancel, trackingDetail }) => {
  const t = useTranslation();
  const [inv, setInv] = useState(trackingDetail);
  const [copySuccess, setCopySuccess] = useState('');

  useEffect(() => {
    setInv(trackingDetail);
  }, [trackingDetail]);
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="fixed inset-0 w-full h-full bg-black opacity-40" />
      <div className="flex items-center min-h-screen px-4 py-8">
        <div className="relative w-full max-w-lg p-4 mx-auto bg-gray-primary rounded-lg shadow-lg">
          <div className="m-3">
            {/* Start Content */}
            <div className="flex justify-end">
              <button
                className="text-gold-secondary mb-3"
                onClick={() => {
                  onCancel();
                }}
              >
                X
              </button>
            </div>
            <div className="flex justify-between mb-8">
              <h3 className="font-bold text-green-primary">
                {t("orders.tracking")}
              </h3>
              <div>
                <p className="sm:text-sm text-gray-secondary">
                  {t("orders.receipt_number")}
                </p>
                <div className="flex items-center gap-x-1 mr-3.5">
                  <p className="sm:text-sm">{inv.receiptNumber}</p>
                  <button
                    onClick={() => {
                      const textArea = document.createElement("textarea");
                      textArea.value = inv.receiptNumber;
                      document.body.appendChild(textArea);
                      textArea.select();
                      try {
                        document.execCommand('copy');
                        const strCopied = t("general.copied");
                        setCopySuccess(strCopied);
                        setTimeout(() => {
                          setCopySuccess('');
                        }, 2500);
                      } catch (err) {
                        PopupUtility.responseValidate(
                          "Failed",
                          "Clipboard API not supported in this browser."
                        );
                      }
                      document.body.removeChild(textArea);
                    }}>
                    <img
                      className="h-3"
                      src={
                        process.env.REACT_APP_ASSETS_IMAGE + "copy-icon.svg"
                      }
                      alt="copy-icon"
                    />
                  </button>
                </div>
                {copySuccess && <div className="mt-2 text-green-secondary px-3 py-0.5 bg-gray-primary w-fit rounded-xl border-2 border-green-secondary font-bold">{copySuccess}</div>}
              </div>
            </div>
            <div className="flex mb-2">
              <div className="flex-1">
                <p className="sm:text-sm text-gray-secondary">
                  {t("orders.courier")}
                </p>
                <p className="sm:text-sm">
                  {inv.courierProvider + " - " + inv.courierRate}
                </p>
              </div>
              <div className="flex-1">
                <p className="sm:text-sm text-gray-secondary">
                  {t("orders.estimated_time_arrival")}
                </p>
                <p className="sm:text-sm">{inv.estimatedArrival}</p>
              </div>
              <div className="flex-1">
                <p className="sm:text-sm text-gray-secondary">
                  {t("orders.buyer")}
                </p>
                <p className="sm:text-sm">
                  {inv.customerAddress.recipientName}
                </p>
              </div>
            </div>
            <div className="max-h-[29rem]">
              {/* start list */}
              {inv.history.map((item, index) => {
                return (
                  <div className="flex">
                    <div className="justify-center mr-1">
                      <div
                        className={`${index === 0
                          ? "border-green-primary"
                          : "border-gray-secondary"
                          } border-2 rounded-full`}
                      >
                        <div
                          className={`${index === 0
                            ? "bg-green-primary"
                            : "bg-gray-secondary"
                            } p-1 m-1 rounded-full`}
                        />
                      </div>
                      {index !== inv.history.length - 1 ? (
                        <div className="flex justify-center m-1 h-full pb-7">
                          <div
                            className={`${index === 0
                              ? "bg-green-primary"
                              : "bg-gray-secondary"
                              } w-[3px] rounded-full`}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      <p className="font-bold">{item.date}</p>
                      <p>{item.description}</p>
                    </div>
                  </div>
                );
              })}
              {/* end list */}
            </div>
            {/* End Content */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackingModal;
