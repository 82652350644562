import InfiniteScroll from "react-infinite-scroller";
import Skeleton from "react-loading-skeleton";

const InfiniteScrollComponent = ({loadMore, hasMore, children}) => {
  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasMore}
      loader={<Skeleton height={"35px"} />}
      useWindow={false}
      threshold={100}
    >
    {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollComponent;
