import React from 'react';
import { useTranslation } from 'react-multi-lang';
import ButtonWhite from '../../../components/ButtonWhite/ButtonWhite';
import ButtonGreen from '../../../components/ButtonGreen/ButtonGreen';

const ResolveModal = ({ onCancel, onSubmit }) => {
    const t = useTranslation();
    const baseImg = process.env.REACT_APP_ASSETS_IMAGE;

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={onCancel}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-gray-primary rounded-xl shadow-lg">
                    <div className="m-3 text-left">
                        <h3 className="text-green-primary font-bold pb-2">{t("complaints.title_resolve")}</h3>

                        <div className=" text-sm">{t("complaints.subtitle_resolve")}</div>

                        <div className="mt-3">
                            <div className="border-2 border-gold-secondary bg-[#f5f0e1] px-3 py-2 rounded-2xl flex">
                                <img className='mr-2 w-5 h-5' src={baseImg + '/information.png'} />
                                <p>{t("complaints.resolve_modal_disclaimer")}</p>
                            </div>
                        </div>
                        <div className="items-center gap-2 mt-3 flex justify-end">
                            <ButtonWhite
                                title={t("general.cancel")}
                                onClick={onCancel}
                            />
                            <ButtonGreen
                                title={t("general.confirm")}
                                onClick={onSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResolveModal;