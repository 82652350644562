import HelpCenterRepository from "../../../../../repositories/HelpCenterRepository";
import { setData, setLoading } from "./Reducer";

const getData = async (store) => {
  store.dispatch(setLoading(true));
  let response = await HelpCenterRepository.getAboutUs();
  if (response) {
    store.dispatch(setData(response));
    store.dispatch(setLoading(false));
  }
};

const Action = {
  getData,
};

export default Action;
