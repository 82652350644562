import React from "react";

const ButtonSort = ({ title, isAsc, isDesc, sortAsc, sortDesc }) => {
  return (
    <div className="flex flex-row gap-x-1 items-center">
      <p>{title}</p>
      <div className="flex flex-col">
        <button onClick={sortDesc}>
          <img
            className={`rotate-180 cursor-pointer ${
              isDesc === true ? "h-2 w-2" : "h-1.5 w-2 opacity-30"
            }`}
            src={process.env.REACT_APP_ASSETS_IMAGE + "arrow-table.svg"}
            alt="arrow-icon"
          />
        </button>
        <button onClick={sortAsc}>
          <img
            className={`cursor-pointer ${
              isAsc === true ? "h-2 w-2" : "h-1.5 w-2 opacity-30"
            }`}
            src={process.env.REACT_APP_ASSETS_IMAGE + "arrow-table.svg"}
            alt="arrow-icon"
          />
        </button>
      </div>
    </div>
  );
};

export default ButtonSort;
