import ProductRepository from "../../../../../repositories/ProductRepository";
import PopupUtility from "../../../../../utils/popup-utility";
import GeneralUtility from "../../../../../utils/general-utility";
import {
    setCategoryId,
    setCategoryList,
    setCurrentPage,
    setHasMoreItems,
    setIsEmptyList,
    setList,
    setLoading,
    setPopup,
    setSearch,
    setSortBy,
    setSortByDirection
} from "./Reducer";
import RouteName from "../../../../../services/routename";

const getList = async (
    dispatch,
    {search, currentList, offset, sortBy, sortByDirection, categoryId}
) => {
    dispatch(setSortBy(sortBy));
    dispatch(setSortByDirection(sortByDirection));
    dispatch(setCategoryId(categoryId));
    dispatch(setSearch(search));
    dispatch(setCategoryId(categoryId));

    let data = {
        page: offset,
        size: 5,
        status: null,
        search: search,
        categoryId: categoryId,
        sortBy: sortBy,
        sortByDirection: sortByDirection
    };

    let list;
    if (currentList) {
        list = currentList;
    } else {
        list = [];
    }

    let response = await ProductRepository.getProduct(data);
    if (!response.error) {
        dispatch(setLoading(false));
        dispatch(setList([...list, ...response.data]));

        if (response.data.length > 0) {
            dispatch(setCurrentPage(offset += 1));
        } else {
            dispatch(setHasMoreItems(false));
            if (list.length === 0 && response.data.length === 0) {
                dispatch(setIsEmptyList(true));
            }
        }
    }
};

const getCategory = async (dispatch) => {
    const response = await ProductRepository.getCategory();
    if (response.error === false) {
        dispatch(setCategoryList(response.data.data));
    }
};

const searchProduct = async (dispatch, {search}) => {
    if (search === "") {
        dispatch(setSearch(null));
    } else {
        dispatch(setSearch(search));
    }

    await emptyList(dispatch);
};

const searchCategory = async (dispatch, {categoryId}) => {
    if (categoryId === "") {
        dispatch(setCategoryId(null));
    } else {
        dispatch(setCategoryId(categoryId));
    }

    await emptyList(dispatch);
};

const onDelete = async (dispatch, id, search, fieldName, orderBy) => {
    let response = await ProductRepository.deleteProduct({
        id: id,
    });
    if (response.error === false) {
        await emptyList(dispatch);
        await sleep();
        await getList(dispatch, {
            search: search,
            offset: 0,
            sortBy: fieldName,
            sortByDirection: orderBy,
        });
        dispatch(setPopup(false));
        PopupUtility.success("Product deleted successfully.");
    } else {
        PopupUtility.responseValidate("Failed", response.message);
    }
};

const onDuplicate = async (dispatch, id, search, fieldName, orderBy) => {
    const res = await ProductRepository.duplicateProduct({id: id});
    if (res.error === false) {
        await emptyList(dispatch);
        await sleep();
        await getList(dispatch, {
            search: search,
            offset: 0,
            sortBy: null,
            sortByDirection: null,
        });
        PopupUtility.success("A new product has been successfully duplicate.");
    } else {
        PopupUtility.responseValidate("Duplicate Product Failed", res.message);
    }
};

const changeValue = async (
    dispatch,
    value,
    id,
    search,
    type,
    fieldName,
    orderBy
) => {
    let data;
    // 1 = price, 2 = promoPrice, 3 = stock, 4 = status
    if (type === 1) {
        data = await ProductRepository.editPrice({
            id: id,
            price: GeneralUtility.decimalToInt(value),
        });
    } else if (type === 2) {
        data = await ProductRepository.editPromoPrice({
            id: id,
            promoPrice: GeneralUtility.decimalToInt(value),
        });
    } else if (type === 3) {
        let newValue;
        if (value === "" || value === null || value === undefined) {
            newValue = 0;
        } else {
            newValue = value;
        }
        data = await ProductRepository.editStock({
            id: id,
            stock: newValue,
        });
    } else if (type === 4) {
        data = await ProductRepository.editStatus({
            id: id,
            status: value,
        });
    }
    if (data.error === false) {
        // await emptyList(dispatch);
        // await sleep();
        // await getList(dispatch, {
        //   search: search,
        //   offset: 0,
        //   sortBy: fieldName,
        //   sortByDirection: orderBy,
        // });
        window.location.href = RouteName.products;
    } else {
        PopupUtility.responseValidate("Failed", data.message);
    }
};

const changeVariant = async (
    dispatch,
    value,
    id,
    search,
    type,
    fieldName,
    orderBy
) => {
    let data;
    // 1 = price, 2 = promoPrice, 3 = stock, 4 = status
    if (type === 1) {
        data = await ProductRepository.editVariantPrice({
            productVariantId: id,
            price: GeneralUtility.decimalToInt(value),
        });
    } else if (type === 2) {
        data = await ProductRepository.editVariantPromoPrice({
            productVariantId: id,
            promoPrice: GeneralUtility.decimalToInt(value),
        });
    } else if (type === 3) {
        let newValue;
        if (value === "" || value === null || value === undefined) {
            newValue = 0;
        } else {
            newValue = value;
        }
        data = await ProductRepository.editVariantStock({
            productVariantId: id,
            stock: newValue,
        });
    } else if (type === 4) {
        data = await ProductRepository.editVariantStatus({
            productVariantId: id,
            status: value,
        });
    }

    if (data.error === false) {
        // await emptyList(dispatch);
        // await sleep();
        // await getList(dispatch, {
        //   search: search,
        //   offset: 0,
        //   sortBy: fieldName,
        //   sortByDirection: orderBy,
        // });
        window.location.href = RouteName.products;
    } else {
        PopupUtility.responseValidate("Failed", data.message);
    }
};


const emptyList = (dispatch) => {
    return new Promise((resolve) => {
        dispatch(setList([]));
        dispatch(setCurrentPage(0));
        dispatch(setHasMoreItems(true));
        resolve();
    });
};

async function sleep(msec = 500) {
    return new Promise((resolve) => setTimeout(resolve, msec));
}

const sortTable = async (dispatch, fieldName, orderBy) => {
    dispatch(setSortBy(fieldName));
    dispatch(setSortByDirection(orderBy));
    await emptyList(dispatch);
};

const copyLink = (product) => {
    const productName = product.productName;
    const productId = product.id;

    const baseUrl = "https://tanamemas.page.link/?link=https://www.tanamemas.id/product";
    const queryParams = `?productId${productId}&ibi=com.tanamemas.app&apn=com.tanamemas.apps`;
    const link = baseUrl + queryParams;
    const newUrl = productName + " : \n" + link;

    // Check if the Clipboard API is supported
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        // Use the Clipboard API if available
        navigator.clipboard.writeText(newUrl)
            .then(() => {
                PopupUtility.success("Link copied to clipboard");
            })
            .catch(err => {
                console.error("Failed to copy: ", err);
            });
    } else {
        // Fallback for older browsers
        const textarea = document.createElement("textarea");
        textarea.value = newUrl;
        document.body.appendChild(textarea);
        textarea.select();

        try {
            // Copy the text inside the textarea
            const successful = document.execCommand("copy");
            if (successful) {
                PopupUtility.success("Link copied to clipboard");
            } else {
                console.error("Failed to copy");
            }
        } catch (err) {
            console.error("Failed to copy: ", err);
        } finally {
            // Remove the temporary textarea
            document.body.removeChild(textarea);
        }
    }
};

const Action = {
    getList,
    onDelete,
    onDuplicate,
    searchProduct,
    emptyList,
    changeValue,
    sortTable,
    getCategory,
    searchCategory,
    changeVariant,
    copyLink
};

export default Action;
