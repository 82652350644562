import Cookies from "js-cookie";
import {setBreadcrums, setCountNotifChat, setTranslation} from "./Reducer";
import AuthRepository from "../../../repositories/AuthRepository";

const getBreadcrums = async (dispatch, title, link) => {
    let breadcrums = [];
    let data = {};
    data.title = title;
    data.link = link;
    breadcrums.push(data);
    dispatch(setBreadcrums(breadcrums));
};

const changeTranslation = async (dispatch, lang) => {
    dispatch(setTranslation(lang));
    Cookies.set("language", lang);
};

const getStatusMaintenance = async () => {
    const response = await AuthRepository.getStatusMaintentance();
    return response;
};

const setCountNotif = (dispatch) => {
    const min = 1;
    const max = 100;
    const count = Math.floor(Math.random() * (max - min + 1)) + min;
    dispatch(setCountNotifChat(count))
}

const Action = {
    getBreadcrums,
    changeTranslation,
    getStatusMaintenance,
    setCountNotif
};

export default Action;
