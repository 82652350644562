import React, { useState } from "react";
import { useEffect } from "react";
import Barcode from "react-barcode";
import OrderRepository from "../../repositories/OrderRepository";
import DateUtility from "../../utils/date-utility";

const JneLabel = () => {
  const [data, setData] = useState({});
  const baseImg = process.env.REACT_APP_ASSETS_IMAGE;
  const url = window.location.href;
  const parts = url.split("/"); // Split the URL by "/"
  const labelId = parts.pop();
  const inv = labelId.replace(/-/g, "/");

  async function sleep(msec = 1000) {
    return new Promise((resolve) => setTimeout(resolve, msec));
  }

  const getData = async () => {
    let data = {
      inv: "",
      from: "",
      to: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      nohp: "",
      package: "",
      destinationCode: "",
      date: "",
      goodsType: "",
      detailGoods: "",
      qty: "",
      weight: "",
      insurance: false,
      cod: false,
      provider: ""
    };

    const response = await OrderRepository.getResi({ noInvoice: inv });
    if (!response.error) {
      let res = response.data.data;
      data = {
        inv: inv,
        awbNumber: res.awbNumber,
        from: res.shipperName,
        to: res.receiverName,
        address: res.receiverAddress,
        city: res.receiverCity,
        province: res.receiverRegion,
        postalCode: res.receiverZip,
        nohp: res.receiverPhone,
        package: res.serviceCode,
        destinationCode: res.originCode,
        date: res.createdAt ? DateUtility.dateFormatApi(res.createdAt) : "",
        goodsType: res.goodsDesc,
        detailGoods: "Accesories",
        qty: res.qty,
        weight: res.weight,
        insurance: res.insuranceFlag === "Y" ? true : false,
        cod: res.codFlag === "Y" ? true : false,
        provider: res.provider !== "" || res.provider !== null ? res.provider.toLowerCase() : ""
      };
    }
    setData(data);
    await sleep();
    print();
  };

  const print = () => {
    document.title = "Label " + inv;
    window.print();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className=" bg-white h-screen w-screen text-center">
      <table className="h-[48em] w-[38em] border-black border ">
        <tbody>
          <tr className="">
            <td className="pl-5">
              <img
                className="h-20"
                src={baseImg + "te-blackwhite.jpeg"}
                alt="tanam-emas"
              />
            </td>
            <td className="pl-5">
              {data.provider === "paxel" ? (
                <img
                  className="h-20"
                  src={baseImg + "paxel-label.png"}
                  alt="paxel"
                />
              ) : (
                <img
                  className="h-20"
                  src={baseImg + "jne-label.jpeg"}
                  alt="jne"
                />
              )}
            </td>
          </tr>
          <tr className="border-y border-black">
            <td colSpan="2">Order Number : {data.inv}</td>
          </tr>
          <tr className="border-y border-black text-center">
            <td colSpan="2" className="pl-24">
              <Barcode value={data.awbNumber} displayValue={true} />
            </td>
          </tr>
          <tr className="text-left align-top">
            <td className="border-black border px-2 py-3">
              <p>From :</p>
              <p>Nama : {data.from}</p>
            </td>
            <td width={250} className="border-black border px-2 py-3">
              <p>To : </p>
              <p>Nama : {data.to}</p>
              <p>Alamat Lengkap : {data.address}</p>
              <p>{data.city + ", " + data.province + ", "}</p>
              <p>{data.postalCode}</p>
              <p>No.Hp : {data.nohp}</p>
            </td>
          </tr>
          <tr>
            <td className="border-black border">{data.package}</td>
            <td className="border-black border">{data.destinationCode}</td>
          </tr>
          <tr className="border-black border text-left align-top">
            <td className="px-2 py-3">
              <p>Date : {data.date}</p>
              <p>Good Type : {data.goodsType}</p>
              <p>Detail Goods : {data.detailGoods}</p>
              <p>Packing Kayu : NO</p>
            </td>
            <td className="px-2 py-3">
              <p>Qty : {data.qty}</p>
              <p>Weight : {data.weight}</p>
              <p>Insurance : {data.insurance ? "YES" : "NO"}</p>
              <p>COD : {data.cod ? "YES" : "NO"} </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <div className="flex flex-row">
                <div className="w-20">
                  <img
                    className="h-18"
                    src={baseImg + "warning.jpeg"}
                    alt="tanam-emas"
                  />
                </div>
                <div className="flex-1 text-center">
                  <h3>TANPA VIDEO UNBOXING</h3>
                  <h3>KELUHAN TIDAK DAPAT DIAJUKAN</h3>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default JneLabel;
