import React, { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { setBankDetails, setValidateStatus } from "../redux/Reducer";
import GeneralUtility from "../../../../utils/general-utility";
import Select from "react-select";
import Action from "../redux/Action";

const BankDetails = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const bank = useSelector((state) => state.reducer.bank);
  const bankDetails = useSelector((state) => state.reducer.bankDetails);
  const errorBankDetails = useSelector(
    (state) => state.reducer.errorBankDetails
  );
  const validateStatus = useSelector((state) => state.reducer.validateStatus);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "bankNumber") {
      dispatch(
        setBankDetails({
          ...bankDetails,
          [name]: GeneralUtility.removeNonNumeric(value),
        })
      );
    } else {
      dispatch(setBankDetails({ ...bankDetails, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = Action.validateStep2(dispatch, bankDetails);
    if (Object.keys(isValid).length === 0) {
      Action.getPage(dispatch, "legaldocuments");
    }
  };

  useEffect(() => {
    if (validateStatus === true) {
      Action.validateStep2(dispatch, bankDetails);
    } else if (validateStatus === false) {
      Action.getBank(dispatch);
      dispatch(setValidateStatus(true));
    }
  }, [bankDetails]);

  return (
    <form>
      <div className="flex flex-col gap-y-4 md:h-[30em] overflow-y-auto">
        {/* bank account name */}
        <div className="flex flex-row items-center gap-x-3 pt-2">
          <div className="w-44">
            <div
              className={`text-md font-bold ${
                errorBankDetails.bankName ? "pb-4" : " "
              }`}
            >
              {t("register.account_name")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <input
              type="text"
              name="bankName"
              onChange={handleChange}
              placeholder={t("register.account_name")}
              className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${
                errorBankDetails.bankName
                  ? "border-red-500 border-1"
                  : "border-0"
              }`}
              defaultValue={bankDetails.bankName}
            />
            <p className="text-red-500">{errorBankDetails.bankName}</p>
          </div>
        </div>
        {/* bank name */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-44">
            <div
              className={`text-md font-bold ${
                errorBankDetails.bankId ? "pb-4" : " "
              }`}
            >
              {t("register.bank_name")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <Select
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: errorBankDetails.bankId ? "red" : "white",
                }),
              }}
              className="text-sm border w-full bg-white font-medium"
              options={bank}
              name="bankId"
              onChange={(item) => {
                handleChange({
                  target: { name: "bankId", value: item.value },
                });
              }}
            />
            <p className="text-red-500">{errorBankDetails.bankId}</p>
          </div>
        </div>
        {/* Bank Number */}
        <div className="flex flex-row items-center gap-x-3 pb-2">
          <div className="w-44">
            <div
              className={`text-md font-bold ${
                errorBankDetails.bankNumber ? "pb-4" : " "
              }`}
            >
              {t("register.account_number")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <input
              type="text"
              name="bankNumber"
              value={bankDetails.bankNumber}
              onChange={handleChange}
              placeholder={t("register.input_account_number")}
              className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${
                errorBankDetails.bankNumber
                  ? "border-red-500 border-1"
                  : "border-0"
              }`}
            />
            <p className="text-red-500">{errorBankDetails.bankNumber}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-row-reverse gap-x-2 pt-5">
        <button
          onClick={handleSubmit}
          className="button md:w-36 hover:bg-gray-primary text-white border-2 hover:text-green-quaternary border-gold-secondary text-xs tracking-widest bg-gold-secondary font-bold py-3 rounded-xl"
        >
          {t("register.next").toUpperCase()}
        </button>
        {/* <ButtonGold title={t("register.next")} onClick={handleSubmit} /> */}
      </div>
    </form>
  );
};

export default BankDetails;
