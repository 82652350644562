//auth
const login = "/api/v1/auth/merchant/login";
const signup = "/api/v1/auth/merchant/signup";
const province = "/api/v1/public/provinces";
const checkAccDetail = "/api/v1/auth/merchant/account-details/check";
const checkLegalDoc = "/api/v1/auth/merchant/legal-documents/check";
const city = "/api/v1/public/cities/provinces";
const district = "/api/v1/public/districts/cities";
const code_phone = "/api/v1/public/country-code";
const bank = "/api/v1/public/banks";
const forgotPassword = "/api/v1/auth/merchant/forgot-password/reset";
const resetPassword =
  "/api/v1/auth/merchant/forgot-password/create-new-password";
const resetPasswordCustomer =
  "/api/v1/auth/customer/forgot-password/create-new-password";
const refreshToken = "/api/v1/auth/merchant/refresh-token";
const tnc = "/api/v1/public/get-term-and-condition";
const checkStatusMerchant = "/api/v1/merchant/get-account-status";

//profile
const account_detail = "/api/v1/merchant/account-details";
const update_account_detail = "/api/v1/merchant/account-details/update";
const bank_detail = "/api/v1/merchant/bank";
const update_bank_detail = "/api/v1/merchant/bank/update";
const legal_documents = "/api/v1/merchant/legal-documents";
const updatePassword = "/api/v1/merchant/password/update";
const checkPass = "/api/v1/merchant/check-password";
const operational_hour = "/api/v1/merchant/operational-hour/list";
const update_operational_hour = "/api/v1/merchant/operational-hour";
const holiday_date = "/api/v1/merchant/holiday-date/list";
const update_holiday_date = "/api/v1/merchant/holiday-date";
const otp_update_bank = "/api/v1/merchant/bank/update/otp";

//helpcenter
const faq = "/api/v1/public/faq";
const aboutUs = "/api/v1/public/aboutus";
const category = "/api/v1/feedbacks/list/category";
const topic = "/api/v1/feedbacks/list/topic";
const create_feedback = "/api/v1/feedbacks/create";

//product
const product_list = "/api/v1/merchant/get-product-list";
const product_detail = "/api/v1/merchant/get-product-detail";
const product_add = "/api/v1/merchant/products/create";
const product_edit = "/api/v1/merchant/products/edit";
const product_duplicate = "/api/v1/merchant/products/duplicate";
const product_delete = "/api/v1/public/products/delete";
const product_gram = "/api/v1/public/grams/list";
const product_carat = "/api/v1/merchant/get-carat-list";
const product_category = "/api/v1/merchant/get-product-category-list";
const product_variant = "/api/v1/merchant/get-variant-option-list";
const edit_status = "/api/v1/merchant/products/edit/status";
const edit_variant_status = "/api/v1/merchant/products/variants/edit/status";
const edit_price = "/api/v1/merchant/products/edit/price";
const edit_variant_price = "/api/v1/merchant/products/variants/edit/price";
const edit_promoprice = "/api/v1/merchant/products/edit/promoprice";
const edit_variant_promoprice =
  "/api/v1/merchant/products/variants/edit/promo-price";
const edit_stock = "/api/v1/merchant/products/edit/stock";
const edit_variant_stock = "/api/v1/merchant/products/variants/edit/stock";
const edit_photo = "/api/v1/product/image/edit";
const add_photo = "/api/v1/product/image/create";
const delete_photo = "/api/v1/product/image/delete";
const product_category_list = "/api/v1/merchant/get-product-category-list";

//buyback
const buyback_list = "/api/v1/merchant/get-list-buyback";
const buyback_detail = "/api/v1/merchant/get-list-buyback/detail";
const buyback_decline = "/api/v1/merchant/buyback-status-decline";
const buyback_confirm = "/api/v1/merchant/buyback-status-confirm";
const buyback_revise = "/api/v1/merchant/buyback-status-revise";

//goldpickup
const listGoldPickup = "/api/v1/merchant/gold-pickup-list";
const detailGoldPickup = "/api/v1/merchant/gold-pickup-list/detail";
const goldPickupDecline = "/api/v1/merchant/gold-pickup/status-decline";
const goldPickupAccept = "/api/v1/merchant/gold-pickup/status-accept";
const goldPickupPickedUp =
  "/api/v1/merchant/gold-pickup/status-set-as-picked-up";

//orders
const listOrder = "/api/v1/merchant/orders";
const trackingOrder = "/api/v1/shipment/tracking";
const setPickupOrder = "/api/v1/shipment/pickup";
const getResi = "/api/v1/merchant/get-resi-transaction";
const completeOrder = "/api/v1/merchant/self-pickup/complete";
const cancelOrder = "/api/v1/merchant/self-pickup/cancel";

//dashboard
const insight = "/api/v1/merchant/dashboard-goldpricing";
const revenue = "/api/v1/merchant/dashboard-total-sales-weekly";
const topSelling = "/api/v1/merchant/dashboard-top-seller-product";
const todayOrder = "/api/v1/merchant/dashboard-count-orders-in-status";
const statusMerchant = "/api/v1/merchant/dashboard-open-close";
const openNow = "/api/v1/merchant/dashboard-open-now";
const changeStatusMerhant = "/api/v1/merchant/update-merchant/status-active";
const generateExcel = "/api/v1/merchant/dashboard-export/excel";

//notication
const getAllNotif = "/api/v1/merchant/notifications";
const readNotif = "/api/v1/merchant/notifications/read";
const readAllNotif = "/api/v1/merchant/notifications/reads";
const countNotif = "/api/v1/merchant/notifications/unread/total";

//balance
const balance_data = "/api/v1/merchant/get-balance";
const balance_history = "/api/v1/merchant/get-balance-history";
const balance_wd = "/api/v1/merchant/withdraw";

// complaint
const complaint_get_list = "/api/v1/merchant/feedbacks";
const complaint_get_detail = "/api/v1/merchant/feedbacks";
const change_status = "/api/v1/merchant/feedbacks/status";
const complaint_notif = "/api/v1/merchant/notification-menu";

// status maintenance
const statusMaintenance = "/api/v1/public/app-status-merchant";

// chat
const list_chat = "/api/v1/merchant/get-all-chat";
const getAutoReply = "/api/v1/merchant/get-auto-reply-chat";
const saveAutoReply = "/api/v1/merchant/save-auto-reply-chat";
const readMessage = "/api/v1/merchant/read-message";
const getChatById = "/api/v1/merchant/get-chat-by-id";
const uploadMedia = "/api/v1/merchant/upload-chat";
const countMessage = "/api/v1/merchant/get-total-unread-chat"

const Endpoint = {
  login,
  province,
  city,
  district,
  code_phone,
  bank,
  forgotPassword,
  resetPassword,
  resetPasswordCustomer,
  checkStatusMerchant,
  refreshToken,
  tnc,
  signup,
  account_detail,
  update_account_detail,
  bank_detail,
  update_bank_detail,
  legal_documents,
  faq,
  aboutUs,
  category,
  topic,
  create_feedback,
  product_list,
  product_add,
  product_edit,
  product_duplicate,
  product_delete,
  product_detail,
  product_gram,
  product_carat,
  product_category,
  product_variant,
  checkAccDetail,
  checkLegalDoc,
  updatePassword,
  checkPass,
  operational_hour,
  update_operational_hour,
  holiday_date,
  update_holiday_date,
  edit_price,
  edit_promoprice,
  edit_status,
  edit_stock,
  edit_photo,
  add_photo,
  delete_photo,
  product_category_list,
  buyback_list,
  buyback_detail,
  buyback_confirm,
  buyback_decline,
  buyback_revise,
  listGoldPickup,
  detailGoldPickup,
  goldPickupAccept,
  goldPickupDecline,
  goldPickupPickedUp,
  listOrder,
  trackingOrder,
  setPickupOrder,
  getResi,
  insight,
  revenue,
  topSelling,
  todayOrder,
  statusMerchant,
  openNow,
  changeStatusMerhant,
  generateExcel,
  getAllNotif,
  readNotif,
  readAllNotif,
  countNotif,
  edit_variant_status,
  edit_variant_price,
  edit_variant_promoprice,
  edit_variant_stock,
  balance_data,
  balance_history,
  balance_wd,
  complaint_get_list,
  complaint_get_detail,
  change_status,
  complaint_notif,
  otp_update_bank,
  completeOrder,
  cancelOrder,
  statusMaintenance,
  list_chat,
  getAutoReply,
  saveAutoReply,
  readMessage,
  getChatById,
  uploadMedia,
  countMessage,
};

export default Endpoint;
