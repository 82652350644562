import React from "react";
import Header from "./components/Header";
import Tabel from "./components/Table";
import { Provider } from "react-redux";
import StoreHelper from "../../../../services/store-helper";
import reducerSlice from "./redux/Reducer";

const Unactive = ({ onEdit }) => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  store.getState();

  const MainComponent = () => {
    return (
      <>
        <div className="pt-1">
          <div className="rounded-2xl p-5 bg-gray-primary">
            <div className="flex flex-row items-center gap-x-5">
              <Header />              
            </div>
            <div className="my-2">
              <Tabel
                onEdit={(id, typeForm) => {
                  onEdit(id, typeForm);
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default Unactive;
