import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Provider, useSelector } from "react-redux";
import Action from "./redux/Action";
import StoreHelper from "../../../../services/store-helper";
import reducerSlice from "./redux/Reducer";
import { useTranslation } from "react-multi-lang";

const AboutUs = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  const MainComponent = () => {
    const loading = useSelector((state) => state.reducer.loading);
    const data = useSelector((state) => state.reducer.data);
    const t = useTranslation();
    useEffect(() => {
      Action.getData(store);
    }, []);

    return (
      <div className="bg-gray-primary rounded-xl p-3">
        {loading ? (
          <Skeleton count={3} height={"2rem"} />
        ) : (
          <div className="m-2">
            <div className="mb-5">{t("helpcenter.more")}</div>
            <div className="flex mb-4 items-center">
              <img
                className="w-4 mr-3.5"
                src={
                  process.env.REACT_APP_ASSETS_IMAGE + "location_icon_black.svg"
                }
                alt="location_icon_black"
              />
              <p className="sm:text-sm w-1/2">{data.address}</p>
            </div>
            <div className="flex mb-4 items-center">
              {/* <img
                className="w-5 mr-3"
                src={
                  process.env.REACT_APP_ASSETS_IMAGE + "phone-icon-black.svg"
                }
                alt="phone-icon-black"
              />
              <p className="sm:text-sm w-1/2">{data.phone}</p> */}
            </div>
            <div className="flex mb-4 items-center">
              <img
                className="w-5 mr-3"
                src={
                  process.env.REACT_APP_ASSETS_IMAGE + "email-icon-black.svg"
                }
                alt="email"
              />
              <p className="sm:text-sm w-1/2">{data.email}</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default AboutUs;
