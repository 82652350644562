import { useTranslation } from "react-multi-lang";
import GeneralUtility from "../../../../utils/general-utility";

const OrderProduct = ({ product }) => {
  const productEdition = product.edition !== "" ? " - " + product.edition : "";
  const t = useTranslation();
  return (
    <div className="flex">
      <img
        className="h-20 w-20 mr-3 border-2 rounded-xl mb-1"
        src={product.photo}
        alt="product_image"
      />
      <div>
        <p className="font-bold">
          {product?.category?.includes("Jewellery")
            ? product?.name + " - " + product?.subCategory
            : product?.brandName + productEdition + ", " + product?.gramName}
        </p>
        {/* ternary jika dia jewelry tampilkan */}
        {product.subCategory !== null ? (
          <div className="flex text-gray-secondary">
            {product.size && product.size !== "" ? (
              <p className="mr-0.5"> Size : {product.size} </p>
            ) : (
              ""
            )}
            {product.color && product.color !== "" ? (
              <p className="ml-0.5"> | Color : {product.color}</p>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        <p>
          {product.quantity} x Rp {GeneralUtility.addSeparator(product.price)}
        </p>

        <p className="mt-2">
          {t("general.notes")} : {product?.notes}
        </p>
      </div>
    </div>
  );
};

export default OrderProduct;
