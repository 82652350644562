import React, { useRef, useState } from "react";
import ButtonDownload from "../../../components/ButtonDownload/ButtonDownload";
import { useTranslation } from "react-multi-lang";
import GeneralUtility from "../../../utils/general-utility";
import CaptureUtility from "../../../utils/capture-utility";

const InvoiceModal = ({ onCancel, title, invoiceDetail }) => {
  const t = useTranslation();
  const [zoom, setZoom] = useState(100);
  const exportRef = useRef();
  let productPrice = 0;
  for (let i = 0; i < invoiceDetail.product.length; i++) {
    const element = invoiceDetail.product[i];
    productPrice += element.price * element.quantity;
  }
  let totalShippingInsurance =
    invoiceDetail.insuranceFee + invoiceDetail.shipping;
  
  return (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full bg-green-quinary bg-opacity-90"></div>
        <div className="flex flex-col min-h-screen px-4">
          <div className="relative px-8 pt-4 text-white">
            <div className="flex flex-row gap-x-8 items-center">
              <div className="flex-1">{title}</div>
              <div className="pt-1">
                <ButtonDownload
                  title={"Download"}
                  onClick={() => CaptureUtility(exportRef.current, title)}
                />
              </div>

              <div
                className="text-2xl cursor-pointer font-thin"
                onClick={() => {
                  onCancel({ showModal: false });
                }}>
                x
              </div>
            </div>
          </div>
          <div className="relative w-full max-w-4xl mx-auto my-auto shadow-lg">
            <div
              style={{ width: zoom + "%", height: zoom + "%" }}
              ref={exportRef}>
              <div className="flex bg-green-primary items-end rounded-t-xl p-3">
                <div className="flex-1 text-white">
                  <h5>{t("orders.invoice_number")}</h5>
                  <h4>{invoiceDetail.invoice}</h4>
                </div>
                <img
                  className="h-12 mb-8"
                  src={process.env.REACT_APP_ASSETS_IMAGE + "te-logo.svg"}
                  alt="Tanam Emas Logo"
                />
              </div>
              <div className="bg-white rounded-b-xl p-3">
                <div className="flex">
                  <div className="flex-1 mr-2">
                    <p className="text-gray-secondary">
                      {t("orders.purchase_date")}
                    </p>
                    <p className="font-bold">{invoiceDetail.dateCreated}</p>
                  </div>
                  <div className="flex-1">
                    <p className="text-gray-secondary">
                      {t("orders.shipping_detail")}
                    </p>
                    <p className="font-bold">{invoiceDetail.address}</p>
                  </div>
                  <div className="flex-1 ml-2">
                    <p className="text-gray-secondary">
                      {t("orders.payment_method")}
                    </p>
                    <p className="font-bold">{invoiceDetail.paymentMethod}</p>
                  </div>
                </div>
                <div className="w-full border-b my-2" />
                <p className="text-gray-secondary text-md">
                  {t("orders.order_details")}
                </p>
                <ul>
                  {invoiceDetail.product.map((item, index) => (
                    <li className="flex mb-1" key={index}>
                      <div className="flex-1 ">
                        <p className="font-bold">
                          {item.category?.includes("Jewellery")
                            ? item.name + " - " + item.subCategory
                            : item.brandName +
                            (item.edition !== ""
                              ? " - " + item.edition
                              : "") +
                            ", " +
                            item.gramName}
                        </p>
                        {/* ternary jika dia jewelry tampilkan */}
                        {item.subCategory !== null ? (
                          <div className="flex text-gray-secondary">
                            {item.size && item.size !== "" ? (
                              <p className="mr-0.5 ml-2"> Size : {item.size} </p>
                            ) : (
                              ""
                            )}
                            {item.color && item.color !== "" ? (
                              <p className="ml-0.5"> | Color : {item.color}</p>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {/* notes */}
                        <div className="flex text-gray-secondary">
                          <p className="ml-2">Notes : {item.notes ?? ""}</p>
                        </div>
                      </div>
                      <div className="text-right">
                        <p>Rp {GeneralUtility.addCommas(item.price)}</p>
                        <p>x{item.quantity}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="w-full border-b my-2" />
                <div className="flex">
                  <p className="flex-1 font-bold">{t("buyback.total_price")}</p>
                  <div className="text-right">
                    <p>Rp {GeneralUtility.addCommas(productPrice)}</p>
                  </div>
                </div>
                <div className="flex">
                  <p className="flex-1 font-bold">
                    {t("orders.total_shipping_cost")}
                  </p>
                  <div className="text-right">
                    <p>Rp {GeneralUtility.addCommas(invoiceDetail.shipping)}</p>
                  </div>
                </div> 
                {invoiceDetail.insuranceFee !== 0 ? (
                  <div className="flex">
                    <p className="flex-1 font-bold">
                      {t("orders.insurance_cost")}
                    </p>
                    <div className="text-right">
                      <p>
                        Rp {GeneralUtility.addCommas(invoiceDetail.insuranceFee)}
                      </p>
                    </div>
                  </div>
                ) : null}

                {/* {invoiceDetail.commission !== 0 ? (
                  <div className="flex">
                    <p className="flex-1 font-bold">
                      {t("orders.comission")}
                    </p>
                    <div className="text-right">
                      <p className="text-red-500 font-semibold">
                        - Rp {GeneralUtility.addCommas(invoiceDetail.commission)}
                      </p>
                    </div>
                  </div> transactionServiceFee
                ) : null} */}

                 {invoiceDetail.fee !== 0 ? (
                  <div className="flex">
                    <p className="flex-1 font-bold">
                      {t("orders.application_service_fee")}
                    </p>
                    <div className="text-right">
                      <p>Rp {GeneralUtility.addCommas(invoiceDetail.fee)}</p>
                    </div>
                  </div>
                ) : null} 


                 {invoiceDetail.transactionServiceFee !== 0 ? (
                  <div className="flex">
                    <p className="flex-1 font-bold">
                      {t("orders.transaction_service_fee")}
                    </p>
                    <div className="text-right">
                      <p>Rp {GeneralUtility.addCommas(invoiceDetail.transactionServiceFee)}</p>
                    </div>
                  </div>
                ) : null} 


                 {invoiceDetail.totalShippingPromo !== 0 ? (
                  <div className="flex">
                    <p className="flex-1 font-bold">
                      {t("orders.total_shipping_promo")}
                    </p>
                    <div className="text-right">
                      <p>Rp {GeneralUtility.addCommas(invoiceDetail.totalShippingPromo)}</p>
                    </div>
                  </div>
                ) : null} 


                <div className="w-full border-b my-2" />
                <div className="flex rounded-xl bg-gray-primary p-2">
                  <p className="flex-1 font-bold">{t("buyback.total_price")}</p>
                  <div className="text-right">
                    <p className="font-bold">
                      Rp {GeneralUtility.addCommas(invoiceDetail.total)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="relative w-fit mx-auto pb-4">
            <div className="flex flex-row items-center justify-center bg-green-secondary text-md text-white rounded-lg">
              <button className="px-3 py-1" onClick={() => setZoom(zoom - 10)}>
                -
              </button>
              <button className="px-2 py-1">zoom</button>
              <button className="px-3 py-1" onClick={() => setZoom(zoom + 10)}>
                +
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default InvoiceModal;
