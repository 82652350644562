import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import FormInputRadio from "./FormInputRadio";
import GoldBarForm from "./GoldBarForm";
import JewelryForm from "./JewelryForm";
import UploadImage from "../../../../components/UploadImage/UploadImage";
import ProductRepository from "../../../../repositories/ProductRepository";

const Add = ({ type, id, cat, onSucces }) => {
  const t = useTranslation();
  const [images, setImages] = useState({});
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [typeForm, setTypeForm] = useState("");
  const [goldbarId, setGoldbarId] = useState("");
  const [jewelryId, setJewelryId] = useState("");
  const [updateImg, setUpdateImg] = useState([]);

  const handleImg = (param, id) => {
    let newUpdateImg = [...updateImg];
    const indexToUpdate = newUpdateImg.findIndex((obj) => obj.id === id);
    if (indexToUpdate === 1) {
      const objAdd = {};
      objAdd.id = param.id || "";
      objAdd.image = param.image;
      objAdd.action = param.action;
      objAdd.filename = param.filename;
      newUpdateImg.push(objAdd);
    } else {
      let newObj = param;
      newUpdateImg.push(newObj);
    }
    setUpdateImg(newUpdateImg);
  };

  const getCategory = async () => {
    const response = await ProductRepository.getCategory();
    const category = response.data.data;
    setCategory(category ?? []);
    setGoldbarId(category[0].id ?? "");
    setJewelryId(category[1].id ?? "");
    setSubCategory(category[1].subCategories ?? []);
    if (type === "add") {
      setTypeForm(category[0].id ?? "");
    } else {
      setTypeForm(cat);
    }
  };

  const chooseType = async (value) => {
    setTypeForm(value);
  };

  const deleteStateImage = (keyToDelete) => {
    const newStateImages = { ...images };
    delete newStateImages[keyToDelete];
    setImages(newStateImages);
  };

  const setNullStateImage = (keysToSetNull) => {
    const newStateImages = { ...images };
    keysToSetNull.forEach(key => {
      if (newStateImages.hasOwnProperty(key)) {
        newStateImages[key] = null;
      }
    });
    setImages(newStateImages);
  };

  const deleteRefPhoto = (indicator) => {
    const updateImages = [...updateImg];
    const newUpdateImages = updateImages.filter(image => image.indicator !== indicator);
    setUpdateImg(newUpdateImages);
  };

  const checkIfPhotoIsBroken = ({ idPhoto, photo, name, indicator }) => {
    if (idPhoto) {
      return {
        id: idPhoto,
        image: photo,
        action: "update",
        filename: name
      };
    }
    return {
      id: "",
      image: photo,
      action: "add",
      filename: name,
      indicator
    };
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <form encType="multipart/form-data">
      <h4 className="text-green-primary font-bold pl-5 mb-4">
        {t("products.subtitle")}
      </h4>

      <div className="md:h-[41em] overflow-y-auto">
        <div className="rounded-2xl p-5 bg-gray-primary">
          <h4 className="opacity-60 font-bold w-60 mb-5">
            {t("products.upload").toUpperCase()}
          </h4>

          <div className="flex flex-row gap-x-4">
            <div className="w-72">
              <div className="font-bold">
                {t("products.photos")}
                <span className="text-gold-secondary">*</span>
              </div>

              <div className="pt-3 w-60">{t("products.condition_photos")}</div>
            </div>
            <div className="overflow-x-auto">
              <div className="w-[70em]">
                <div className="flex flex-row gap-x-2 bg-gray-disable rounded-xl p-2">
                  <UploadImage
                    img={images.photo1 ? images.photo1 : ""}
                    aspectRatio={1 / 1}
                    name={"photo1"}
                    width={40}
                    height={40}
                    position={"col"}
                    onClick={(photo, name) => {
                      if (type === "edit") {
                        if (images.idPhoto1 !== null) {
                          const obj = checkIfPhotoIsBroken({
                            idPhoto: images.idPhoto1,
                            photo,
                            name,
                            indicator: "photo1"
                          });
                          handleImg(obj, images.idPhoto1);
                        } else {
                          let obj = {
                            id: "",
                            image: photo,
                            action: "add",
                            filename: name,
                            indicator: "photo1"
                          };
                          handleImg(obj, "");
                        }
                      } else {
                        setImages((prev) => ({
                          ...prev,
                          0: {
                            photo1: photo,
                            photo1name: name
                          }
                        }));
                      }
                    }}
                    onDelete={(imageRef) => {
                      imageRef.current.value = null;
                      if (images.idPhoto1 != null) {
                        setNullStateImage(["idPhoto1", "photo1"]);
                        let obj = {
                          id: images.idPhoto1,
                          image: images.photo1,
                          action: "delete",
                        };
                        handleImg(obj, images.idPhoto1);
                        return;
                      }
                      deleteRefPhoto("photo1");
                      deleteStateImage(0);
                    }}
                  />
                  <UploadImage
                    img={images.photo2 ? images.photo2 : ""}
                    aspectRatio={1 / 1}
                    name={"photo2"}
                    width={40}
                    height={40}
                    position={"col"}
                    onClick={(photo, name) => {
                      if (type === "edit") {
                        if (images.idPhoto2 !== null) {
                          const obj = checkIfPhotoIsBroken({
                            idPhoto: images.idPhoto2,
                            photo,
                            name,
                            indicator: "photo2"
                          });
                          handleImg(obj, images.idPhoto2);
                        } else {
                          let obj = {
                            id: "",
                            image: photo,
                            action: "add",
                            filename: name,
                            indicator: "photo2"
                          };
                          handleImg(obj, "");
                        }
                      } else {
                        setImages((prev) => ({
                          ...prev,
                          1: {
                            photo2: photo,
                            photo2name: name
                          }
                        }));
                      }
                    }}
                    onDelete={(imageRef) => {
                      imageRef.current.value = null;
                      if (images.idPhoto2 != null) {
                        setNullStateImage(["idPhoto2", "photo2"]);
                        let obj = {
                          id: images.idPhoto2,
                          image: images.photo2,
                          action: "delete",
                        };
                        handleImg(obj, images.idPhoto2);
                        return;
                      }
                      deleteRefPhoto("photo2");
                      deleteStateImage(1);
                    }}
                  />
                  <UploadImage
                    img={images.photo3 ? images.photo3 : ""}
                    aspectRatio={1 / 1}
                    name={"photo3"}
                    width={40}
                    height={40}
                    position={"col"}
                    onClick={(photo, name) => {
                      if (type === "edit") {
                        if (images.idPhoto3 !== null) {
                          const obj = checkIfPhotoIsBroken({
                            idPhoto: images.idPhoto3,
                            photo,
                            name,
                            indicator: "photo3"
                          });
                          handleImg(obj, images.idPhoto3);
                        } else {
                          let obj = {
                            id: "",
                            image: photo,
                            action: "add",
                            filename: name,
                            indicator: "photo3"
                          };
                          handleImg(obj, "");
                        }
                      } else {
                        setImages((prev) => ({
                          ...prev,
                          2: {
                            photo3: photo,
                            photo3name: name
                          }
                        }));
                      }
                    }}
                    onDelete={(imageRef) => {
                      imageRef.current.value = null;
                      if (images.idPhoto3 != null) {
                        setNullStateImage(["idPhoto3", "photo3"]);
                        let obj = {
                          id: images.idPhoto3,
                          image: images.photo3,
                          action: "delete",
                        };
                        handleImg(obj, images.idPhoto3);
                        return;
                      }
                      deleteRefPhoto("photo3");
                      deleteStateImage(2);
                    }}
                  />
                  <UploadImage
                    img={images.photo4 ? images.photo4 : ""}
                    aspectRatio={1 / 1}
                    name={"photo4"}
                    width={40}
                    height={40}
                    position={"col"}
                    onClick={(photo, name) => {
                      if (type === "edit") {
                        if (images.idPhoto4 !== null) {
                          const obj = checkIfPhotoIsBroken({
                            idPhoto: images.idPhoto4,
                            photo,
                            name,
                            indicator: "photo4"
                          });
                          handleImg(obj, images.idPhoto4);
                        } else {
                          let obj = {
                            id: "",
                            image: photo,
                            action: "add",
                            filename: name,
                            indicator: "photo4"
                          };
                          handleImg(obj, "");
                        }
                      } else {
                        setImages((prev) => ({
                          ...prev,
                          3: {
                            photo4: photo,
                            photo4name: name
                          }
                        }));
                      }
                    }}
                    onDelete={(imageRef) => {
                      imageRef.current.value = null;
                      if (images.idPhoto4 != null) {
                        setNullStateImage(["idPhoto4", "photo4"]);
                        let obj = {
                          id: images.idPhoto4,
                          image: images.photo4,
                          action: "delete",
                        };
                        handleImg(obj, images.idPhoto4);
                        return;
                      }
                      deleteRefPhoto("photo4");
                      deleteStateImage(3);
                    }}
                  />
                  <UploadImage
                    img={images.photo5 ? images.photo5 : ""}
                    aspectRatio={1 / 1}
                    name={"photo5"}
                    width={40}
                    height={40}
                    position={"col"}
                    onClick={(photo, name) => {
                      if (type === "edit") {
                        if (images.idPhoto5 !== null) {
                          const obj = checkIfPhotoIsBroken({
                            idPhoto: images.idPhoto5,
                            photo,
                            name,
                            indicator: "photo5"
                          });
                          handleImg(obj, images.idPhoto5);
                        } else {
                          let obj = {
                            id: "",
                            image: photo,
                            action: "add",
                            filename: name,
                            indicator: "photo5"
                          };
                          handleImg(obj, "");
                        }
                      } else {
                        setImages((prev) => ({
                          ...prev,
                          4: {
                            photo5: photo,
                            photo5name: name
                          }
                        }));
                      }
                    }}
                    onDelete={(imageRef) => {
                      imageRef.current.value = null;
                      if (images.idPhoto5 != null) {
                        setNullStateImage(["idPhoto5", "photo5"]);
                        let obj = {
                          id: images.idPhoto5,
                          image: images.photo5,
                          action: "delete",
                        };
                        handleImg(obj, images.idPhoto5);
                        return;
                      }
                      deleteRefPhoto("photo5");
                      deleteStateImage(4);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* <p className="text-red-500">{error.photo}</p> */}
          </div>
        </div>
        <div className="rounded-2xl p-5 bg-gray-primary mt-4">
          <h4 className="opacity-60 font-bold w-60 mb-5">
            {t("products.information").toUpperCase()}
          </h4>

          <FormInputRadio
            title={t("products.category")}
            subtitle={t("products.category_subtitle")}
            value={typeForm}
            onChange={(value) => chooseType(value)}
            category={category}
            type={type}
          />

          {typeForm === goldbarId && (
            <GoldBarForm
              id={id}
              setImages={setImages}
              type={type}
              goldbarId={goldbarId}
              images={images}
              onSucces={onSucces}
              updateImg={updateImg}
            />
          )}

          {typeForm === jewelryId && (
            <JewelryForm
              id={id}
              setImages={setImages}
              jewelryId={jewelryId}
              subCategory={subCategory}
              type={type}
              images={images}
              onSucces={onSucces}
              updateImg={updateImg}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default Add;
