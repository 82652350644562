import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "sidebar",
  initialState: {
    show: true,
    pageActive: "",
    menu: [],
    notifCount: {
      complaintMenu: 0,
      newOrderMenu: 0,
    },
    countMessage: 0
  },
  reducers: {
    setShow: (state, action) => {
      state.show = action.payload;
    },
    setPageActive: (state, action) => {
      state.pageActive = action.payload;
    },
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setNotifCount: (state, action) => {
      state.notifCount = action.payload;
    },
    setCountMessage: (state, action) => {
      state.countMessage = action.payload;
    },
  },
});

export const {
  setShow,
  setPageActive,
  setMenu,
  setNotifCount,
  setCountMessage
 } = reducerSlice.actions;
export default reducerSlice;
