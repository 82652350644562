import React from "react";
import { useTranslation } from "react-multi-lang";
import { useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import StoreHelper from "../../services/store-helper";
import Action from "./redux/Action";
import reducerSlice from "./redux/Reducer";
import FreqAskQuest from "./component/FAQ/FreqAskQuest";
import Feedback from "./component/Feedback/Feedback";
import AboutUs from "./component/AboutUs/AboutUs";

const HelpCenter = ({ getBreadcrums }) => {
  const store = StoreHelper.generateStoreState(reducerSlice);

  const MainComponent = () => {
    const t = useTranslation();
    store.getState();
    const page = useSelector((state) => state.reducer.page);

    const nav = [
      { title: t("helpcenter.faq"), route: "faq" },
      { title: t("helpcenter.feedback"), route: "feedback" },
      { title: t("helpcenter.about_us"), route: "aboutUs" },
    ];

    const Parent = () => {
      if (page === "faq") {
        return <FreqAskQuest />;
      } else if (page === "feedback") {
        return <Feedback />;
      } else if (page === "aboutUs") {
        return <AboutUs />;
      }
    };

    useEffect(() => {
      Action.getPage(store, { page });
      getBreadcrums(t("helpcenter.title"));
    }, []);

    return (
      <>
        <div className="flex flex-row items-center px-2 gap-x-2">
          <img
            className="h-full w-5"
            src={process.env.REACT_APP_ASSETS_IMAGE + "helpcenter-icon-header.svg"}
            alt="helpcenter-icon-header"
          />
          <h2 className="text-green-primary font-bold">
            {t("helpcenter.title")}
          </h2>
        </div>

        <div className="flex flex-row gap-x-3 pt-3">
          <div>
            <nav className="bg-white rounded-xl">
              <ul className="flex-col items-center -space-x-px">
                {nav.map((item, index) => (
                  <li
                    key={index}
                    className="cursor-pointer w-72"
                    onClick={() => {
                      Action.getPage(store, { page: item.route });
                    }}
                  >
                    <div
                      className={`${page === item.route ? "bg-gray-primary" : ""
                        } flex items-center px-6 py-3 rounded-xl`}
                    >
                      <h4 className="grow font-semibold">{item.title}</h4>
                      <i className="arrow right w-2 h-2"></i>
                    </div>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <div className="w-full max-h-[39rem] overflow-y-auto">
            <Parent />
          </div>
        </div>
      </>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default HelpCenter;
