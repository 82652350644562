import { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import ButtonGreen from "../../../../../../components/ButtonGreen/ButtonGreen";
import Action from "../redux/Action";
import DateUtility from "../../../../../../utils/date-utility";

const CalendarView = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const event = useSelector((state) => state.reducer.event);

  useEffect(() => {
    Action.getHolidateData(dispatch);
  }, []);

  return (
    <div>
      <div className="mb-3 flex flex-row">
        {event.status && event.status === 1 ? (
          <div className="flex-1 pl-10 font-semibold">
            {DateUtility.formatDateWithDay(event.startDate)} -{" "}
            {DateUtility.formatDateWithDay(event.endDate)}
          </div>
        ) : null}
        <div className="mb-3 flex justify-end">
          <ButtonGreen
            title={t("holidaydate.set_holiday_date")}
            onClick={() => {
              Action.changeShowForm(dispatch, { showForm: true });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarView;
