import React, { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import UploadImage from "../../../../components/UploadImage/UploadImage";
import RegisterRepo from "../../../../repositories/RegisterRepo";
import Action from "../redux/Action";
import {
  setErrorApiCheck,
  setLegalDocuments,
  setValidateStatus,
} from "../redux/Reducer";
import GeneralUtility from "../../../../utils/general-utility";
import PopupUtility from "../../../../utils/popup-utility";

const LegalDocuments = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const legalDocuments = useSelector((state) => state.reducer.legalDocuments);
  const errorLegalDocuments = useSelector(
    (state) => state.reducer.errorLegalDocuments
  );
  const errorApiCheck = useSelector((state) => state.reducer.errorApiCheck);
  const validateStatus = useSelector((state) => state.reducer.validateStatus);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "ktpNik" || name == "merchantNib" || name == "npwpNumber") {
      dispatch(
        setLegalDocuments({
          ...legalDocuments,
          [name]: GeneralUtility.removeNonNumeric(value),
        })
      );
    } else {
      dispatch(setLegalDocuments({ ...legalDocuments, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setValidateStatus(true));
    const isValid = Action.validateStep3(dispatch, legalDocuments);
    if (Object.keys(isValid).length === 0) {
      const res = await RegisterRepo.checkLegalDoc(legalDocuments);
      if (res.error === false) {
        Action.getPage(dispatch, "security");
      } else {
        dispatch(setErrorApiCheck(res.error_message));
        if (res.status === 500) {
          PopupUtility.responseValidate("Failed", res.error_message);
        }
      }
    }
  };

  useEffect(() => {
    if (validateStatus === true) {
      Action.validateStep3(dispatch, legalDocuments);
    } else {
      dispatch(setValidateStatus(true));
    }
  }, [legalDocuments]);

  return (
    <form>
      <div className="flex flex-col gap-y-4 md:h-[30em] overflow-y-auto">
        {/* upload KTP */}
        <div className="flex flex-row gap-x-3">
          <div className="w-44">
            <div className="text-md font-bold">{t("register.upload_ktp")}</div>
            <div className="opacity-40">
              {t("general.support_files")} jpeg,jpg,png
            </div>
            <div className="opacity-40">{t("general.max_size")} 5Mb</div>
          </div>
          <div className="flex-1">
            <UploadImage
              img={legalDocuments.ktpFile}
              aspectRatio={16 / 9.8}
              name={"ktpFile"}
              type="rectangle"
              position={"col"}
              onClick={(photo, name) =>
                dispatch(
                  setLegalDocuments({
                    ...legalDocuments,
                    ktpFile: photo,
                    ktpFileName: name,
                  })
                )
              }
              onDelete={(imageRef) => {
                imageRef.current.value = null;
              }}
            />
            <p className="text-red-500">{errorLegalDocuments.ktpFile}</p>
          </div>
        </div>
        {/* NIK */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-44">
            <div
              className={`text-md font-bold ${errorLegalDocuments.ktpNik ? "pb-4" : " "
                }`}
            >
              {t("register.nik")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <input
              type="text"
              name="ktpNik"
              onChange={handleChange}
              placeholder={t("register.input_nik")}
              className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${errorLegalDocuments.ktpNik
                ? "border-red-500 border-1"
                : "border-0"
                }`}
              value={legalDocuments.ktpNik}
              maxLength={20}
            />
            <p className="text-red-500">{errorLegalDocuments.ktpNik}</p>
            <p className="text-red-500">{errorApiCheck.ktpNik}</p>
          </div>
        </div>
        {/* upload NPWP */}
        <div className="flex flex-row gap-x-3">
          <div className="w-44">
            <div className="text-md font-bold">{t("register.upload_npwp")}</div>
            <div className="opacity-40">
              {t("general.support_files")} jpeg,jpg,png
            </div>
            <div className="opacity-40">{t("general.max_size")} 5Mb</div>
          </div>
          <div className="flex-1">
            <UploadImage
              img={legalDocuments.npwpFile}
              aspectRatio={16 / 9.8}
              name={"npwpFile"}
              type="rectangle"
              position={"col"}
              onClick={(photo, name) =>
                dispatch(
                  setLegalDocuments({
                    ...legalDocuments,
                    npwpFile: photo,
                    npwpFileName: name,
                  })
                )
              }
              onDelete={(imageRef) => {
                imageRef.current.value = null;
              }}
            />
            <p className="text-red-500">{errorLegalDocuments.npwpFile}</p>
          </div>
        </div>
        {/* NPWP Number */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-44">
            <div
              className={`text-md font-bold ${errorLegalDocuments.npwpNumber ? "pb-4" : " "
                }`}
            >
              {t("register.npwp_number")}
              {/* <span className="text-gold-secondary">*</span> */}
            </div>
          </div>
          <div className="flex-1">
            <input
              type="text"
              name="npwpNumber"
              onChange={handleChange}
              placeholder={t("register.input_npwp_number")}
              className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${errorLegalDocuments.npwpNumber
                ? "border-red-500 border-1"
                : "border-0"
                }`}
              value={GeneralUtility.npwpFormat(legalDocuments.npwpNumber)}
            />
            <p className="text-red-500">{errorLegalDocuments.npwpNumber}</p>
            <p className="text-red-500">{errorApiCheck.npwpNumber}</p>
          </div>
        </div>
        {/* NIB */}
        <div className="flex flex-row items-center gap-x-3 pb-2">
          <div className="w-44">
            <div
              className={`text-md font-bold ${errorLegalDocuments.merchantNib ? "pb-4" : " "
                }`}
            >
              {t("register.nib")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <input
              type="text"
              name="merchantNib"
              onChange={handleChange}
              placeholder={t("register.input_nib")}
              className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${errorLegalDocuments.merchantNib
                ? "border-red-500 border-1"
                : "border-0"
                }`}
              value={legalDocuments.merchantNib}
              maxLength={20}
            />
            <p className="text-red-500">{errorLegalDocuments.merchantNib}</p>
            <p className="text-red-500">{errorApiCheck.merchantNib}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-row-reverse gap-x-2 pt-5">
        <button
          onClick={handleSubmit}
          className="button md:w-36 hover:bg-gray-primary text-white border-2 hover:text-green-quaternary border-gold-secondary text-xs tracking-widest bg-gold-secondary font-bold py-3 rounded-xl"
        >
          {t("register.next").toUpperCase()}
        </button>
      </div>
    </form>
  );
};

export default LegalDocuments;
