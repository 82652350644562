function BalanceModel({
  totalPrice,
  price,
  shippingPrice,
  shippingInsurance,
  serviceFee,
  dendaCancel,
  biayaAdminWd,
  bankDetail,
  name,
  commisionFee,
  dendaServiceFeeCancel,
  priceTotal,
  transServiceFee,
  heldBalance
}) {
  let obj = {};
  obj.totalPrice = totalPrice;
  obj.price = price;
  obj.shippingPrice = shippingPrice;
  obj.shippingInsurance = shippingInsurance;
  obj.serviceFee = serviceFee;
  obj.dendaCancel = dendaCancel;
  obj.biayaAdminWd = biayaAdminWd;
  obj.bankDetail = bankDetail;
  obj.name = name;
  obj.commisionFee = commisionFee;
  obj.dendaServiceFeeCancel = dendaServiceFeeCancel;
  obj.priceTotal = priceTotal;
  obj.transServiceFee = transServiceFee;
  obj.heldBalance = heldBalance;
  return obj;
}

export default BalanceModel;
