import Cookies from "js-cookie";
import AccountRepository from "../../../../../repositories/AccountRepository";
import EncryptDecrypt from "../../../../../services/encrypt-decrypt";
import PopupUtility from "../../../../../utils/popup-utility";
import {
  setSecurity,
  setErrorSecurity,
  setCanSave,
  setShowConfirmPass,
  setShowNewPass,
  setShowPass,
} from "./Reducer";

const validate = (dispatch, values) => {
  const errors = {};
  if (values.new_password.length < 8) {
    errors.char = "Password must at least 8 number!";
  }
  if (values.new_password.search(/[0-9]/) < 0) {
    errors.number = "Password must contain at least one number!";
  }
  if (values.new_password.search(/[a-z]/) < 0) {
    errors.lowercase = "Password must contain at least one lowercase!";
  }
  if (values.new_password.search(/[A-Z]/) < 0) {
    errors.uppercase = "Password must contain at least one uppercase!";
  }

  if (values.confirm_password !== values.new_password) {
    errors.confirm_password = "Password doesnt match!";
  }

  dispatch(setErrorSecurity(errors));
  if (Object.keys(errors).length === 0) {
    dispatch(setCanSave(true));
  } else {
    dispatch(setCanSave(false));
  }
  return errors;
};

const handleChange = (dispatch, e, security) => {
  const { name, value } = e.target;
  dispatch(setSecurity({ ...security, [name]: value }));
};

const handleSubmit = async (dispatch, e, security) => {
  e.preventDefault();
  let data = {
    currentPassword: security.password,
    newPassword: security.new_password,
  };
  const response = await AccountRepository.updatePassword(data);
  if (response.error === false) {
    const resData = response.data.data;
    if (resData.userId) {
      let user = {};
      user.userId = resData.userId;
      user.merchantsId = resData.merchantsId;
      user.merchantName = resData.merchantName;
      user.merchantCode = resData.merchantCode;
      user.merchantStatus = resData.merchantStatus;
      user.merchantStatusName = resData.merchantStatusName;
      user.email = resData.email;
      user.balance = resData.balance;

      //set session on cookie and encrypt the data
      Cookies.set(
        "token",
        EncryptDecrypt.enc(resData.auth.token, { expires: 1 })
      );
      Cookies.set("userMerchant", EncryptDecrypt.enc(user), { expires: 1 });
      Cookies.set("merchantPhoto", EncryptDecrypt.enc(resData.merchantPhoto), {
        expires: 1,
      });
    }
    PopupUtility.success("Changes saved successfully");
    dispatch(
      setSecurity({
        password: "",
        new_password: "",
        confirm_password: "",
      })
    );
    dispatch(setShowConfirmPass(false));
    dispatch(setShowNewPass(false));
    dispatch(setShowPass(false));
  } else {
    PopupUtility.responseValidate(response.message, response.error_message);
  }
};

const Action = {
  validate,
  handleSubmit,
  handleChange,
};

export default Action;
