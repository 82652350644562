import { Provider, useDispatch, useSelector } from "react-redux";
import StoreHelper from "../../../../services/store-helper";
import HeaderOrder from "../HeaderOrder";
import CancelledList from "./components/CancelledList";
import Action from "./redux/Action";
import reducerSlice, {
  setEndDateFilter,
  setStartDateFilter,
} from "./redux/Reducer";

const Cancelled = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  const MainComponent = () => {
    const dispatch = useDispatch();
    const search = useSelector((state) => state.reducer.search);
    const sort = useSelector((state) => state.reducer.sort);
    const startDateFilter = useSelector(
      (state) => state.reducer.startDateFilter
    );
    const endDateFilter = useSelector((state) => state.reducer.endDateFilter);
    return (
      <>
        <HeaderOrder
          onChangeSearch={(value) => {
            Action.emptyList(dispatch);
            Action.getList(dispatch, {
              currentList: [],
              search: value,
              sort: sort,
              startDateFilter: startDateFilter,
              endDateFilter: endDateFilter,
              page: 0,
            });
          }}
          onChangeSort={(value) => {
            Action.emptyList(dispatch);
            Action.getList(dispatch, {
              currentList: [],
              search: search,
              sort: value,
              startDateFilter: startDateFilter,
              endDateFilter: endDateFilter,
              page: 0,
            });
          }}
          startDateFilter={startDateFilter}
          endDateFilter={endDateFilter}
          datePickerOnOpen={() => {
            dispatch(setStartDateFilter(""));
            dispatch(setEndDateFilter(""));
          }}
          datePickerOnChange={(dateObject) => {
            let d0 = new Date(dateObject[0]);
            if (dateObject.length > 1) {
              let d1 = new Date(dateObject[1]);
              dispatch(setStartDateFilter(d0.toISOString()));
              dispatch(setEndDateFilter(d1.toISOString()));
            } else {
              dispatch(setStartDateFilter(d0.toISOString()));
              dispatch(setEndDateFilter(""));
            }
          }}
          datePickerOnClose={() => {
            Action.emptyList(dispatch);
            Action.getList(dispatch, {
              currentList: [],
              search: search,
              sort: sort,
              startDateFilter: startDateFilter,
              endDateFilter: endDateFilter,
              page: 0,
            });
          }}
        />
        <CancelledList
          search={search}
          sort={sort}
          startDateFilter={startDateFilter}
          endDateFilter={endDateFilter}
        />
      </>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default Cancelled;
