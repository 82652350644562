import AccountRepository from "../../../../../repositories/AccountRepository";
import { setData, setModalImage, setImage } from "./Reducer";

const getData = async (dispatch) => {
  const response = await AccountRepository.getLegalDocuments();
  dispatch(setData(response));
};

const changeModalImage = async (dispatch, { showModal, title, img }) => {
  dispatch(setModalImage(showModal));
  dispatch(setImage({ title: title, src: img }));
};

const Action = {
  getData,
  changeModalImage,
};

export default Action;
