function RevenueModel({
  product_sales,
  revenue_this_period,
  revenue_last_period,
  persentagePrice,
}) {
  let obj = {};
  obj.product_sales = product_sales;
  obj.revenue_this_period = revenue_this_period;
  obj.revenue_last_period = revenue_last_period;
  obj.persentagePrice = persentagePrice;
  return obj;
}

export default RevenueModel;
