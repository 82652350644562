import React from "react";
import { useTranslation } from "react-multi-lang";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const Revenue = ({ status }) => {
  const t = useTranslation();
  const isLoading = useSelector((state) => state.reducer.isLoadingRevenue);
  const revenueData = useSelector((state) => state.reducer.revenueData);
  const highestChartData = useSelector(
    (state) => state.reducer.highestChartData
  );
  const dat1 = useSelector((state) => state.reducer.revenueChartData[0]);
  const dat2 = useSelector((state) => state.reducer.revenueChartData[1]);
  const filter = useSelector((state) => state.reducer.filter);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
    display: false,
    scales: {
      y: {
        min: 0,
        max: highestChartData < 1 ? 100000 : highestChartData,
        ticks: {
          maxTicksLimit: 10,
        },
      },
      x: {
        grid: {
          display: false,
        },
        offset: true,
      },
    },
  };

  const labels = [];

  let data = {
    labels,
    datasets: [
      {
        fill: true,
        label: t("general.this_period"),
        data: dat2,
        borderColor: "#BBAB7F",
        backgroundColor: "#92815445",
        lineTension: 0.3,
        borderDash: [10, 5],
      },
      {
        fill: true,
        label: t("general.last_period"),
        data: dat1,
        borderColor: "#5D7D73",
        backgroundColor: "#D6DBD3",
        lineTension: 0.3,
      },
    ],
  };

  return (
    <div className="section-grey p-5 h-[49vh] overflow-y-auto w-full">
      <h2 className="text-sm mb-2 font-semibold tracking-widest text-[#A7ADA9]">
        {t("dashboard.revenue").toUpperCase()}
      </h2>

      <div className="grid grid-cols-12">
        <div
          className={`${
            status
              ? "lg:col-span-3 md:col-span-3"
              : "lg:col-span-4 md:col-span-6"
          }`}
        >
          {isLoading ? (
            <>
              <div className="my-2 text-sm font-bold">
                {t("dashboard.total_sales_all_merchant", { params: filter })}
              </div>
              <div className="my-3 pt-3">
                <div className="text-xs font-medium text-green-quaternary">
                  {t("dashboard.total_product_sales")}
                </div>
                <Skeleton height="40px" />
              </div>
              <div className="my-3">
                <div className="text-xs font-medium text-green-quaternary">
                  {t("general.this_period")}
                </div>
                <Skeleton height="40px" />
              </div>
              <div className="my-3">
                <div className="text-xs font-medium text-green-quaternary">
                  {t("general.last_period")}
                </div>
                <Skeleton height="40px" />
              </div>
            </>
          ) : (
            <>
              <div className="my-2 text-sm font-bold">
                {t("dashboard.total_sales_all_merchant", { params: filter })}
              </div>
              <div className="my-3 pt-3">
                <div className="text-xs font-medium text-green-quaternary">
                  {t("dashboard.total_product_sales")}
                </div>
                <div className="text-xl font-extrabold">
                  {revenueData.product_sales}
                </div>
              </div>
              <div className="my-3">
                <div className="text-xs font-medium text-green-quaternary">
                  This {filter}
                </div>
                <div className="text-xl font-extrabold">
                  <span className="text-sm align-top">Rp</span>{" "}
                  {revenueData.revenue_this_period}
                </div>
                <div className="text-sm">
                  <span
                    className={`${
                      revenueData.persentagePrice >= 0
                        ? "text-[#5eca98]"
                        : "text-red-500"
                    } font-semibold `}
                  >
                    {revenueData.persentagePrice >= 0
                      ? `↑ ${revenueData.persentagePrice}`
                      : `↓ ${revenueData.persentagePrice * -1}`}
                    %
                  </span>{" "}
                  from last {filter.toLowerCase()}
                </div>
              </div>
              <div className="my-3">
                <div className="text-xs font-medium text-green-quaternary">
                  Last {filter}
                </div>
                <div className="text-xl font-extrabold">
                  <span className="text-sm align-top">Rp</span>{" "}
                  {revenueData.revenue_last_period}
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className={`${
            status
              ? "lg:col-span-9 md:col-span-9"
              : "lg:col-span-8 md:col-span-6"
          }`}
        >
          <Line options={options} data={data} />
        </div>
      </div>
    </div>
  );
};

export default Revenue;
