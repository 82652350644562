import RegisterRepo from "../../../repositories/RegisterRepo";
import {
  setCity,
  setDistrict,
  setPage,
  setCodePhone,
  setProvince,
  setErrorAccountDetails,
  setErrorBankDetails,
  setBank,
  setErrorLegalDocuments,
  setErrorSecurity,
} from "./Reducer";

const getCodePhone = async (dispatch) => {
  const phoneNumber = await RegisterRepo.getCodePhone();
  dispatch(setCodePhone(phoneNumber));
};

const getProvince = async (dispatch) => {
  const province = await RegisterRepo.getProvince();
  dispatch(setProvince(province));
};

const getCity = async (dispatch, id) => {
  let dat = { id: id };
  const city = await RegisterRepo.getCity(dat);
  dispatch(setCity(city));
};

const getBank = async (dispatch) => {
  const bank = await RegisterRepo.getBank();
  dispatch(setBank(bank));
};

const getDistrict = async (dispatch, id) => {
  let dat = { id: id };
  const district = await RegisterRepo.getDistrict(dat);
  dispatch(setDistrict(district));
};

const validateStep1 = (dispatch, values) => {
  const errors = {};
  if (!values.photo) {
    errors.photo = "Merchant Photo is required!";
  }
  if (!values.name) {
    errors.name = "Merchant name is required!";
  }
  if (!values.email) {
    errors.email = "Email is required!";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "phoneNumber number is required!";
  } else if (!values.countryCodeId) {
    errors.phoneNumber = "Code National Number is required!";
  } else if (values.phoneNumber.length <= 10) {
    errors.phoneNumber = "Phone number must at least 10 number";
  }
  if (!values.countryCodeId) {
    errors.countryCodeId = "Code National phoneNumber is required!";
  }
  if (!values.address) {
    errors.address = "Address is required!";
  }
  if (!values.provincesId) {
    errors.provincesId = "Province is required!";
  }
  if (!values.citiesId) {
    errors.citiesId = "City is required!";
  }
  if (!values.districtsId) {
    errors.districtsId = "District is required!";
  }
  if (!values.postalCode) {
    errors.postalCode = "Postal Code is required!";
  }
  dispatch(setErrorAccountDetails(errors));
  return errors;
};

const validateStep2 = (dispatch, values) => {
  const errors = {};
  if (!values.bankName) {
    errors.bankName = "Bank Account Name is required!";
  }
  if (!values.bankId) {
    errors.bankId = "Bank Name is required!";
  }
  if (!values.bankNumber) {
    errors.bankNumber = "Bank Account Number is required!";
  }
  dispatch(setErrorBankDetails(errors));
  return errors;
};

const validateStep3 = (dispatch, values) => {
  const errors = {};
  if (!values.ktpFile) {
    errors.ktpFile = "KTP is required!";
  }
  if (!values.ktpNik) {
    errors.ktpNik = "NIK is required!";
  } else if (values.ktpNik.length <= 16) {
    errors.ktpNik = "KTP must at least 16 number!";
  }

  if (!values.npwpFile) {
    errors.npwpFile = "NPWP is required!";
  }
  if (!values.npwpNumber) {
    errors.npwpNumber = "NPWP Number is required!";
  } else if (values.npwpNumber.length <= 16) {
    errors.npwpNumber = "NPWP Number must at least 16 number!";
  }
  if (!values.merchantNib) {
    errors.merchantNib = "NIB is required!";
  } else if (values.merchantNib.length <= 13) {
    errors.merchantNib = "NIB must at least 13 number!";
  }
  dispatch(setErrorLegalDocuments(errors));
  return errors;
};

const validateStep4 = (dispatch, values) => {
  const errors = {};

  if (values.password.length < 8) {
    errors.char = "Password must at least 8 number!";
  }
  if (values.password.search(/[0-9]/) < 0) {
    errors.number = "Password must contain at least one number!";
  }
  if (values.password.search(/[a-z]/) < 0) {
    errors.lowercase = "Password must contain at least one lowercase!";
  }
  if (values.password.search(/[A-Z]/) < 0) {
    errors.uppercase = "Password must contain at least one uppercase!";
  }

  if (values.confirm_password !== values.password) {
    errors.confirm_password = "Password doesnt match!";
  }

  dispatch(setErrorSecurity(errors));
  return errors;
};

const getPage = async (dispatch, page) => {
  dispatch(setPage(page));
};

const handleSignup = async (
  accountDetails,
  bankDetails,
  legalDocuments,
  security,
  termscoditions
) => {
  let data = {
    ...accountDetails,
    ...bankDetails,
    ...legalDocuments,
    ...security,
    ...termscoditions,
  };
  const signup = await RegisterRepo.signUp(data);
};

const Action = {
  getCodePhone,
  getProvince,
  getCity,
  getDistrict,
  getPage,
  getBank,
  validateStep1,
  validateStep2,
  validateStep3,
  validateStep4,
  handleSignup,
};

export default Action;
