import React, {useEffect} from "react";
import {useTranslation} from "react-multi-lang";
import {Provider, useDispatch, useSelector} from "react-redux";
import StoreHelper from "../../../services/store-helper";
import reducerSlice from "./redux/Reducer";
import Action from "./redux/Action";
import "../sidebar/Sidebar.css";

const Sidebar = () => {
    const store = StoreHelper.generateStoreState(reducerSlice);

    const MainComponent = () => {
        const currentPath = window.location.pathname;
        const dispatch = useDispatch();
        const t = useTranslation();

        const show = useSelector((state) => state.reducer.show);
        const pageActive = useSelector((state) => state.reducer.pageActive);
        const menu = useSelector((state) => state.reducer.menu);
        const notifCount = useSelector((state) => state.reducer.notifCount);
        const countMessage = useSelector((state) => state.reducer.countMessage);

        useEffect(() => {
            Action.getPageActive(dispatch, "");
            Action.getMenu(dispatch, t);

            // Panggil getnotifCount untuk pertama kali
            // saat komponen dimount;
            Action.getTotalNotif(dispatch);
            Action.getCountMessage(dispatch);


            // Gunakan setInterval untuk menjalankan getnotifCount
            // setiap 1 menit (60000 milidetik)
            const intervalId = setInterval(() => {
                Action.getTotalNotif(dispatch);
            }, 60000);

            // // Bersihkan interval saat komponen akan unmount
            return () => clearInterval(intervalId);
        }, []);

        return (
            <div
                className={`relative text-green-white text-lg duration-700 h-full ${show ? "w-52" : "w-10"}`}>
                <button
                    className={`sb-i-c absolute cursor-pointer top-5 w-7 py-2 px-2.5 ${!show && "rotate-180"}`}
                    onClick={() => Action.setMaxMin(dispatch, !show)}>
                    <img
                        src={process.env.REACT_APP_ASSETS_IMAGE + "arrow-expand.svg"}
                        alt="arrow-icon"/>
                </button>

                <div className="text-2xl text-amber-200 py-14">
                    <img
                        className="h-12"
                        src={
                            process.env.REACT_APP_ASSETS_IMAGE +
                            `${show ? "te-logo.svg" : "te-logogram.svg"}`
                        }
                        alt="Tanam Emas Logo"/>
                </div>

                {menu.map((item, index) => (
                    <div key={index}>
                        {item.route !== "/helpcenter" ? (
                            <div className="flex flex-col sb">
                                <button
                                    // to={item.route}
                                    className="hover:text-gold-primary py-3"
                                    onClick={() => {
                                        window.location.href = item.route;
                                        // Action.getPageActive(dispatch, item.title)
                                    }}>
                                    <div className="flex items-center justify-between">
                                        <div className="flex flex-row gap-x-3 items-center">
                                            <img
                                                className="h-5 w-5"
                                                src={` ${currentPath !== item.route ? item.icon : item.icon_active}`}
                                                alt={item.title}/>
                                            <div className={`${!show && "hidden"} h-5 duration-700 st`}>
                                                <span
                                                    className={`${currentPath !== item.route ? "text-white" : "text-gold-primary"} `}>
                                                  {item.title}
                                                </span>
                                            </div>
                                        </div>
                                        {/* notifikasi count orders */}
                                        {item.route === "/orders" && show && notifCount.newOrderMenu > 0 ?
                                            <span
                                                className="px-2 text-[10px] rounded-xl bg-gold-secondary relative mr-2">
                                                {notifCount.newOrderMenu}
                                              </span> : ""}
                                        {/* notifikasi count orders */}
                                        {/* notifikasi count complaint */}
                                        {item.route === "/complaint" && show && notifCount.complaintMenu > 0 ?
                                            <span
                                                className="px-2 text-[10px] rounded-xl bg-gold-secondary relative mr-2">
                                                {notifCount.complaintMenu}
                                            </span> : ""}
                                        {/* notifikasi count complaint */}
                                        {/* notifikasi count chat */}
                                        {window.location.pathname !== "/chat" ?
                                            item.route === "/chat" && show && countMessage > 0 ?
                                                <span
                                                    className="px-2 text-[10px] rounded-xl bg-gold-secondary relative mr-2">
                                                {countMessage}
                                            </span> : "" : ""}
                                        {/* notifikasi count chat */}
                                    </div>
                                </button>
                            </div>
                        ) : (
                            <div className="flex flex-col absolute bottom-0 w-full sb">
                                <button
                                    // to={item.route}
                                    className="hover:text-gold-primary py-3"
                                    onClick={() => {
                                        window.location.href = item.route;
                                        // Action.getPageActive(dispatch, item.title)
                                    }}>
                                    <div className="flex flex-row gap-x-3 items-center">
                                        <img
                                            className="h-5 w-5"
                                            src={` ${currentPath !== item.route ? item.icon : item.icon_active
                                            }`}
                                            alt={item.title}/>
                                        <div className={`${!show && "hidden"} h-5 duration-700`}>
                                            <span
                                                className={`${currentPath !== item.route ? "text-white" : "text-gold-primary"} `}>{item.title}</span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Provider store={store}>
            <MainComponent/>
        </Provider>
    );
};

export default Sidebar;
