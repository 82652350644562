import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";

const BASE_API = process.env.REACT_APP_BASE_API;

const getListData = async () => {
  const url = BASE_API + Endpoint.list_chat;
  const response = await CallApi({ url, method: "POST" });
  const res1 = response !== "undefined" ? response.data : null;
  return res1 !== null ? res1.data : null;
};

const getAutoReply = async (data) => {
  const url = BASE_API + Endpoint.getAutoReply;
  const response = await CallApi({ url, method: "POST", data });
  return typeof response != "undefined" ? response.data : null;
};

const readMessage = async (data) => {
  const url = BASE_API + Endpoint.readMessage;
  return await CallApi({ url, method: "POST", data });
};

const saveAutoReply = async (data) => {
  const url = BASE_API + Endpoint.saveAutoReply;
  return await CallApi({ url, method: "POST", data });
};

const getDetailChat = async (data) => {
  const url = BASE_API + Endpoint.getChatById;
  const response = await CallApi({ url, method: "POST", data });
  const res1 = response !== "undefined" ? response.data : null;
  return res1 !== null ? res1.data : null;
};

const uploadMediaToAws = async (data) => {
  const url = BASE_API + Endpoint.uploadMedia;
  const response = await CallApi({ url, method: "POST", data });
  const res1 = response !== "undefined" ? response.data : null;
  return res1 !== null ? res1.data : null;
};

const getCountMessage = async () => {
  const url = BASE_API + Endpoint.countMessage;
  const response = await CallApi({ url, method: "POST" });
  const response1 = typeof response != "undefined" ? response.data : null;
  const response2 =
    typeof response1 != "undefined" && response1 != null
      ? response1.data
      : null;
  return response2;
};

const ChatRepository = {
  getListData,
  getAutoReply,
  saveAutoReply,
  readMessage,
  getDetailChat,
  uploadMediaToAws,
  getCountMessage,
};

export default ChatRepository;
