import React from 'react'

const BlankChat = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="text-center text-green-chat-blank">
        <span>
          <h3 className='font-bold'>Let’s get close to your customers</h3>
          <p>Choose the message in the left side to start chat with your customer</p>
        </span>
        <div className="flex items-center justify-center mt-4">
          <img
            className="h-[300px] w-[375px] fill-slate-300"
            src={process.env.REACT_APP_ASSETS_IMAGE + "blank-chat.svg"}
            alt="search-icon"
          />
        </div>
      </div>
    </div>
  )
}

export default BlankChat