import React from "react";
import Skeleton from "react-loading-skeleton";
import {
  setTranslations,
  setDefaultLanguage,
  useTranslation,
} from "react-multi-lang";
import { useSelector } from "react-redux";
import en from "../../../../translations/en.json";
import id from "../../../../translations/id.json";
import GeneralUtility from "../../../../utils/general-utility";
setTranslations({ en, id });
setDefaultLanguage("en");

const TopSellingProducts = () => {
  const t = useTranslation();

  const isLoading = useSelector((state) => state.reducer.isLoadingTopSelling);
  const data = useSelector((state) => state.reducer.topSellingData);

  return (
    <div className="section-grey px-5 py-3 h-[40vh] overflow-y-auto">
      <h2 className="text-sm py-3 font-semibold tracking-widest text-[#A7ADA9]">
        {t("dashboard.top_selling_products").toUpperCase()}
      </h2>
      {isLoading ? (
        <Skeleton count={6} height="40px" />
      ) : (
        <>
          <table className="flex-auto w-full table-auto border-separate border-spacing-y-1 text-left">
            <thead>
              <tr>
                <th className="p-2"></th>
                <th>{t("dashboard.product_name")}</th>
                <th>{t("dashboard.sales_count")}</th>
                <th>{t("dashboard.total_sales")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr className="bg-white" key={index}>
                  <td className="p-2">{index + 1}</td>
                  <td className="font-semibold">{item.product}</td>
                  <td>{item.salesCount}</td>
                  <td>Rp. {GeneralUtility.addCommas(item.totalSales)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.length === 0 ? (
            <div className="w-full text-center">
              <img
                className="w-full max-h-[11rem] my-3"
                src={process.env.REACT_APP_ASSETS_IMAGE + "product-empty.svg"}
                alt="empty-order"
              />
              <p className="text-xl font-medium">
                {t("dashboard.empty_product")}
              </p>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default TopSellingProducts;
