import Cookies from "js-cookie";
import AccountRepository from "../../../../../repositories/AccountRepository";
import RegisterRepo from "../../../../../repositories/RegisterRepo";
import GeneralUtility from "../../../../../utils/general-utility";
import PopupUtility from "../../../../../utils/popup-utility";
import BankModel from "../../../../auth/register/model/BankModel";
import {
  setErrorBankDetails,
  setBank,
  setData,
  setEdit,
  setValidatePass,
  setCanSave,
  setPassword,
  setCountdown,
  setOtpUpdateBankDetailMerchantId,
  setErrorOtp,
  setOtp,
  setOtpPopup,
  setInfoPopup,
  setSuspendPopup,
} from "./Reducer";
import RouteName from "../../../../../services/routename";

const validate = (dispatch, values) => {
  const errors = {};
  if (!values.bankName) {
    errors.bankName = "Merchant Bank Name is required!";
  }
  if (!values.bankId) {
    errors.bankId = "Merchant Bank Id is required!";
  }
  if (!values.bankNumber) {
    errors.bankNumber = "Merchant Bank Number is required!";
  } else if (!values.bankNumber.match(/^(\s*|[1-9][0-9]*)$/)) {
    errors.bankNumber = "Merchant Bank Number is number only!";
  }

  dispatch(setErrorBankDetails(errors));
  if (Object.keys(errors).length === 0) {
    dispatch(setCanSave(true));
  } else {
    dispatch(setCanSave(false));
  }
  return errors;
};

const handleChange = (dispatch, data, e) => {
  const { name, value } = e.target;
  const newDetail = { ...data, [name]: value };
  dispatch(setData(newDetail));
};

const getBank = async (dispatch) => {
  const bank = await RegisterRepo.getBank();
  dispatch(setBank(bank));
};

const getData = async (dispatch) => {
  const response = await AccountRepository.getBankDetails();
  let arrayBank = new BankModel({
    value: response.bank.id,
    label: response.bank.name,
    code: response.bank.code,
  });
  let data = {};
  data.name = response.bankName;
  data.bankName = response.bank.name;
  data.bankId = arrayBank;
  data.bankNumber = response.bankNumber;
  dispatch(setData(data));
};

const checkValid = async (dispatch, pass, e) => {
  e.preventDefault();
  const valid = await AccountRepository.checkPass({ password: pass });
  if (valid.error === false) {
    dispatch(setValidatePass(false));
    dispatch(setEdit(true));
    dispatch(setPassword(pass));
  } else {
    PopupUtility.responseValidate(valid.message, valid.error_message.password);
  }
};

const handleSubmit = async (dispatch, bankDetails, t) => {
  let payload = {};
  payload.bankName = bankDetails.bankName;
  payload.bankId = bankDetails.bankId.value;
  payload.bankNumber = bankDetails.bankNumber;
  payload.otpUpdateBankDetailMerchantId = parseInt(bankDetails.otpUpdateBankDetailMerchantId);
  payload.otpCode = GeneralUtility.arrayToString(bankDetails.otpCode);

  const response = await AccountRepository.updateBankDetails(payload);
  if (response.error === false) {
    await getData(dispatch);
    dispatch(setEdit(false));
    dispatch(setErrorOtp(""));
    dispatch(setOtp(['', '', '', '', '', '']));
    dispatch(setOtpPopup(false));
    dispatch(setInfoPopup(false));
    PopupUtility.success("Changes saved successfully");
  } else {
    const attempt = response.error_message?.attempt;
    checkAndLogOutUser(attempt, dispatch, t);
  }
};

const checkAndLogOutUser = (attempt, dispatch, t) => {
  if (attempt >= 3) {
    dispatch(setErrorOtp(""));
    dispatch(setOtp(['', '', '', '', '', '']));
    dispatch(setOtpPopup(false));
    dispatch(setInfoPopup(false));
    dispatch(setSuspendPopup(true));
  }
  dispatch(setErrorOtp(t("balance.error_otp_message", { attempt: 3 - parseInt(attempt) })));
};

const sendOtp = async (dispatch) => {
  let response = await AccountRepository.sendOtp();
  const countDown = response.data.data.countdown;
  const otpWithdrawalMerchantId = response.data.data.id;
  dispatch(setCountdown(countDown));
  dispatch(setOtpUpdateBankDetailMerchantId(otpWithdrawalMerchantId));
};

const redirectToFeedbacks = () => {
  Cookies.set("redirectedPageBySuspendAccount", "feedback");
  window.location.href = RouteName.helpcenter;
};

const Action = {
  validate,
  handleSubmit,
  handleChange,
  getBank,
  getData,
  checkValid,
  sendOtp,
  redirectToFeedbacks
};

export default Action;
