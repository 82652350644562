import React from "react";
import { useTranslation } from "react-multi-lang";
import InputText from "../../../../components/FormInput/InputText";
import InputTextCustom from "../../../../components/FormInput/InputTextCustom";

const FormInputCategory = ({
  title,
  subtitle,
  required,
  array,
  onChange,
  valueCheckStone,
  valueCategory,
  errorCategory,
  valueCertificationCode,
  errorCertificationCode,
  valueCaratWeight,
  errorCaratWeight,
  valueColorGrade,
  errorColorGrade,
  valueStoneWeight,
  errorStoneWeight,
  valueClarityGrade,
  errorClarityGrade,
  setContainStone,
  disabled,
}) => {
  const t = useTranslation();
  return (
    <div className="flex flex-row gap-x-4 pt-8">
      <div className="w-72">
        <div className="font-bold">
          {title}
          {required ? <span className="text-gold-secondary">*</span> : null}
        </div>

        <div className="pt-3 w-60">{subtitle}</div>
      </div>
      <div className="flex-1">
        {/* productSubCategoryId */}
        <select
          className={`text-sm border rounded-xl w-full bg-white font-medium ${errorCategory ? "border-red-500" : "border-gray-primary"
            }`}
          name="productSubCategoryId"
          value={valueCategory ? valueCategory : "default"}
          onChange={(e) => {
            onChange(e);
          }}
          disabled={disabled}
        >
          <option value="default" disabled>
            {t("products.input_jewelry_category")}
          </option>
          {array.map((item, index) => (
            <option value={item.id} key={index}>
              {item.name}
            </option>
          ))}
        </select>

        <p className="text-red-500">{errorCategory}</p>

        {/* checkStone */}
        <div className="flex items-center pt-3 pl-2">
          <input
            disabled={disabled}
            type="checkbox"
            className="rounded-md checked:bg-gold-secondary focus:ring-gray-primary"
            id="checkStone"
            onChange={(e) => {
              setContainStone(e.target.checked);
            }}
            checked={valueCheckStone}
          />
          <label htmlFor="checkStone" className="pl-3 font-bold">
            {t("products.stone")}
          </label>
        </div>

        {valueCheckStone ? (
          <div className="pt-5 pl-9 ">
            <div className="font-bold">{t("products.stone_title")}</div>
            <div className="pt-3 w-80">{t("products.stone_subtitle")}</div>

            <div className="flex flex-col gap-y-4 pt-5">
              {/* certificationCode */}
              <div className="flex-auto">
                <p className="pl-3 pb-2 font-bold text-green-secondary">
                  {t("products.certification")}
                  {required ? (
                    <span className="text-gold-secondary">*</span>
                  ) : null}
                </p>
                <InputText
                  placeholder={t("products.input_certification")}
                  name="certificationCode"
                  value={valueCertificationCode}
                  error={errorCertificationCode}
                  onChange={(val) => {
                    onChange(val);
                  }}
                  maxLength={20}
                  height={""}
                  disabled={disabled}
                />
              </div>

              <div className="flex flex-row gap-x-4">
                {/* gramCaratWeight */}
                {/* <div className="flex-auto">
                  <p className="pl-3 pb-2 font-bold text-green-secondary">
                    {t("products.carat_weight")}
                    {required ? (
                      <span className="text-gold-secondary">*</span>
                    ) : null}
                  </p>
                  <InputTextCustom
                    placeholder={t("products.input_carat_weight")}
                    name="caratWeight"
                    value={valueCaratWeight}
                    error={errorCaratWeight}
                    onChange={(value) => {
                      onChange(value);
                    }}
                    maxLength={255}
                    unit="gr"
                    disabled={disabled}
                  />
                </div> */}

                {/* gramDiamondWeight */}
                <div className="flex-auto">
                  <p className="pl-3 pb-2 font-bold text-green-secondary">
                    {t("products.stone_weight")}
                    {required ? (
                      <span className="text-gold-secondary">*</span>
                    ) : null}
                  </p>
                  <InputTextCustom
                    placeholder={t("products.input_stone_weight")}
                    name="preciousStoneWeight"
                    value={valueStoneWeight}
                    error={errorStoneWeight}
                    onChange={(value) => {
                      onChange(value);
                    }}
                    maxLength={255}
                    unit="ct "
                    disabled={disabled}
                  />
                </div>
              </div>

              <div className="flex flex-row gap-x-4">
                {/* colorGrade */}
                <div className="flex-auto">
                  <p className="pl-3 pb-2 font-bold text-green-secondary">
                    {t("products.color_grade")}
                    {required ? (
                      <span className="text-gold-secondary">*</span>
                    ) : null}
                  </p>
                  <InputText
                    placeholder={t("products.input_color_grade")}
                    name="colorGrade"
                    value={valueColorGrade}
                    error={errorColorGrade}
                    onChange={(val) => {
                      onChange(val);
                    }}
                    maxLength={20}
                    height={""}
                    disabled={disabled}
                  />
                </div>

                {/* clarityGrade */}
                <div className="flex-auto">
                  <p className="pl-3 pb-2 font-bold text-green-secondary">
                    {t("products.clarity_grade")}
                    {required ? (
                      <span className="text-gold-secondary">*</span>
                    ) : null}
                  </p>
                  <InputText
                    placeholder={t("products.input_clarity_grade")}
                    name="clarityGrade"
                    value={valueClarityGrade}
                    error={errorClarityGrade}
                    onChange={(val) => {
                      onChange(val);
                    }}
                    maxLength={20}
                    height={""}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FormInputCategory;
