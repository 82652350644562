import OperationalHourRepository from "../../../../../../repositories/OperationalHourRepository";
import DateUtility from "../../../../../../utils/date-utility";
import PopupUtility from "../../../../../../utils/popup-utility";
import {
  setDataFriday,
  setDataMonday,
  setDataSaturday,
  setDataSunday,
  setDataThursday,
  setDataTuesday,
  setDataWednesday,
  setEdit,
  setInitialData,
  setLoadingData,
} from "./Reducer";

const getOperationalHours = async (dispatch) => {
  dispatch(setLoadingData(true));

  let response = await OperationalHourRepository.getOperationalHours();

  if (!response.error) {
    let data = response.data;
    dispatch(setInitialData(data));
    dispatch(setDataMonday(data[0]));
    dispatch(setDataTuesday(data[1]));
    dispatch(setDataWednesday(data[2]));
    dispatch(setDataThursday(data[3]));
    dispatch(setDataFriday(data[4]));
    dispatch(setDataSaturday(data[5]));
    dispatch(setDataSunday(data[6]));
    dispatch(setLoadingData(false));
  }
};

const updateOperationalHours = async (
  dispatch,
  { monday, tuesday, wednesday, thursday, friday, saturday, sunday }
) => {
  let array = [monday, tuesday, wednesday, thursday, friday, saturday, sunday];
  let response;

  for (let i = 0; i < array.length; i++) {
    let data = {};
    if (array[i].id !== "") {
      data.id = array[i].id;
      data.dayOfWeek = array[i].day;
      data.startTime = DateUtility.timeDate(array[i].openTime, false);
      data.endTime = DateUtility.timeDate(array[i].closeTime, false);
      data.status = array[i].isOpen;
    } else {
      data.dayOfWeek = array[i].day;
      data.startTime = DateUtility.timeDate(array[i].openTime, false);
      data.endTime = DateUtility.timeDate(array[i].closeTime, false);
      data.status = array[i].isOpen;
    }
    response = await OperationalHourRepository.updateOperationalHours(data);
  }

  if (!response.error) {
    PopupUtility.success("Changes saved successfully");
  } else {
    PopupUtility.responseValidate("Error", response.message);
  }

  getOperationalHours(dispatch);
  dispatch(setEdit(false));
};

const Action = {
  getOperationalHours,
  updateOperationalHours,
};

export default Action;
