const ToggleActive = ({ status, changeStatus, desc }) => {
    return (
        <div className="flex items-center mb-4">
            <label className="inline-flex relative items-center cursor-pointer">
                <input
                    type="checkbox"
                    name="status"
                    defaultChecked={status}
                    onChange={(e) => {
                        changeStatus();
                    }}
                    className="sr-only peer "
                />
                <div className="w-8 h-4 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[0.08rem] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[14px] after:w-[14px] after:transition-all dark:border-gray-600 peer-checked:bg-green-primary"></div>
            </label>
            <span className="block ml-2">{desc ?? "yoho"}</span>
        </div>
    );
};

export default ToggleActive;