import { useDispatch, useSelector } from "react-redux";
import Action from "../redux/Action";

const FaqQuestion = ({ question }) => {
  const dispatch = useDispatch();
  const showQuestionId = useSelector((state) => state.reducer.showQuestionId);

  return (
    <div className="bg-white rounded-xl mb-2">
      <button className="w-full flex p-2 items-center" onClick={() => { Action.changeShowQuestionId(dispatch, { currentQuestionId: showQuestionId, questionId: question.id }) }}>
        <p className="flex-1 text-left text-md font-bold">{question.title}</p>
        <i className={`arrow w-2 h-2 ${showQuestionId === question.id ? "up" : "down"}`}></i>
        
        {/* <div className="text-lg font-bold">
          {showQuestionId === question.id ? "-" : "+"}
        </div> */}
      </button>
      {showQuestionId === question.id ?
        <p className="px-2 pb-2">{question.answer}</p>
        :
        <></>
      }
    </div>
  )
}

export default FaqQuestion;