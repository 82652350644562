function OperationalItemModel({ id, isOpen, day, openTime, closeTime, type }) {
  let obj = {};
  obj.id = id;
  obj.day = day;
  obj.isOpen = isOpen;
  obj.openTime = openTime;
  obj.closeTime = closeTime;
  obj.type = type;
  return obj;
}

export default OperationalItemModel;
