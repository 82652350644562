import {createSlice} from "@reduxjs/toolkit";

const reducerSlice = createSlice({
    name: "base",
    initialState: {
        breadcrums: [],
        translation: "en",
        countNotifChat: 0
    },
    reducers: {
        setBreadcrums: (state, action) => {
            state.breadcrums = action.payload;
        },
        setTranslation: (state, action) => {
            state.translation = action.payload;
        },
        setCountNotifChat: (state, action) => {
            state.countNotifChat = action.payload;
        }
    },
});

export const {
    setBreadcrums,
    setTranslation,
    setCountNotifChat
} = reducerSlice.actions;
export default reducerSlice;
