import React from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import ButtonGreen from "../../../components/ButtonGreen/ButtonGreen";
import { setPopup } from "../redux/Reducer";
import PopupWithdraw from "./PopupWithdraw";
import GeneralUtility from "../../../utils/general-utility";
import Action from "../redux/Action";

const DetailBalance = () => {
  const dispatch = useDispatch();
  const popup = useSelector((state) => state.reducer.popup);
  const data = useSelector((state) => state.reducer.data);
  const currentStatus = useSelector((state) => state.reducer.currentStatus);
  const t = useTranslation();
  return (
    <div className="bg-gray-primary rounded-xl py-4 px-2 ">
      <div className="text-sm text-gray-secondary font-semibold ml-3 mb-4">
        {t("balance.details").toUpperCase()}
      </div>
      <div className="bg-white rounded-xl m-2">
        <div className="flex p-2">
          <div>
            <div className="text-xs text-green-quaternary">
              {t("balance.title")}
            </div>
          </div>
          <div className="flex-1 text-right">
            <div className="text-green-primary text-xl font-extrabold">
              <span className="text-sm align-top">Rp </span>
              {GeneralUtility.addCommas(data.price ?? 0)}
            </div>
          </div>
        </div>
      </div>
      <div className="p-2">
        <span className="inline-block mb-1 font-extrabold text-green-secondary text-md">{t("balance.held_balance")} : Rp{data.heldBalance}</span>
        <span className="inline-block text-gray-500">{t("balance.desc_held_balance")}</span>
      </div>
      <div className="text-right pt-3">
        {data.price > 0 && currentStatus !== -1 ? (
          <ButtonGreen
            title={t("balance.withdraw")}
            onClick={() => {
              dispatch(setPopup(true));
            }}
          />
        ) : (
          <button className={`
            button md:w-36 text-xs tracking-widest font-bold border-2  border-opacity-40 rounded-xl py-2 
            ${data.price <= 0 || currentStatus === -1 ? 'text-white bg-slate-400 border-slate-400 cursor-not-allowed' : 'text-green-secondary border-green-secondary cursor-pointer'}
          `}>
            {t("balance.withdraw").toUpperCase()}
          </button>
        )}
      </div>

      {popup && (
        <PopupWithdraw
          onCancel={() => {
            dispatch(setPopup(false));
          }}
          data={data}
          onSubmit={() => {
            Action.postWd({
              dispatch,
              data
            });
          }}
        />
      )}
    </div>
  );
};

export default DetailBalance;
