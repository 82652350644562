import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";

const BASE_API = process.env.REACT_APP_BASE_API;

const getAccountDetails = async () => {
  const url = BASE_API + Endpoint.account_detail;
  const response = await CallApi({ url, method: "POST" });
  const data = typeof response != "undefined" ? response.data : null;
  const data2 = typeof data != "undefined" && data != null ? data.data : [];
  return data2;
};

const getBankDetails = async () => {
  const url = BASE_API + Endpoint.bank_detail;
  const response = await CallApi({ url, method: "POST" });
  const data = typeof response != "undefined" ? response.data : null;
  const data2 = typeof data != "undefined" && data != null ? data.data : [];
  return data2;
};

const getLegalDocuments = async () => {
  const url = BASE_API + Endpoint.legal_documents;
  const response = await CallApi({ url, method: "POST" });
  const data = typeof response != "undefined" ? response.data : null;
  const data2 = typeof data != "undefined" && data != null ? data.data : [];
  return data2;
};

const checkPass = async (data) => {
  const url = BASE_API + Endpoint.checkPass;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const updateBankDetails = async (dat) => {
  let data = new FormData();
  data.append("bankName", dat.bankName);
  data.append("bankId", dat.bankId);
  data.append("bankNumber", dat.bankNumber);
  data.append("otpUpdateBankDetailMerchantId", dat.otpUpdateBankDetailMerchantId);
  data.append("otpCode", dat.otpCode);

  const url = BASE_API + Endpoint.update_bank_detail;
  const response = await CallApi({
    url,
    method: "POST",
    data: data,
    type: "multipart",
  });
  return response;
};

const updateAccountDetail = async (dat) => {
  const url = BASE_API + Endpoint.update_account_detail;
  const response = await CallApi({
    url,
    method: "POST",
    data: dat,
    type: "multipart",
  });
  return response;
};

const updatePassword = async (dat) => {
  const url = BASE_API + Endpoint.updatePassword;
  const response = await CallApi({ url, method: "POST", data: dat });
  return response;
};

const sendOtp = async () => {
  const url = BASE_API + Endpoint.otp_update_bank;
  const response = await CallApi({ url, method: "POST" });
  return response;
};

const AccountRepository = {
  getAccountDetails,
  getBankDetails,
  getLegalDocuments,
  checkPass,
  updateBankDetails,
  updateAccountDetail,
  updatePassword,
  sendOtp
};

export default AccountRepository;
