import React, { useState, useRef } from "react";
import GeneralUtility from "../../../utils/general-utility";

const Stock = ({ stock, changeStock, status }) => {
  const [data, setData] = useState(stock);
  const prevDataRef = useRef(stock);

  const handleInputChange = (e) => {
    setData(GeneralUtility.removeNonNumeric(e.target.value));
  };

  const handleInputKeyUp = (e) => {
    if (e.key === "Enter") {
      const value = e.target.value;
      if (prevDataRef.current !== value) {
        changeStock(value);
      }
    }
  };

  const handleInputFocus = (e) => {
    e.target.select();
  };

  const handleInputBlur = (e) => {
    const value = e.target.value;
    if (prevDataRef.current == value) {
      setData(stock);
    }
    else {
      changeStock(value);
    }
    prevDataRef.current = value;
  };

  return (
    <td className={`font-semibold ${status ? "opacity-50" : ""}`}>
      <input
        type="text"
        name="stock"
        value={data}
        disabled={status}
        onChange={handleInputChange}
        onKeyUp={handleInputKeyUp}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        className={`${status ? "cursor-not-allowed" : "cursor-pointer"} h-11 w-20 block text-sm shadow-sm border border-gray-primary rounded-xl bg-gray-primary`}
      />
    </td>
  );
};

export default Stock;
