import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";

const BASE_API = process.env.REACT_APP_BASE_API;

async function sleep(msec = 1000) {
  return new Promise((resolve) => setTimeout(resolve, msec));
}

const getInsight = async (data) => {
  await sleep();
  const url = BASE_API + Endpoint.insight;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const getRevenue = async (data) => {
  await sleep();
  const url = BASE_API + Endpoint.revenue;
  const response = await CallApi({ url, method: "POST", data });
  return response;
  // var data = {
  //   product_sales: "",
  //   revenue_this_period: "",
  //   revenue_last_period: "",
  // };
  // if (week === 0) {
  //   data = {
  //     product_sales: "2.326",
  //     revenue_this_period: "2.185.177.073",
  //     revenue_last_period: "880.409.780",
  //   };
  // } else {
  //   data = {
  //     product_sales: "543",
  //     revenue_this_period: "880.409.780",
  //     revenue_last_period: "2.185.177.073",
  //   };
  // }
  // return data;
};

// const getRevenueChart = async (week) => {
//   await sleep();
//   var data = [];
//   if (week === 0) {
//     data = [0, 10, 20, 25, 10, 50, 20];
//   } else {
//     data = [20, 10, 10, 18, 10, 22, 10];
//   }
//   const response = {
//     data: data,
//     labels: [
//       "11 Nov",
//       "12 Nov",
//       "13 Nov",
//       "14 Nov",
//       "15 Nov",
//       "16 Nov",
//       "17 Nov",
//     ],
//   };
//   return response;
// };

const getTopSelling = async (data) => {
  await sleep();
  const url = BASE_API + Endpoint.topSelling;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const getLeaderBoard = async (data) => {
  await sleep();
  const url = BASE_API + Endpoint.todayOrder;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const changeStatusApprove = async (data) => {
  const url = BASE_API + Endpoint.changeStatusMerhant;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const getStatusMerchant = async () => {
  const url = BASE_API + Endpoint.statusMerchant;
  const response = await CallApi({ url, method: "POST" });

  // const response = {
  //   error: false,
  //   data: {
  //     data: {
  //       status: true,
  //       open: "08.00",
  //       close: "16.00",
  //       eventStart: "2023-05-04T17:00:00.000Z",
  //       eventEnd: "2023-05-07T17:00:00.000Z",
  //     },
  //   },
  // };
  return response;
};

const openNow = async () => {
  const url = BASE_API + Endpoint.openNow;
  const response = await CallApi({ url, method: "POST" });

  // const response = {
  //   error: false,
  //   data: {
  //     data: {},
  //   },
  // };
  return response;
};

const generateExcel = async (data) => {
  const url = BASE_API + Endpoint.generateExcel;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const DashboardRepository = {
  getInsight,
  getRevenue,
  getTopSelling,
  getLeaderBoard,
  getStatusMerchant,
  openNow,
  changeStatusApprove,
  generateExcel,
};

export default DashboardRepository;
