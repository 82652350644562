import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-multi-lang";
import TableVariant from "./TableVariant";
import VarListModel from "../../model/VarList";
import UploadImage from "../../../../components/UploadImage/UploadImage";
import ToggleActive from "./ToggleActive";

const Variant = ({
  variantOption,
  tabel,
  setTabel,
  type,
  image,
  imageFilename,
  variantSelectedList,
  setCheckVariant
}) => {
  // const DUMMY_IMAGE = process.env.REACT_APP_ASSETS_IMAGE + "dummy-image.jpg";
  const t = useTranslation();
  const [variant, setVariant] = useState([]);
  const [masterVariant, setMasterVariant] = useState([]);
  const [listVariant, setListVariant] = useState([{ label: "", value: "" }]);
  const [selectedValue, setSelectedValue] = useState({ size: [], color: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [active, setActive] = useState(true);

  const getVariant = async () => {
    let arr = [];
    variantOption.forEach((element) => {
      let options = [];
      element.options.forEach((item) => {
        const data = {
          label: item.option,
          value: item.id,
        };
        options.push(data);
      });
      const item = {
        label: element.variant,
        value: element.id,
        option: options,
      };
      arr.push(item);
    });
    setVariant(arr);
    setMasterVariant(arr);

    if (type === "edit") {
      let list = [...variantSelectedList];
      // const filteredData = list.filter((item, index, arr) => {
      //   return arr.findIndex((obj) => obj.label === item.label) === index;
      // });
      setListVariant(list);
    }
  };

  const getListVariant = (sizes, colors) => {
    const array = [];
    // jika panjang size lebih besar
    if (sizes.length > colors.length) {
      sizes.forEach(size => {
        colors.forEach(color => {
          let data = new VarListModel({
            id: "",
            image: color.image ?? image,
            imageFilename: color.filename ?? imageFilename,
            sizeId: size.value,
            size: size.label,
            color: color.label,
            colorId: color.value,
            stock: 10,
            normalPrice: 1000000,
            promotionalPrice: 0,
            weight: 10,
            status: true,
            main: false,
          });
          return array.push(data);
        });
      });
    }
    // jika panjang color lebih besar
    if (colors.length > sizes.length) {
      colors.forEach(color => {
        sizes.forEach(size => {
          let data = new VarListModel({
            id: "",
            image: color.image ?? image,
            imageFilename: color.filename ?? imageFilename,
            sizeId: size.value,
            size: size.label,
            color: color.label,
            colorId: color.value,
            stock: 10,
            normalPrice: 1000000,
            promotionalPrice: 0,
            weight: 10,
            status: true,
            main: false,
          });
          return array.push(data);
        });
      });
    }
    // jika panjang keduanya sama
    if (colors.length === sizes.length) {
      colors.forEach(color => {
        sizes.forEach(size => {
          let data = new VarListModel({
            id: "",
            image: color.image ?? image,
            imageFilename: color.filename ?? imageFilename,
            sizeId: size.value,
            size: size.label,
            color: color.label,
            colorId: color.value,
            stock: 10,
            normalPrice: 1000000,
            promotionalPrice: 0,
            weight: 10,
            status: true,
            main: false,
          });
          return array.push(data);
        });
      });
    }
    // jika sizes-nya kosong
    if (sizes.length === 0) {
      colors.forEach(color => {
        let data = new VarListModel({
          id: "",
          image: color.image ?? image,
          imageFilename: color.filename ?? imageFilename,
          sizeId: "",
          size: "",
          color: color.label,
          colorId: color.value,
          stock: 10,
          normalPrice: 1000000,
          promotionalPrice: 0,
          weight: 10,
          status: true,
          main: false,
        });
        return array.push(data);
      });
    }
    // jika color-nya kosong
    if (colors.length === 0) {
      sizes.forEach(size => {
        let data = new VarListModel({
          id: "",
          image: undefined,
          imageFilename: undefined,
          sizeId: size.value,
          size: size.label,
          color: "",
          colorId: "",
          stock: 10,
          normalPrice: 1000000,
          promotionalPrice: 0,
          weight: 10,
          status: true,
          main: false,
        });
        return array.push(data);
      });
    }

    setTabel(array);
  };

  const getFilterVariant = (list) => {
    let items = [...masterVariant];

    list.forEach((element) => {
      items.splice(
        items.findIndex((item) => item.value === element.value),
        1
      );
    });
    setVariant(items);
  };

  useEffect(() => {
    if (mounted) {
      getVariant();
      setMounted(false);
    }
  }, []);

  useEffect(() => {
    if (listVariant.length === 0) {
      setCheckVariant(false);
    }
  }, [listVariant]);

  useEffect(() => {
    if (mounted === false) {
      const size = selectedValue.size;
      const color = selectedValue.color;
      getListVariant(size, color);
    }
  }, [selectedValue]);

  return (
    <>
      {listVariant.map((item, index) => (
        <React.Fragment key={index}>
          <div className="flex flex-row items-center gap-x-2 pt-4">
            <div className="w-40">
              <Select
                isSearchable={false}
                value={item}
                options={variant}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(item) => {
                  let items = [...listVariant];
                  let selectedItems = items[index];
                  selectedItems.label = item.label;
                  selectedItems.value = item.value;
                  selectedItems.option = item.option;
                  items[index] = selectedItems;
                  setListVariant(items);
                  getFilterVariant(items);
                }}
                isDisabled={type === "edit"}
              />
            </div>
            <div className="flex-auto">
              <Select
                isMulti
                isClearable={false}
                isSearchable={false}
                value={item.selectedList}
                options={item.option}
                isDisabled={type === "edit" ? true : isLoading}
                isLoading={isLoading}
                className="basic-multi-select w-full"
                classNamePrefix="select"
                styles={{
                  menu: (provided, state) => ({
                    ...provided,
                    zIndex: 10,
                  }),
                }}
                onChange={(element) => {
                  if (item.label.toLowerCase() === "size") {
                    setSelectedValue((prev) => ({ ...prev, size: [...element] }));
                  }
                  if (item.label.toLowerCase() === "color") {
                    setSelectedValue((prev) => ({ ...prev, color: [...element] }));
                  }
                }}
              />
            </div>
            {/* {index !== 0 && type === "add" && ( */}
            {type === "add" && (
              <div
                onClick={() => {
                  let items = [...listVariant];
                  items.splice(index, 1);
                  getFilterVariant(items);
                  setListVariant(items);

                  const itemLabel = item.label;
                  if (itemLabel === "Color") {
                    setSelectedValue((prev) => ({
                      ...prev,
                      color: []
                    }));
                  }
                  if (itemLabel === "Size") {
                    setSelectedValue((prev) => ({
                      ...prev,
                      size: []
                    }));
                  }
                }}
              >
                <img
                  src={process.env.REACT_APP_ASSETS_IMAGE + "trash.svg"}
                  className="w-4 h-4 cursor-pointer" />
              </div>
            )}
          </div>
          {item.label === "Color" && selectedValue.color.length > 0 ? (
            <div className="flex flex-row items-center gap-x-2 pt-4">
              <div className="w-40"></div>
              <div className="flex-auto">
                <ToggleActive
                  status={active}
                  desc={t("products.toggle_image_product_desc")}
                  changeStatus={() => {
                    setActive(!active);
                  }} />
                {active ? (
                  <div className="flex flex-row gap-x-2 bg-white rounded-xl p-2">
                    {selectedValue.color.map((item, index) => (
                      <UploadImage
                        key={index}
                        wording={item.label}
                        img={item.image ? item.image : ""}
                        aspectRatio={1}
                        name={item.label}
                        width={40}
                        height={40}
                        position={"col"}
                        onClick={(photo, name) => {
                          setSelectedValue((prev) => ({
                            ...prev,
                            color: prev.color.map((c) => {
                              return c.value === item.value ? { ...c, image: photo, filename: name } : c;
                            })
                          }));
                        }}
                        onDelete={(imageRef) => {
                          imageRef.current.value = null;
                          setSelectedValue((prev) => ({
                            ...prev,
                            color: prev.color.map((c) => {
                              return c.value === item.value ? { ...c, image: undefined, filename: undefined } : c;
                            })
                          }));
                        }}
                      />
                    ))}
                  </div>
                ) : ""}
              </div>
            </div>
          ) : (
            ""
          )}
        </React.Fragment>
      ))}

      {type === "add" && listVariant.length !== variantOption.length ? (
        <div
          className="border-y-2 text-gold-secondary font-semibold cursor-pointer text-sm py-2 my-4"
          onClick={() => {
            let item = [...listVariant];
            if (item.length < variantOption.length) {
              item.push({});
              setListVariant(item);
            }
          }}
        >
          ADD NEW VARIANT
        </div>
      ) : (
        ""
      )}

      {tabel && tabel.length > 0 && (
        <>
          <div className={`text-green-secondary font-semibold p-2 ${type === "edit" ? "opacity-50" : ""}`}>
            Variant Table
          </div>
          <table className="table-auto w-full text-left">
            <thead className={type === "edit" ? "opacity-50" : ""}>
              <tr className="text-green-secondary font-semibold">
                {tabel[0].color ? <th className="p-2 my-2">Picture</th> : ""}
                {tabel[0].size ? <th className="p-2 my-2">Size</th> : ""}
                {tabel[0].color ? <th className="p-2 my-2">Color</th> : ""}
                <th className="p-2 my-2">Stock</th>
                <th className="p-2 my-2">Normal Price</th>
                <th className="p-2 my-2">Promotional Price</th>
                <th className="p-2 my-2">Weight</th>
                <th className="p-2 my-2">Status</th>
                <th className="p-2 my-2" width={100}></th>
              </tr>
            </thead>
            <tbody className="font-medium">
              {tabel.map((item, index) => (
                <TableVariant
                  key={index}
                  result={tabel}
                  item={item}
                  index={index}
                  setTabel={setTabel}
                  image={image}
                  typeAction={type}
                />
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

export default Variant;
