import React, { useState, useRef } from "react";
import GeneralUtility from "../../../utils/general-utility";
import PopupUtility from "../../../utils/popup-utility";

const Price = ({ price, promoPrice, changePrice, status }) => {
  const [data, setData] = useState(price);
  const prevBlurValueRef = useRef(price);

  const handleInputChange = (e) => {
    setData(GeneralUtility.addSeparator(e.target.value));
  };

  const handleInputKeyUp = (e) => {
    if (e.key === "Enter") {
      const floatPrice = parseFloat(e.target.value.split(",").join(""));
      const floatPromoPrice = parseFloat(promoPrice);
      if (floatPrice < 1) {
        setData(price);
        PopupUtility.responseValidate("Validation", "Price cannot be 0");
      } else if (floatPrice <= floatPromoPrice) {
        setData(price);
        PopupUtility.responseValidate(
          "Validation",
          "Price cannot be less than or equal to Promotional Price"
        );
      } else {
        changePrice(e.target.value);
      }
    }
  };

  const handleInputFocus = (e) => {
    e.target.select();
  };

  const handleInputBlur = (e) => {
    const value = e.target.value;
    if (value === GeneralUtility.addCommas(prevBlurValueRef.current)) {
      setData(price);
    }
    else {
      const floatPrice = parseFloat(value.split(",").join(""));
      const floatPromoPrice = parseFloat(promoPrice);
      if (floatPrice < 1) {
        setData(price);
        PopupUtility.responseValidate("Validation", "Price cannot be 0");
      } else if (floatPrice <= floatPromoPrice) {
        setData(price);
        PopupUtility.responseValidate(
          "Validation",
          "Price cannot be less than or equal to Promotional Price"
        );
      } else {
        changePrice(value);
      }
    }
    prevBlurValueRef.current = value;
  };

  return (
    <td className={`font-semibold ${status ? "opacity-50" : ""}`}>
      <label className="flex-1 min-w-fit relative block">
        <span className="absolute inset-y-0 left-1.5 flex items-center pl-2">
          <p>Rp</p>
        </span>
        <input
          type="text"
          name="price"
          value={GeneralUtility.addCommas(data)}
          onChange={handleInputChange}
          onKeyUp={handleInputKeyUp}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          className={`${status ? "cursor-not-allowed" : "cursor-pointer"} pl-10 pr-3 text-right w-32 block text-sm shadow-sm border border-gray-primary rounded-xl bg-gray-primary`}
          disabled={status}
        />
      </label>
    </td>
  );
};

export default Price;
