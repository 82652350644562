import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "products",
  initialState: {
    page: "all",
    id: "",
    typeForm: "",
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    setTypeForm: (state, action) => {
      state.typeForm = action.payload;
    },
  },
});

export const { setPage, setId, setTypeForm } = reducerSlice.actions;
export default reducerSlice;
