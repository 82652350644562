import BalanceModel from "../pages/balance/models/BalanceModel";
import TicketModel from "../pages/balance/models/TicketModel";
import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";
import DateUtility from "../utils/date-utility";
import GeneralUtility from "../utils/general-utility";

const BASE_API = process.env.REACT_APP_BASE_API;

const getBalance = async () => {
  const url = BASE_API + Endpoint.balance_data;
  const response = await CallApi({ url, method: "POST" });
  const res1 = typeof response !== "undefined" ? response.data : null;
  const res2 = typeof res1 !== "undefined" && res1 != null ? res1.data : null;

  let data = new BalanceModel({
    totalPrice: getBalanceTotalPrice(res2),
    price: res2.price,
    shippingPrice: res2.shippingPrice ?? 0,
    shippingInsurance: res2.shippingInsurance ?? 0,
    serviceFee: res2.serviceFee ?? 0,
    dendaCancel: res2.dendaCancel ?? 0,
    biayaAdminWd: res2.biayaAdminWd ?? 0,
    bankDetail: "",
    name: "",
    commisionFee: res2.commission ?? 0,
    dendaServiceFeeCancel: res2.dendaServiceFeeCancel ?? 0,
    priceTotal: res2.priceTotal,
    transServiceFee: res2.transServiceFee,
    heldBalance: res2.priceHold
  });

  let balance = {};
  balance.error = response.error;
  balance.message = response.message;
  balance.data = data;
  return balance;
};

const getBalanceTotalPrice = (item) => {
  return parseFloat(item.price + item.biayaAdminWd);
};

const postWd = async (data) => {
  const url = BASE_API + Endpoint.balance_wd;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const getBalanceHistory = async (data) => {
  const url = BASE_API + Endpoint.balance_history;
  const response = await CallApi({ url, method: "POST", data });
  const res1 = typeof response !== "undefined" ? response.data : null;
  const balanceItems = typeof res1 !== "undefined" && res1 != null ? res1.data : null;

  const result = [];
  for (let item of balanceItems) {
    const ticket = TicketModel({
      titleTicket: getTitleTicket(item),
      date: DateUtility.formatDate(item.createdAt, "full"),
      productSalesRevenue: getProductSalesRevenue(item),
      balanceReceived: getBalanceReceived(item),
      withdrawalFee: getWithdrawalFee(item),
      balance: getBalanceItem(item),
      totalAmountWithdraw: getTotalAmountWd(item),
      productCommission: getProductCommission(item),
      shipping: getShipping(item),
      insurance: getInsurance(item),
      adminApplicationFee: getAdminApplicationFee(item),
      transactionServiceFee: getTransactionServiceFee(item),
      cancellationFee: getCancellationFee(item),
      cancellationFeePerItem: getCancellationFeePerItem(item),
      thirdPartyFee: getThirdPartyFee(item),
      type: item.type,
      status: item.status,
      customerName: getCustomerName(item),
      totalPricePromo: GeneralUtility.addCommas(item.totalPricePromo || 0),
      totalShippingPromo: GeneralUtility.addCommas(item.totalShippingPromo || 0),
      totalAdminPromo: GeneralUtility.addCommas(item.totalAdminPromo || 0)
    });
    result.push(ticket);
  }

  let balance = {};
  balance.error = response.error;
  balance.message = response.message;
  balance.data = result;
  return balance;
};

const getCustomerName = (item) => {
  if (item.type !== 0) {
    return item.customerName;
  }
  return "";
};

const getTransactionServiceFee = (item) => {
  if (item.type === 1) {
    return GeneralUtility.addCommas(item.transServiceFee);
  }
  return 0;
};


const getThirdPartyFee = (item) => {
  if (item.type === 2) {
    return GeneralUtility.addCommas(item.dendaServiceFeeCancel);
  }
  return 0;
};

const getCancellationFeePerItem = (item) => {
  // hanya muncul di cancellation history
  if (item.type === 2) {
    return GeneralUtility.addCommas(item.dendaCancel);
  }
  return 0;
};

const getCancellationFee = (item) => {
  // hanya muncul di cancellation history
  if (item.type === 2) {
    return GeneralUtility.addCommas(item.dendaCancel + item.dendaServiceFeeCancel);
  }
  return 0;
};

const getAdminApplicationFee = (item) => {
  if (item.type === 1) {
    return GeneralUtility.addCommas(item.serviceFee);
  }
  return 0;
};

const getShipping = (item) => {
  // hanya muncul di sales history
  if (item.type === 1) {
    return GeneralUtility.addCommas(item.shippingPrice);
  }
  return 0;
};

const getInsurance = (item) => {
  // hanya muncul di sales history
  if (item.type === 1) {
    return GeneralUtility.addCommas(item.shippingInsurance);
  }
  return 0;
};

const getProductCommission = (item) => {
  // hanya muncul di sales history
  if (item.type === 1) {
    return GeneralUtility.addCommas(item.commission);
  }
  return 0;
};

const getTotalAmountWd = (item) => {
  // hanya muncul di wd history
  if (item.type === 0) {
    return GeneralUtility.addCommas(item.price);
  }
  return 0;
};

const getBalanceItem = (item) => {
  // hanya muncul di wd history
  if (item.type === 0) {
    return GeneralUtility.addCommas(item.price + item.biayaAdminWd);
  }
  return 0;
};

const getTitleTicket = (item) => {
  const { noInvoice, merchants, type, createdAt } = item;
  // hanya muncul di wd history
  if (type === 0) {
    return DateUtility.formatDate(createdAt, "full");
  }
  return `${noInvoice}/${merchants.name.toUpperCase()}`;
};

const getProductSalesRevenue = (item) => {
  // hanya muncul di sales history
  if (item.type === 1) {
    return GeneralUtility.addCommas(item.priceTotal);
  }
  return 0;
};

const getBalanceReceived = (item) => {
  // hanya muncul di sales history
  if (item.type === 1) {
    return GeneralUtility.addCommas(item.price);
  }
  return 0;
};

const getWithdrawalFee = (item) => {
  // hanya muncul di wd history
  if (item.type === 0) {
    return GeneralUtility.addCommas(item.biayaAdminWd);
  }
  return 0;
};

const BalanceRepository = {
  getBalance,
  getBalanceHistory,
  postWd
};

export default BalanceRepository;
