import AboutUsModel from "../pages/helpcenter/component/AboutUs/model/AboutUsModel";
import FaqQuestionsModel from "../pages/helpcenter/component/FAQ/model/FaqQuestionsModel";
import FaqSectionModel from "../pages/helpcenter/component/FAQ/model/FaqSectionModel";
import CategoryModel from "../pages/helpcenter/component/Feedback/model/CategoryModel";
import TopicModel from "../pages/helpcenter/component/Feedback/model/TopicModel";
import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";

const BASE_API = process.env.REACT_APP_BASE_API;

const getAboutUs = async () => {
  const url = BASE_API + Endpoint.aboutUs;
  const response = await CallApi({ url, method: "POST" });
  const res1 = typeof response != "undefined" ? response.data : null;
  const res2 = typeof res1 != "undefined" || res1 != null ? res1.data : null;
  let data = AboutUsModel({
    id: res2.id,
    address: res2.address,
    phone: res2.phoneNumber,
    email: res2.email,
  });

  return data;
};

const getFaq = async () => {
  const url = BASE_API + Endpoint.faq;
  const response = await CallApi({ url, method: "POST" });
  const res1 = typeof response != "undefined" ? response.data : null;
  const res2 = typeof res1 != "undefined" || res1 != null ? res1.data : null;
  let data = [];
  for (let i = 0; i < res2.length; i++) {
    const section = res2[i];
    const sectionQuestions = section.questions;
    let questions = [];
    for (let j = 0; j < sectionQuestions.length; j++) {
      const question = sectionQuestions[j];
      questions.push(
        FaqQuestionsModel({
          id: j,
          title: question.title,
          answer: question.answer,
        })
      );
    }
    data.push(
      FaqSectionModel({
        id: section.id,
        sectionTitle: section.name,
        questions: questions,
      })
    );
  }
  return data;
};

const getCategories = async () => {
  const url = BASE_API + Endpoint.category;
  const response = await CallApi({ url, method: "POST" });
  const responseData = response.data.data;
  let data = [];
  for (let i = 0; i < responseData.length; i++) {
    const element = responseData[i];
    data.push(
      CategoryModel({
        id: element.id,
        title: element.categoryName,
      })
    );
  }
  return data;
};
const getTopics = async () => {
  const url = BASE_API + Endpoint.topic;
  const response = await CallApi({ url, method: "POST" });
  const responseData = response.data.data;
  let data = [];
  for (let i = 0; i < responseData.length; i++) {
    const element = responseData[i];
    data.push(
      TopicModel({
        id: element.id,
        title: element.topicName,
      })
    );
  }
  return data;
};

const postFeedback = async (data) => {
  const url = BASE_API + Endpoint.create_feedback;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const HelpCenterRepository = {
  getAboutUs,
  getFaq,
  getCategories,
  getTopics,
  postFeedback,
};

export default HelpCenterRepository;
