import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "operationalMain",
  initialState: {
    loadingData: false,
    dataMonday: {
      isOpen: false,
      openTime: "1945-07-17T00:00:00.000Z",
      closeTime: "1945-07-17T12:00:00.000Z",
      type: "manual",
    },
    dataTuesDay: {
      isOpen: false,
      openTime: "1945-07-17T00:00:00.000Z",
      closeTime: "1945-07-17T12:00:00.000Z",
      type: "manual",
    },
    dataWednesday: {
      isOpen: false,
      openTime: "1945-07-17T00:00:00.000Z",
      closeTime: "1945-07-17T12:00:00.000Z",
      type: "manual",
    },
    dataThursday: {
      isOpen: false,
      openTime: "1945-07-17T00:00:00.000Z",
      closeTime: "1945-07-17T12:00:00.000Z",
      type: "manual",
    },
    dataFriday: {
      isOpen: false,
      openTime: "1945-07-17T00:00:00.000Z",
      closeTime: "1945-07-17T12:00:00.000Z",
      type: "manual",
    },
    dataSaturday: {
      isOpen: false,
      openTime: "1945-07-17T00:00:00.000Z",
      closeTime: "1945-07-17T12:00:00.000Z",
      type: "manual",
    },
    dataSunday: {
      isOpen: false,
      openTime: "1945-07-17T00:00:00.000Z",
      closeTime: "1945-07-17T12:00:00.000Z",
      type: "manual",
    },
    initialData: [
      {
        isOpen: false,
        openTime: "1945-07-17T00:00:00.000Z",
        closeTime: "1945-07-17T12:00:00.000Z",
        type: "manual",
      },
      {
        isOpen: false,
        openTime: "1945-07-17T00:00:00.000Z",
        closeTime: "1945-07-17T12:00:00.000Z",
        type: "manual",
      },
      {
        isOpen: false,
        openTime: "1945-07-17T00:00:00.000Z",
        closeTime: "1945-07-17T12:00:00.000Z",
        type: "manual",
      },
      {
        isOpen: false,
        openTime: "1945-07-17T00:00:00.000Z",
        closeTime: "1945-07-17T12:00:00.000Z",
        type: "manual",
      },
      {
        isOpen: false,
        openTime: "1945-07-17T00:00:00.000Z",
        closeTime: "1945-07-17T12:00:00.000Z",
        type: "manual",
      },
      {
        isOpen: false,
        openTime: "1945-07-17T00:00:00.000Z",
        closeTime: "1945-07-17T12:00:00.000Z",
        type: "manual",
      },
      {
        isOpen: false,
        openTime: "1945-07-17T00:00:00.000Z",
        closeTime: "1945-07-17T12:00:00.000Z",
        type: "manual",
      },
    ],
    edit: false,
    show: false,
  },
  reducers: {
    setLoadingData: (state, action) => {
      state.loadingData = action.payload;
    },
    setDataMonday: (state, action) => {
      state.dataMonday = action.payload;
    },
    setDataTuesday: (state, action) => {
      state.dataTuesDay = action.payload;
    },
    setDataWednesday: (state, action) => {
      state.dataWednesday = action.payload;
    },
    setDataThursday: (state, action) => {
      state.dataThursday = action.payload;
    },
    setDataFriday: (state, action) => {
      state.dataFriday = action.payload;
    },
    setDataSaturday: (state, action) => {
      state.dataSaturday = action.payload;
    },
    setDataSunday: (state, action) => {
      state.dataSunday = action.payload;
    },
    setInitialData: (state, action) => {
      state.initialData = action.payload;
    },
    setEdit: (state, action) => {
      state.edit = action.payload;
    },
    setShow: (state, action) => {
      state.show = action.payload;
    },
  },
});

export const {
  setLoadingData,
  setDataMonday,
  setDataTuesday,
  setDataWednesday,
  setDataThursday,
  setDataFriday,
  setDataSaturday,
  setDataSunday,
  setInitialData,
  setEdit,
  setShow,
} = reducerSlice.actions;
export default reducerSlice;
