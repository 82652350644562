import React from "react";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import Action from "../../redux/Action";
import ButtonGreen from "../../../../components/ButtonGreen/ButtonGreen";

const BuybackDashboard = ({ statusMerchant }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.isLoadingBuyback);
  // const data = useSelector((state) => state.reducer.buybackData);

  return (
    <div className="section-grey px-4 py-3 h-[49vh] overflow-y-auto">
      <h2 className="text-sm p-2 mb-2 font-semibold tracking-widest text-[#A7ADA9]">
        {t("dashboard.insight").toUpperCase()}
      </h2>

      {isLoading ? (
        <div className="w-full p-3 mb-2">
          <Skeleton height="220px" />
        </div>
      ) : (
        <div className="p-2">
          <div className="w-full text-center">
            <img
              className="w-full max-h-[8rem] mb-1"
              src={process.env.REACT_APP_ASSETS_IMAGE + "close-merchant.svg"}
              alt="close-merchant"
            />
            <div className="rounded-xl bg-green-custom p-3 border-2 border-green-secondary text-left">
              <div className="font-bold">{t("dashboard.close")}</div>
              <div className="font-bold">
                {!statusMerchant.statusMerchantHoliday
                  ? statusMerchant.holidayStartMerchant +
                  " - " +
                  statusMerchant.holidayEndMerchant
                  : ""}
              </div>
              <div className="font-medium pt-2">
                {!statusMerchant.statusMerchantHoliday
                  ? t("dashboard.close_holiday")
                  : t("dashboard.close_merchant")}
              </div>
            </div>
            {statusMerchant.statusTimeOutsideRange ? (
              <div className="items-center gap-2 mt-4 flex justify-end">
                <ButtonGreen
                  title={t("general.open_now").toUpperCase()}
                  onClick={() => {
                    Action.openNow(dispatch, statusMerchant);
                  }}
                />
              </div>
            ) : ""}
          </div>
        </div>
      )}
    </div>
  );
};

export default BuybackDashboard;
