import React from "react";

const ButtonWhite = ({ title, onClick, disabled }) => {
  return (
    <>
      <button
        type="submit"
        className={`button md:w-36 ${disabled && disabled === true ? "opacity-50" : 'hover:bg-green-secondary hover:text-gray-primary'} text-xs tracking-widest font-bold text-green-secondary border-2 border-green-secondary border-opacity-40 rounded-xl py-2`}
        onClick={(e) => {
          onClick(e);
        }}
        disabled={disabled ?? false}
      >
        {title.toUpperCase()}
      </button>
    </>
  );
};

export default ButtonWhite;
