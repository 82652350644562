import React, { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { Provider, useDispatch, useSelector } from "react-redux";
import Action from "./redux/Action";
import StoreHelper from "../../../../services/store-helper";
import Select from "react-select";
import reducerSlice, { setEdit, setErrorOtp, setInfoPopup, setOtp, setOtpPopup, setSuspendPopup } from "./redux/Reducer";
import PopupConfirmation from "../../../../components/PopupConfirmation/PopupConfirmation";
import ButtonWhite from "../../../../components/ButtonWhite/ButtonWhite";
import ButtonGreen from "../../../../components/ButtonGreen/ButtonGreen";
import SuspendInformation from "../../../../components/SuspendInformation/SuspendInformation";
import PopupOtp from "./components/PopupOtp";

const BankDetails = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  store.getState();

  const MainComponent = () => {
    const t = useTranslation();
    const dispatch = useDispatch();

    const show = useSelector((state) => state.reducer.show);
    const pass = useSelector((state) => state.reducer.password);
    const validatePass = useSelector((state) => state.reducer.validatePass);

    const bank = useSelector((state) => state.reducer.bank);
    const data = useSelector((state) => state.reducer.data);
    const edit = useSelector((state) => state.reducer.edit);
    const otpCode = useSelector((state) => state.reducer.otp);
    const otpUpdateBankDetailMerchantId = useSelector((state) => state.reducer.otpUpdateBankDetailMerchantId);
    const otpPopup = useSelector((state) => state.reducer.otpPopup);
    const infoPopup = useSelector((state) => state.reducer.infoPopup);
    const canSave = useSelector((state) => state.reducer.canSave);
    const errorBankDetails = useSelector((state) => state.reducer.errorBankDetails);
    const suspendPopup = useSelector((state) => state.reducer.suspendPopup);

    useEffect(() => {
      Action.getBank(dispatch);
      Action.getData(dispatch);
    }, []);

    useEffect(() => {
      Action.validate(dispatch, data);
    }, [data]);

    return (
      <>
        <form>
          <div
            className={`flex flex-col gap-y-4  overflow-y-auto px-3 md:h-[${edit === false ? "20em" : "30em"
              }em]`}
          >
            {!edit ? (
              <div className="text-md text-right font-bold">
                <span
                  role="button"
                  className="cursor-pointer underline underline-offset-2 hover:text-black"
                  onClick={() => {
                    // dispatch(setValidatePass(true));
                    dispatch(setEdit(true));
                  }}>
                  Edit
                </span>
              </div>
            ) : null}

            {edit === false ? (
              <>
                {/* bank account name */}
                <div className="flex flex-row items-center gap-x-3 text-md pt-2">
                  <div className="w-44">
                    <div className="font-bold">
                      {t("register.account_name")}
                      <span className="text-gold-secondary">*</span>
                    </div>
                  </div>
                  <div className="flex-1">{data.name}</div>
                </div>
                <hr />

                {/* bank name */}
                <div className="flex flex-row items-center text-md gap-x-3">
                  <div className="w-44">
                    <div className="font-bold">
                      {t("register.bank_name")}
                      <span className="text-gold-secondary">*</span>
                    </div>
                  </div>
                  <div className="flex-1">{data.bankName}</div>
                </div>
                <hr />

                {/* Bank Number */}
                <div className="flex flex-row items-center gap-x-3 text-md pb-2">
                  <div className="w-44">
                    <div className="font-bold">
                      {t("register.account_number")}
                      <span className="text-gold-secondary">*</span>
                    </div>
                  </div>
                  <div className="flex-1">{data.bankNumber}</div>
                </div>
                <hr />
              </>
            ) : (
              <>
                {/* bank account name */}
                <div className="flex flex-row items-center gap-x-3 pt-2">
                  <div className="w-44">
                    <div
                      className={`text-md font-bold ${errorBankDetails.bankName ? "pb-4" : " "
                        }`}
                    >
                      {t("register.account_name")}
                      <span className="text-gold-secondary">*</span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <input
                      type="text"
                      name="bankName"
                      onChange={(e) => {
                        Action.handleChange(dispatch, data, e);
                      }}
                      placeholder={t("register.account_name")}
                      className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${errorBankDetails.bankName
                        ? "border-red-500 border-1"
                        : "border-0"
                        }`}
                      defaultValue={data.name}
                    />
                    <p className="text-red-500">{errorBankDetails.bankName}</p>
                  </div>
                </div>
                <hr />

                {/* bank name */}
                <div className="flex flex-row items-center gap-x-3">
                  <div className="w-44">
                    <div
                      className={`text-md font-bold ${errorBankDetails.bankId ? "pb-4" : " "
                        }`}
                    >
                      {t("register.bank_name")}
                      <span className="text-gold-secondary">*</span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errorBankDetails.bankId ? "red" : "white",
                        }),
                      }}
                      className="text-sm border w-full bg-white font-medium"
                      options={bank}
                      name="bankId"
                      onChange={(item) => {
                        Action.handleChange(dispatch, data, {
                          target: { name: "bankId", value: item },
                        });
                      }}
                      value={data.bankId}
                    />
                    {/* <select
                    className={`text-sm border rounded-xl w-full bg-white font-medium ${
                      errorBankDetails.bankId
                        ? "border-red-500"
                        : "border-gray-primary"
                    }`}
                    name="bankId"
                    defaultValue={data.bankId}
                    onChange={(e) => {
                      Action.handleChange(dispatch, e, bankDetails);
                    }}
                  >
                    <option value="default" disabled>
                      {t("register.select_bank_name")}
                    </option>
                    {bank.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </select> */}
                    <p className="text-red-500">{errorBankDetails.bankId}</p>
                  </div>
                </div>
                <hr />

                {/* Bank Number */}
                <div className="flex flex-row items-center gap-x-3 pb-2">
                  <div className="w-44">
                    <div
                      className={`text-md font-bold ${errorBankDetails.bankNumber ? "pb-4" : " "
                        }`}
                    >
                      {t("register.account_number")}
                      <span className="text-gold-secondary">*</span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <input
                      type="text"
                      name="bankNumber"
                      defaultValue={data.bankNumber}
                      onChange={(e) => {
                        Action.handleChange(dispatch, data, e);
                      }}
                      placeholder={t("register.input_account_number")}
                      className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${errorBankDetails.bankNumber
                        ? "border-red-500 border-1"
                        : "border-0"
                        }`}
                    />
                    <p className="text-red-500">{errorBankDetails.bankNumber}</p>
                  </div>
                </div>
                <hr />

                <div className="flex flex-row-reverse gap-x-2 pt-5 pb-3">
                  <ButtonGreen
                    title={t("general.save").toUpperCase()}
                    onClick={(e) => {
                      e.preventDefault();
                      if (canSave) {
                        dispatch(setInfoPopup(true));
                      }
                    }}
                  />
                  <ButtonWhite
                    title={t("general.cancel").toUpperCase()}
                    onClick={(e) => {
                      e.preventDefault();
                      Action.getData(dispatch);
                      dispatch(setEdit(false));
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </form>

        {infoPopup ? (
          <PopupConfirmation
            title={t("accountsettings.bank_details")}
            subtitle={t("accountsettings.title_bank_details")}
            onDelete={() => {
              Action.sendOtp(dispatch);
              dispatch(setOtpPopup(true));
            }}
            onCancel={() => {
              dispatch(setInfoPopup(false));
            }}
          />
        ) : ""}

        {otpPopup ? (
          <PopupOtp
            onCancel={() => {
              dispatch(setErrorOtp(""));
              dispatch(setOtp(['', '', '', '', '', '']));
              dispatch(setOtpPopup(false));
            }}
            onSubmit={() => {
              const bankDetails = { ...data, otpUpdateBankDetailMerchantId, otpCode };
              Action.handleSubmit(dispatch, bankDetails, t);
            }}
          />
        ) : ""}

        {suspendPopup ? (
          <SuspendInformation
            onClick={() => {
              // set cookies supaya pilih tab feeback
              Action.redirectToFeedbacks();
            }}
            onCancel={() => {
              dispatch(setSuspendPopup(false));
            }} />
        ) : ""}
      </>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default BankDetails;
