import { Provider, useDispatch, useSelector } from 'react-redux';
import StoreHelper from '../../services/store-helper';
import reducerSlice, { setEndDateFilter, setStartDateFilter } from './redux/Reducer';
import { useTranslation } from 'react-multi-lang';
import React, { useEffect } from 'react';
import HeaderTabel from './components/HeaderTable';
import Tabel from './components/Tabel';
import Action from './redux/Action';
import Total from './components/Total';
import Detail from './components/Detail';
import Pagination from "../../components/Pagination/Pagination";

const ComplaintInfo = ({ t }) => {
    return (
        <div className="border-2 border-gold-secondary bg-[#f5f0e1] px-4 py-3 rounded-2xl">
            <p className='text-green-primary font-black mb-2'>
                {t("complaints.complaint_info")}
            </p>
            <ul className="list-disc pl-4 font-semibold">
                <li className='text-green-primary'>{t("complaints.info_1")}</li>
                <li className='text-green-primary'>{t("complaints.info_2")}</li>
            </ul>
        </div>
    );
};

const Complaint = ({ getBreadcrums }) => {
    const store = StoreHelper.generateStoreState(reducerSlice);

    const MainComponent = () => {
        store.getState();

        const t = useTranslation();
        const dispatch = useDispatch();

        const currentPage = useSelector(state => state.reducer.currentPage);
        const postPerPage = useSelector(state => state.reducer.postPerPage);
        const search = useSelector(state => state.reducer.search);
        const statusFilter = useSelector(state => state.reducer.statusFilter);
        const fieldName = useSelector(state => state.reducer.fieldName);
        const orderBy = useSelector(state => state.reducer.orderBy);
        const showDetail = useSelector(state => state.reducer.showDetail);
        const totalData = useSelector(state => state.reducer.totalData);
        const totalPage = useSelector(state => state.reducer.totalPage);
        const loading = useSelector(state => state.reducer.loading);
        const startDateFilter = useSelector(state => state.reducer.startDateFilter);
        const endDateFilter = useSelector(state => state.reducer.endDateFilter);

        useEffect(() => {
            const title = `${t("complaints.title")} > ${t("complaints.list")}`;
            getBreadcrums(title);
        }, [getBreadcrums]);

        useEffect(() => {
            Action.getList(dispatch, {
                currentPage,
                postPerPage,
                search,
                statusFilter,
                fieldName,
                orderBy,
                startDateFilter,
                endDateFilter
            });
        }, []);

        const ShowDetail = () => {
            if (showDetail) {
                return <Detail />;
            } else {
                return <Total totalData={totalData} loading={loading} />;
            }
        };

        return (
            <>
                <div className="flex flex-row items-center px-2 gap-x-2">
                    <i className="mt-1.5">
                        <img
                            className="h-full w-10"
                            src={process.env.REACT_APP_ASSETS_IMAGE + "back-icon.svg"}
                            alt="back-icon"
                        />
                    </i>
                    <h2 className="text-green-primary font-bold">
                        {t("complaints.title")}
                    </h2>
                </div>

                <div className='pt-6'>
                    <div className='flex gap-x-3'>
                        {/* PANEL KIRI */}
                        <div className="basis-8/12">
                            <ComplaintInfo t={t} />

                            <div className='rounded-2xl p-5 mt-6 bg-gray-primary'>
                                <div className='flex flex-row items-center gap-x-5'>

                                    <HeaderTabel
                                        datePickerOnOpen={() => {
                                            dispatch(setStartDateFilter(""));
                                            dispatch(setEndDateFilter(""));
                                        }}
                                        datePickerOnChange={(dateObject) => {
                                            let d0 = new Date(dateObject[0]);
                                            if (dateObject.length > 1) {
                                                let d1 = new Date(dateObject[1]);
                                                dispatch(setStartDateFilter(d0.toISOString()));
                                                dispatch(setEndDateFilter(d1.toISOString()));
                                            } else {
                                                dispatch(setStartDateFilter(d0.toISOString()));
                                                dispatch(setEndDateFilter(""));
                                            }
                                        }}
                                        datePickerOnClose={() => {
                                            Action.getList(dispatch, {
                                                startDateFilter,
                                                endDateFilter,
                                                currentPage: 0,
                                                postPerPage,
                                                search,
                                                statusFilter,
                                                fieldName,
                                                orderBy
                                            });
                                        }} />
                                </div>
                                <div className='my-2'>
                                    <Tabel />
                                </div>
                                <div>
                                    <Pagination
                                        totalPage={totalPage}
                                        postsPerPage={postPerPage}
                                        totalData={totalData}
                                        currentPage={currentPage}
                                        onClick={(page) => {
                                            Action.getList(dispatch, {
                                                currentPage: page,
                                                postPerPage,
                                                search,
                                                statusFilter,
                                                fieldName,
                                                orderBy,
                                                startDateFilter,
                                                endDateFilter
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* PANEL KANAN */}
                        <div className="basis-4/12">
                            <div className='p-5 bg-gray-primary rounded-2xl h-auto'>
                                <ShowDetail />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <Provider store={store}>
            <MainComponent />
        </Provider>
    );
};

export default Complaint;