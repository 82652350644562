import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "faq",
  initialState: {
    loading: true,
    data: {},
    showSectionId: -1,
    showQuestionId: -1,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setShowSectionId: (state, action) => {
      state.showSectionId = action.payload;
    },
    setShowQuestionId: (state, action) => {
      state.showQuestionId = action.payload;
    },
  },
});

export const {
  setLoading,
  setData,
  setShowSectionId,
  setShowQuestionId,
} = reducerSlice.actions;

export default reducerSlice;
