import React from "react";
import { useTranslation } from "react-multi-lang";
import ButtonGreen from "../../../../../components/ButtonGreen/ButtonGreen";
import ButtonWhite from "../../../../../components/ButtonWhite/ButtonWhite";
import { Provider, useDispatch, useSelector } from "react-redux";
import StoreHelper from "../../../../../services/store-helper";
import reducerSlice, {
  setDataFriday,
  setDataMonday,
  setDataSaturday,
  setDataSunday,
  setDataThursday,
  setDataTuesday,
  setDataWednesday,
  setEdit,
  setShow,
} from "./redux/Reducer";
import OperationalItem from "./components/OperationalItem";
import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
import Action from "./redux/Action";
import DateUtility from "../../../../../utils/date-utility";
import PopupConfirmation from "../../../../../components/PopupConfirmation/PopupConfirmation";

const OperationalMain = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);

  const MainComponent = () => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const loadingData = useSelector((state) => state.reducer.loadingData);
    const dataMonday = useSelector((state) => state.reducer.dataMonday);
    const dataTuesDay = useSelector((state) => state.reducer.dataTuesDay);
    const dataWednesday = useSelector((state) => state.reducer.dataWednesday);
    const dataThursday = useSelector((state) => state.reducer.dataThursday);
    const dataFriday = useSelector((state) => state.reducer.dataFriday);
    const dataSaturday = useSelector((state) => state.reducer.dataSaturday);
    const dataSunday = useSelector((state) => state.reducer.dataSunday);
    const initialData = useSelector((state) => state.reducer.initialData);
    const show = useSelector((state) => state.reducer.show);
    const edit = useSelector((state) => state.reducer.edit);
    store.getState();

    const ophour = [
      {
        day: t("operationalhour.monday"),
        open: dataMonday.openTime,
        close: dataMonday.closeTime,
        isOpen: dataMonday.isOpen,
        type: dataMonday.type,
      },
      {
        day: t("operationalhour.tuesday"),
        open: dataTuesDay.openTime,
        close: dataTuesDay.closeTime,
        isOpen: dataTuesDay.isOpen,
        type: dataTuesDay.type,
      },
      {
        day: t("operationalhour.wednesday"),
        open: dataWednesday.openTime,
        close: dataWednesday.closeTime,
        isOpen: dataWednesday.isOpen,
        type: dataWednesday.type,
      },
      {
        day: t("operationalhour.thursday"),
        open: dataThursday.openTime,
        close: dataThursday.closeTime,
        isOpen: dataThursday.isOpen,
        type: dataThursday.type,
      },
      {
        day: t("operationalhour.friday"),
        open: dataFriday.openTime,
        close: dataFriday.closeTime,
        isOpen: dataFriday.isOpen,
        type: dataFriday.type,
      },
      {
        day: t("operationalhour.saturday"),
        open: dataSaturday.openTime,
        close: dataSaturday.closeTime,
        isOpen: dataSaturday.isOpen,
        type: dataSaturday.type,
      },
      {
        day: t("operationalhour.sunday"),
        open: dataSunday.openTime,
        close: dataSunday.closeTime,
        isOpen: dataSunday.isOpen,
        type: dataSunday.type,
      },
    ];

    useEffect(() => {
      Action.getOperationalHours(dispatch);
    }, []);

    return (
      <div className="px-2 pb-6">
        <div className="flex flex-row">
          <div className="w-60 mr-12">
            <p className="text-md font-semibold">
              {t("operationalhour.subtitle")}
            </p>
            <p className="pt-3 text-green-tertiary">
              {t("operationalhour.description")}
            </p>
          </div>
          {edit === false ? (
            <div className="flex-1 flex flex-row">
              <div className="pl-10 font-semibold">
                <table>
                  <tbody>
                    {ophour.map((items, index) => (
                      <tr key={index}>
                        <td>{items.day}</td>
                        <td className="px-2">:</td>
                        <td>
                          {items.isOpen === true
                            ? items.type === "24hour"
                              ? t("general.open24hour")
                              : DateUtility.timeDate(items.open, false) +
                                " - " +
                                DateUtility.timeDate(items.close, true)
                            : "Day Off"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex-1 text-md text-right font-bold">
                <div
                  className="cursor-pointer underline underline-offset-2 hover:text-black"
                  onClick={() => {
                    dispatch(setEdit(!edit));
                  }}
                >
                  Edit
                </div>
              </div>
            </div>
          ) : (
            <>
              {loadingData ? (
                <div className="flex-1">
                  <Skeleton count={8} height="40px" />
                </div>
              ) : (
                <div className="flex-1">
                  <table className="w-full">
                    {/* HEADER */}
                    <thead>
                      <tr>
                        <th>{t("operationalhour.day")}</th>
                        <th>{t("operationalhour.operational_hours")}</th>
                        <th>{t("operationalhour.opening_hours")}</th>
                        <th>{t("operationalhour.closing_hours")}</th>
                      </tr>
                    </thead>
                    {/* LIST OF DAYS */}
                    <tbody>
                      <OperationalItem
                        day={t("operationalhour.monday")}
                        item={dataMonday}
                        setData={setDataMonday}
                      />
                      <OperationalItem
                        day={t("operationalhour.tuesday")}
                        item={dataTuesDay}
                        setData={setDataTuesday}
                      />
                      <OperationalItem
                        day={t("operationalhour.wednesday")}
                        item={dataWednesday}
                        setData={setDataWednesday}
                      />
                      <OperationalItem
                        day={t("operationalhour.thursday")}
                        item={dataThursday}
                        setData={setDataThursday}
                      />
                      <OperationalItem
                        day={t("operationalhour.friday")}
                        item={dataFriday}
                        setData={setDataFriday}
                      />
                      <OperationalItem
                        day={t("operationalhour.saturday")}
                        item={dataSaturday}
                        setData={setDataSaturday}
                      />
                      <OperationalItem
                        day={t("operationalhour.sunday")}
                        item={dataSunday}
                        setData={setDataSunday}
                      />
                    </tbody>
                  </table>

                  <div className="pt-6 flex justify-end gap-x-6 pr-6">
                    <ButtonWhite
                      title={t("general.cancel")}
                      onClick={() => {
                        dispatch(setDataMonday(initialData[0]));
                        dispatch(setDataTuesday(initialData[1]));
                        dispatch(setDataWednesday(initialData[2]));
                        dispatch(setDataThursday(initialData[3]));
                        dispatch(setDataFriday(initialData[4]));
                        dispatch(setDataSaturday(initialData[5]));
                        dispatch(setDataSunday(initialData[6]));
                        dispatch(setEdit(false));
                      }}
                    />
                    <ButtonGreen
                      title={t("general.save")}
                      onClick={() => {
                        dispatch(setShow(true));
                      }}
                    />
                  </div>

                  {show ? (
                    <PopupConfirmation
                      title={t("operationalhour.confirmation_title")}
                      subtitle={t("general.confirmation_subtitle")}
                      onCancel={() => {
                        dispatch(setShow(false));
                      }}
                      onDelete={() => {
                        Action.updateOperationalHours(dispatch, {
                          monday: dataMonday,
                          tuesday: dataTuesDay,
                          wednesday: dataWednesday,
                          thursday: dataThursday,
                          friday: dataFriday,
                          saturday: dataSaturday,
                          sunday: dataSunday,
                        });
                        dispatch(setShow(false));
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default OperationalMain;
