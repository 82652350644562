function NotificationModel({
  id,
  from,
  title,
  subtitle,
  createdAt,
  read,
  type,
  subType,
  dataId,
}) {
  let obj = {};
  obj.id = id;
  obj.from = from;
  obj.title = title;
  obj.subtitle = subtitle;
  obj.createdAt = createdAt;
  obj.read = read;
  obj.type = type;
  obj.subType = subType;
  obj.dataId = dataId;
  return obj;
}

export default NotificationModel;
