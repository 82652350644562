import {Provider, useDispatch, useSelector} from "react-redux";
import StoreHelper from "../../services/store-helper";
import reducerSlice, {setImage, setModalImage, setSelectedItem} from "./redux/Reducer";
import {useTranslation} from "react-multi-lang";
import React, {useEffect} from "react";
import ChatList from "./components/ChatList";
import DetailChat from "./components/DetailChat";
import Action from "./redux/Action";
import BlankChat from "./components/BlankChat";
import SearchItemChat from "./components/SearchItemChat";
import Cookies from "js-cookie";
import {initializeWebSocket, ws} from "./socket/Socket";

const MainComponent = ({getBreadcrums}) => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const selectedItem = useSelector((state) => state.reducer.selectedItem);

    const reconnectWebSocket = () => {
        if (ws === null || ws.readyState === WebSocket.CLOSED || ws.readyState === WebSocket.CLOSING) {
            console.log('Reconnecting WebSocket...');
            initializeWebSocket(dispatch); // Reinitialize WebSocket if it's closed
        }
    };

    useEffect(() => {
        Action.getList(dispatch);
        Cookies.remove("selectedChatItem");

        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                dispatch(setSelectedItem({}));
                dispatch(setModalImage(false));
                dispatch(setImage({
                    title: "",
                    src: "",
                }));
                Cookies.remove("selectedChatItem");
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        const handleWindowFocus = () => {
            // console.log('Browser kembali aktif');
            reconnectWebSocket();
        };

        // const handleWindowBlur = () => {
        //   console.log('Browser tidak aktif');
        // ws.close();
        // };

        window.addEventListener('focus', handleWindowFocus);
        // window.addEventListener('blur', handleWindowBlur);

        // Initialize WebSocket when component mounts
        initializeWebSocket(dispatch);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('focus', handleWindowFocus);
            // window.removeEventListener('blur', handleWindowBlur);

            // Close WebSocket when component unmounts
            if (ws) {
                ws.close();
            }
        };
    }, [dispatch]);

    useEffect(() => {
        const title = `${t("chats.title")} > ${t("chats.list")}`;
        getBreadcrums(title);
    }, [getBreadcrums]);

    return (
        <>
            <div className="flex flex-row items-center px-2 gap-x-2">
                <i className="mt-1.5">
                    <img
                        className="h-full w-10"
                        src={process.env.REACT_APP_ASSETS_IMAGE + "back-icon.svg"}
                        alt="back-icon"
                    />
                </i>
                <h2 className="text-green-primary font-bold">{t("chats.title")}</h2>
            </div>

            <div className="pt-6 h-full flex gap-x-3 items-stretch">
                <div className="w-[40%] ">
                    <SearchItemChat/>
                    <div className="p-4 bg-white rounded-2xl h-[calc(100%-60px)]">
                        <ChatList/>
                    </div>
                </div>

                <div className="w-[60%] h-full">
                    <div
                        className={` h-full p-4 rounded-2xl flex flex-col ${Object.keys(selectedItem).length > 0 ? "bg-white" : "bg-gray-chat-blank"}`}>
                        {Object.keys(selectedItem).length > 0 ? (
                            <DetailChat/>
                        ) : (
                            <BlankChat/>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const Chat = ({getBreadcrums}) => {
    const store = StoreHelper.generateStoreState(reducerSlice);
    store.getState();

    return (
        <Provider store={store}>
            <MainComponent getBreadcrums={getBreadcrums}/>
        </Provider>
    );
};

export default Chat;
