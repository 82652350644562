import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "BankDetails",
  initialState: {
    validatePass: false,
    bankDetails: {
      bankName: "",
      bankId: "",
      bankNumber: "",
    },
    data: {},
    edit: false,
    errorBankDetails: {},
    bank: [],
    canSave: false,
    show: false,
    password: "",
    infoPopup: false,
    otpPopup: false,
    otp: ['', '', '', '', '', ''],
    countDown: 0,
    otpUpdateBankDetailMerchantId: "",
    errorOtp: "",
    suspendPopup: false
  },
  reducers: {
    setBankDetails: (state, action) => {
      state.bankDetails = action.payload;
    },
    setErrorBankDetails: (state, action) => {
      state.errorBankDetails = action.payload;
    },
    setValidatePass: (state, action) => {
      state.validatePass = action.payload;
    },
    setBank: (state, action) => {
      state.bank = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setEdit: (state, action) => {
      state.edit = action.payload;
    },
    setCanSave: (state, action) => {
      state.canSave = action.payload;
    },
    setShow: (state, action) => {
      state.show = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setInfoPopup: (state, action) => {
      state.infoPopup = action.payload;
    },
    setOtpPopup: (state, action) => {
      state.otpPopup = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
    setCountdown: (state, action) => {
      state.countDown = action.payload;
    },
    setOtpUpdateBankDetailMerchantId: (state, action) => {
      state.otpUpdateBankDetailMerchantId = action.payload;
    },
    setErrorOtp: (state, action) => {
      state.errorOtp = action.payload;
    },
    setSuspendPopup: (state, action) => {
      state.suspendPopup = action.payload;
    }
  },
});

export const {
  setBankDetails,
  setErrorBankDetails,
  setValidatePass,
  setBank,
  setData,
  setEdit,
  setCanSave,
  setShow,
  setPassword,
  setOtpPopup,
  setOtp,
  setCountdown,
  setOtpUpdateBankDetailMerchantId,
  setErrorOtp,
  setInfoPopup,
  setSuspendPopup
} = reducerSlice.actions;

export default reducerSlice;
