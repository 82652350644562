import React, { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import Action from "../redux/Action";

const Header = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const categoryList = useSelector((state) => state.reducer.categoryList);

  let filterTimeout;
  useEffect(() => {
    Action.getCategory(dispatch);
  }, []);

  return (
    <>
      <h4 className="opacity-60 font-bold w-60">
        {t("products.list").toUpperCase()}
      </h4>
      <label className="flex-1 min-w-fit relative block">
        <span className="absolute inset-y-0 left-1.5 flex items-center pl-2">
          <img
            className="h-3.5 w-5 fill-slate-300"
            src={process.env.REACT_APP_ASSETS_IMAGE + "username-icon.svg"}
            alt="username-icon"
          />
        </span>
        <input
          type="text"
          placeholder={t("products.search")}
          className="pl-10 pr-3 w-full block sm:text-sm shadow-sm border border-gray-primary rounded-xl bg-white  placeholder:text-slate-40 "
          onChange={(e) => {
            clearTimeout(filterTimeout);
            filterTimeout = setTimeout(() => {
              Action.searchProduct(dispatch, {
                search: e.target.value,
              });
            }, 100);
          }}
        />
      </label>
      <select
        className="sm:text-sm shadow-sm border border-gray-primary rounded-xl"
        name=""
        id=""
        onChange={(e) => {
          clearTimeout(filterTimeout);
          filterTimeout = setTimeout(() => {
            Action.searchCategory(dispatch, {
              categoryId: e.target.value,
            });
          }, 100);
        }}
      >
        <option value="">All Category</option>
        {categoryList?.map(({ id, name }, index) => (
          <option key={index} value={id}>
            {name}
          </option>
        ))}
      </select>
    </>
  );
};

export default Header;
