import {createSlice} from "@reduxjs/toolkit";

const reducerSlice = createSlice({
    name: "chat",
    initialState: {
        selectedItem: {},
        isUploadOpen: false,
        bodyChat: "",
        modalImage: false,
        image: {
            title: "",
            src: "",
        },
        data: [],
        searchedData: [],
        loaderListChat: false,
        rowsTextarea: 1,
        error: {
            isError: false,
            type: ""
        },
        buttonLoading: false,
    },
    reducers: {
        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload;
        },
        setIsUploadOpen: (state, action) => {
            state.isUploadOpen = action.payload;
        },
        setBodyChat: (state, action) => {
            state.bodyChat = action.payload;
        },
        setModalImage: (state, action) => {
            state.modalImage = action.payload;
        },
        setImage: (state, action) => {
            state.image = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        setLoaderListChat: (state, action) => {
            state.loaderListChat = action.payload;
        },
        setRowsTextarea: (state, action) => {
            state.rowsTextarea = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setSearchedData: (state, action) => {
            state.searchedData = action.payload;
        },
        setButtonLoading: (state, action) => {
            state.buttonLoading = action.payload;
        }
    },
});

export const {
    setSelectedItem,
    setIsUploadOpen,
    setBodyChat,
    setModalImage,
    setImage,
    setData,
    setLoaderListChat,
    setRowsTextarea,
    setError,
    setSearchedData,
    setButtonLoading
} = reducerSlice.actions;

export default reducerSlice;