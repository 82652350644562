import Cookies from "js-cookie";
import DashboardRepository from "../../../repositories/DashboardRepository";
import DateUtility from "../../../utils/date-utility";
import GeneralUtility from "../../../utils/general-utility";
import PopupUtility from "../../../utils/popup-utility";
import RevenueModel from "../models/RevenueModel";
import TopSellingModel from "../models/TopSellingModel";
import {
  setStatusMerchant,
  setTopSellingData,
  setRevenueChartData,
  setRevenueData,
  setHighestChartData,
  setFilter,
  setFilterInNumber,
  setLeaderboardData,
  setTopSellingTotalData,
  setTopSellingTotalPage,
  setIsLoadingTopSelling,
  setIsLoadingLeaderboard,
  setIsLoadingRevenue,
  setIsLoadingBuyback,
  setPopup,
  setIsLoadingGenerate,
  setCurrentStatus,
  setPopupSuspend,
} from "./Reducer";
import EncryptDecrypt from "../../../services/encrypt-decrypt";
import BalanceRepository from "../../../repositories/BalanceRepository";
import OperationalHourRepository from "../../../repositories/OperationalHourRepository";
import AuthRepository from "../../../repositories/AuthRepository";
import RouteName from "../../../services/routename";

const getRevenueData = async (dispatch, time) => {
  dispatch(setIsLoadingRevenue(true));
  dispatch(setRevenueChartData([]));
  let filter;
  if (time === 0) {
    filter = "week";
  } else if (time === 1) {
    filter = "month";
  } else if (time === 2) {
    filter = "year";
  }
  let payload = await DateUtility.dropdownDashboard(time);
  let response = await DashboardRepository.getRevenue({
    ...payload,
    type: filter,
  });
  let chart1 = [];
  let chart2 = [];
  let data = {};
  let highestValue;
  if (!response.error) {
    let resData = response.data.data;
    data = RevenueModel({
      product_sales: resData.totalProductSales,
      revenue_this_period: GeneralUtility.addCommas(
        resData.totalPriceThisPeriod
      ),
      revenue_last_period: GeneralUtility.addCommas(
        resData.totalPriceLastPeriod
      ),
      persentagePrice: resData.persentagePrice,
    });
    for (let i = 0; i < resData.chart.length; i++) {
      const dat = resData.chart[i].lastPeriod;
      const label = resData.chart[i].orderDate;
      chart1.push({ x: label, y: dat });
    }
    for (let i = 0; i < resData.chart.length; i++) {
      const dat = resData.chart[i].thisPeriod;
      const label = resData.chart[i].orderDate;
      chart2.push({ x: label, y: dat });
    }
  } else {
    data = RevenueModel({
      product_sales: 0,
      revenue_this_period: 0,
      revenue_last_period: 0,
    });
  }
  let final = [chart1, chart2];
  let joinedArray = chart1.concat(chart2);
  if (joinedArray.length > 0) {
    highestValue = joinedArray.reduce((prev, current) =>
      prev.y > current.y ? prev : current
    );
  } else {
    highestValue = { y: 100000 };
  }
  dispatch(setHighestChartData(highestValue.y));
  dispatch(setRevenueData(data));
  dispatch(setRevenueChartData(final));
  dispatch(setIsLoadingRevenue(false));
};

const getTopSelling = async (dispatch, { page, time }) => {
  dispatch(setIsLoadingTopSelling(true));
  let payload = await DateUtility.dropdownDashboard(time);
  let response;
  let data = [];
  if (payload !== undefined) {
    response = await DashboardRepository.getTopSelling(payload.thisPeriod);
  }
  if (!response.error) {
    response.data.data.forEach((element) => {
      const topSelling = TopSellingModel({
        id: "",
        merchant: "",
        product: element.productName,
        salesCount: element.salesCount,
        totalSales: element.totalSales,
      });
      data.push(topSelling);
    });
  }

  dispatch(setTopSellingTotalData(response.total));
  dispatch(setTopSellingTotalPage(response.total_page));
  dispatch(setTopSellingData(data));
  dispatch(setIsLoadingTopSelling(false));
};

const getLeaderBoard = async (dispatch, time) => {
  dispatch(setIsLoadingLeaderboard(true));
  let payload = await DateUtility.dropdownDashboard(time);
  let response;
  let data;
  if (payload !== undefined) {
    response = await DashboardRepository.getLeaderBoard(payload.thisPeriod);
  }

  if (!response.error) {
    data = response.data.data;
  } else {
    data = { newOrder: 0, onDelivery: 0, completed: 0 };
  }
  dispatch(setLeaderboardData(data));
  dispatch(setIsLoadingLeaderboard(false));
};

const getFilter = async (dispatch, time) => {
  let filter;
  if (time === 0) {
    filter = "Week";
  } else if (time === 1) {
    filter = "Month";
  } else if (time === 2) {
    filter = "Year";
  }
  dispatch(setFilter(filter));
  dispatch(setFilterInNumber(time));
};

const getTimeMerchantStatus = (operationalHour) => new Promise((resolve, reject) => {
  if (!operationalHour) {
    return reject("operationalHour not found");
  }
  const date = new Date();
  const day = convertToCustomDay(date.getDay());
  const dayOfWeek = operationalHour.find(item => item.dayOfWeek === day);
  return resolve(dayOfWeek);
});

const convertToCustomDay = (dayIndex) => {
  if (dayIndex === 0) {
    dayIndex = 7;
  }

  return dayIndex;
};

const getStatusMerchant = async (dispatch) => {
  dispatch(setIsLoadingBuyback(true));
  let status = {
    id: "",
    statusMerchant: true,
    statusMerchantHoliday: true,
    holidayStartMerchant: "",
    holidayEndMerchant: "",
  };

  let response = await DashboardRepository.getStatusMerchant();

  if (!response.error) {
    const res = response.data.data;
    const getTimeMerchant = await getTimeMerchantStatus(res.merchant.operationalHour);
    const statusOperational = getTimeMerchant.status;
    const idDay = getTimeMerchant.id;

    status = {
      statusMerchant: statusOperational,
      statusMerchantHoliday: res.merchant.status === "Open",
      holidayStartMerchant: DateUtility.formatDateWithDay(res.merchant.startDateHoliday),
      holidayEndMerchant: DateUtility.formatDateWithDay(res.merchant.endDateHoliday),
      id: idDay,
      statusTimeOutsideRange: isCurrentTimeWithinRange(getTimeMerchant.startTimeOp, getTimeMerchant.endTimeOp)
    };
  }
  dispatch(setStatusMerchant(status));
  dispatch(setIsLoadingBuyback(false));
};

function isCurrentTimeWithinRange(startTime, endTime) {
  const currentTime = new Date();
  const startTimeObj = new Date(currentTime.toDateString() + ' ' + startTime);
  const endTimeObj = new Date(currentTime.toDateString() + ' ' + endTime);
  return currentTime >= startTimeObj && currentTime <= endTimeObj;
}

const openNow = async (dispatch, status) => {
  let response = await DashboardRepository.openNow();
  if (!response.error) {
    let response = await OperationalHourRepository.updateOperationalHours({
      id: status.id,
      status: true,
    });
    getStatusMerchant(dispatch);
  } else {
    PopupUtility.responseValidate("Failed", response.message);
  }
};

const changeStatusApprove = async (dispatch, id, data) => {
  const user = { ...data, merchantStatus: 1 };
  let response = await DashboardRepository.changeStatusApprove({ id: id });
  if (!response.error) {
    Cookies.set("userMerchant", EncryptDecrypt.enc(user), { expires: 3 });
    dispatch(setPopup(false));
  } else {
    PopupUtility.responseValidate("Failed", response.message);
  }
};

const checkStatus = async (dispatch, status) => {
  if (status === 4) {
    dispatch(setPopup(true));
  }
};

const generateExcel = async (dispatch, time) => {
  dispatch(setIsLoadingGenerate(true));
  let filter;
  if (time === 0) {
    filter = "week";
  } else if (time === 1) {
    filter = "month";
  } else if (time === 2) {
    filter = "year";
  }
  let payload = await DateUtility.dropdownDashboard(time);
  let response = await DashboardRepository.generateExcel({
    ...payload,
    type: filter,
  });

  if (!response.error) {
    const print = response.data.data;
    const downloadLink = print;
    if (downloadLink) {
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = downloadLink;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.download = "dashboard.xlsx"; // Adjust the file name if needed

      // Simulate a click event to trigger the file download
      link.click();

      // Clean up the temporary link element
      link.remove();
    }
  } else {
    PopupUtility.responseValidate("Failed", response.message);
  }
  dispatch(setIsLoadingGenerate(false));
};

const getBalance = async () => {
  let response = await BalanceRepository.getBalance();
  if (!response.error) {
    let res = response.data;
    Cookies.set("balanceMerchant", EncryptDecrypt.enc(res.price));
  }
};

const getCheckedMerchant = async (dispatch) => {
  let response = await AuthRepository.checkStatusMerchant();
  const status = response.data.data?.status;

  if (!response.error && status === -1) {
    dispatch(setCurrentStatus(status));
    dispatch(setPopupSuspend(true));
  }
};

const redirectToFeedbacks = () => {
  Cookies.set("redirectedPageBySuspendAccount", "feedback");
  window.location.href = RouteName.helpcenter;
};

const Action = {
  // getBuyback,
  getRevenueData,
  getTopSelling,
  getLeaderBoard,
  getFilter,
  getStatusMerchant,
  openNow,
  changeStatusApprove,
  checkStatus,
  generateExcel,
  getBalance,
  getCheckedMerchant,
  redirectToFeedbacks
};

export default Action;
