import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "accountdetails",
  initialState: {
    validateStatus: false,
    showPass: false,
    showNewPass: false,
    showConfirmPass: false,
    security: {
      password: "",
      new_password: "",
      confirm_password: "",
    },
    errorSecurity: {},
    canSave: false,
  },
  reducers: {
    setSecurity: (state, action) => {
      state.security = action.payload;
    },
    setShowPass: (state, action) => {
      state.showPass = action.payload;
    },
    setShowConfirmPass: (state, action) => {
      state.showConfirmPass = action.payload;
    },
    setShowNewPass: (state, action) => {
      state.showNewPass = action.payload;
    },
    setValidateStatus: (state, action) => {
      state.validateStatus = action.payload;
    },
    setErrorSecurity: (state, action) => {
      state.errorSecurity = action.payload;
    },
    setCanSave: (state, action) => {
      state.canSave = action.payload
    }
  },
});

export const {
  setErrorSecurity,
  setSecurity,
  setShowConfirmPass,
  setShowNewPass,
  setShowPass,
  setValidateStatus,
  setCanSave,
} = reducerSlice.actions;

export default reducerSlice;
