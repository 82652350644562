import { setShowDropdown } from "./Reducer";

const changeShowNotif = async (store, { show }) => {
  store.dispatch(setShowDropdown(show));
};

const Action = {
  changeShowNotif,
};

export default Action;
