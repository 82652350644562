import React from 'react';
import {useTranslation} from 'react-multi-lang';
import {useDispatch, useSelector} from 'react-redux';
import Action from '../redux/Action';

const SearchItemChat = () => {
    const t = useTranslation();
    const dispatch = useDispatch();

    const data = useSelector((state) => state.reducer.data);
    const searchedData = useSelector((state) => state.reducer.searchedData);
    
    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    return (
        <label className="relative block mt-0 mb-4">
            <span className="absolute inset-y-0 left-1.5 flex items-center pl-2">
                <img
                    className="h-3.5 w-5 fill-slate-300"
                    src={process.env.REACT_APP_ASSETS_IMAGE + "search-icon.svg"}
                    alt="search-icon"/>
            </span>
            <input
                type="text"
                placeholder={t("chats.search_name")}
                className="pl-10 p-2 w-full block sm:text-sm shadow-sm border border-white rounded-xl bg-white  placeholder:text-slate-40 "
                onChange={debounce((e) => {
                    const value = e.target.value;
                    Action.searchItemListChat(dispatch, {value, chatList: data, searchedData});
                }, 800)}
            />
        </label>
    );
};

export default SearchItemChat;