import React from "react";
import { useTranslation } from "react-multi-lang";

const TakeDownLabel = () => {
  const t = useTranslation();
  return (
    <div className="bg-pink-primary text-red-primary p-1 text-center rounded-md">
      {t("products.takedown_warning")}
    </div>
  );
};

export default TakeDownLabel;
