import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import { setCountdown, setOtp } from '../redux/Reducer';
import Action from '../redux/Action';
import ButtonWhite from '../../../../../components/ButtonWhite/ButtonWhite';
import ButtonGold from '../../../../../components/ButtonGold/ButtonGold';
import UseAuth from '../../../../auth/Auth';
import GeneralUtility from '../../../../../utils/general-utility';

const OtpInput = React.forwardRef(({ onChange, value, onEnter, errorOtp }, ref) => (
    <input
        ref={ref}
        type="text"
        maxLength="1"
        value={value}
        onChange={onChange}
        onKeyPress={(e) => {
            if (e.key === 'Enter' && onEnter) {
                onEnter();
            }
        }}
        onFocus={(e) => {
            e.target.select();
        }}
        className={`text-center rounded-lg w-[50px] h-[70px]
        focus:outline-none 
        ${errorOtp !== "" ?
                "border-2 border-red-600 ring-red-600"
                : "border-0 focus:border-gold-primary focus:ring-gold-primary"}        
        focus:ring 
        text-3xl font-bold`}
    />
));

const PopupOtp = ({ onCancel, onSubmit }) => {
    const t = useTranslation();
    const dispatch = useDispatch();

    const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
    const otp = useSelector(state => state.reducer.otp);
    const countDown = useSelector(state => state.reducer.countDown);
    const errorOtp = useSelector(state => state.reducer.errorOtp);

    const user = UseAuth.getUser();
    const emailOwner = user.emailOwner;

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (countDown === 0) {
                clearInterval(intervalId);
            } else {
                // dispatch disini
                dispatch(setCountdown(countDown - 1));
            }
        }, 1000);

        // Membersihkan interval ketika komponen di-unmount atau waktu habis
        return () => clearInterval(intervalId);
    }, [countDown]);

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={() => {
                    onCancel();
                }}
            />
            <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-gray-primary rounded-lg shadow-lg">
                    <div className="m-3">
                        <h3 className="text-green-primary font-bold">
                            {"OTP Verification"}
                        </h3>
                        <p>{t("balance.input_verification", { emailOwner })}</p>
                        <p>{t("balance.max_input_otp")}</p>
                        <div className="grid grid-cols-6 gap-y-4 my-6 px-7">
                            {otp.map((digit, i) => (
                                <OtpInput
                                    key={i}
                                    value={digit}
                                    ref={inputRefs[i]}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const newOtp = [...otp];
                                        newOtp[i] = value;
                                        dispatch(setOtp(newOtp));

                                        // Pindah fokus ke input berikutnya jika value sudah diisi
                                        if (value !== '' && i < inputRefs.length - 1) {
                                            inputRefs[i + 1].current.focus();
                                        }
                                    }}
                                    errorOtp={errorOtp}
                                    onEnter={i === otp.length - 1 ? onSubmit : null} />
                            ))}
                        </div>

                        {errorOtp !== "" ?
                            (<p className='text-center mb-2 text-red-600'>{errorOtp}</p>)
                            : ""}

                        <p className='text-center mb-8'>
                            Didn't receive code ? <span
                                onClick={() => {
                                    if (countDown === 0) {
                                        Action.sendOtp(dispatch);
                                    }
                                }}
                                className={`text-gold-secondary relative mx-1 font-bold
                                ${countDown === 0 ? "cursor-pointer no-underline hover:underline" : "opacity-50 cursor-not-allowed"}`}>
                                Resend code
                            </span> after <span className='relative ml-1'>{countDown}s</span>
                        </p>
                        <div className="items-stretch gap-2 mt-5 flex justify-end">
                            <ButtonWhite
                                title={t("general.cancel")}
                                onClick={() => {
                                    onCancel();
                                }}
                            />
                            <ButtonGold
                                title={"confirm"}
                                onClick={() => {
                                    const otpLength = GeneralUtility.arrayToString(otp).length;
                                    if (otpLength === 6) {
                                        onSubmit();
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopupOtp;

