import { useTranslation } from "react-multi-lang";
import { useDispatch } from "react-redux";
import TimePickerComponent from "../../../../../../components/TimePickerComponent/TimePickerComponent";

const OperationalItem = ({ day, item, setData }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  return (
    <tr>
      <td className="py-2 pl-1">
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            value={item.isOpen}
            checked={item.isOpen}
            onChange={() => {
              let dat = { ...item };
              dat.isOpen = !dat.isOpen;
              dispatch(setData(dat));
            }}
          />
          <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-secondary"></div>

          <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            {day}
          </span>
        </label>
      </td>

      <td className="pl-1 text-center">
        <div>
          <label>
            <select
              className="text-sm border rounded-xl w-28 bg-white font-medium"
              name="operationalHour"
              defaultValue={item.type}
              onChange={(e) => {
                let dat = { ...item };
                if (e.target.value === "24hour") {
                  dat.openTime = "1945-08-16T15:00:00.000Z";
                  dat.closeTime = "1945-08-17T14:59:59.000Z";
                  dat.type = "24hour";
                } else {
                  dat.type = "manual";
                }
                dispatch(setData(dat));
              }}
            >
              <option value="manual">Manual</option>
              <option value="24hour">24 Hour</option>
            </select>
          </label>
        </div>
      </td>

      <td className="text-center">
        <TimePickerComponent
          disabled={
            item.isOpen === false || item.type === "24hour" ? true : false
          }
          value={item.openTime}
          format="HH:mm"
          placeholder={t("operationalhour.select_hour_placeholder")}
          onChange={(dateObject) => {
            let time = dateObject;
            let dat = { ...item };
            dat.openTime = new Date(time).toISOString();
            dispatch(setData(dat));
          }}
          inputClass={`${
            item.isOpen === false || item.type === "24hour"
              ? "bg-gray-disable"
              : "bg-white"
          } block sm:text-sm shadow-sm border border-gray-primary rounded-xl placeholder:text-slate-40 cursor-pointer`}
        />
      </td>
      <td className="text-center">
        <TimePickerComponent
          disabled={
            item.isOpen === false || item.type === "24hour" ? true : false
          }
          value={item.closeTime}
          format="HH:mm"
          placeholder={t("operationalhour.select_hour_placeholder")}
          onChange={(dateObject) => {
            let time = dateObject;
            let dat = { ...item };
            dat.closeTime = new Date(time).toISOString();
            dispatch(setData(dat));
          }}
          inputClass={`${
            item.isOpen === false || item.type === "24hour"
              ? "bg-gray-disable"
              : "bg-white"
          } block sm:text-sm shadow-sm border border-gray-primary rounded-xl placeholder:text-slate-40 cursor-pointer`}
        />
      </td>
    </tr>
  );
};

export default OperationalItem;
