import HelpCenterRepository from "../../../../../repositories/HelpCenterRepository";
import {
  setData,
  setLoading,
  setShowQuestionId,
  setShowSectionId,
} from "./Reducer";

const getData = async (store) => {
  store.dispatch(setLoading(true));
  let response = await HelpCenterRepository.getFaq();
  if (response) {
    store.dispatch(setData(response));
    store.dispatch(setLoading(false));
  }
};

const changeShowSectionId = async (
  dispatch,
  { currentSectionId, sectionId }
) => {
  if (currentSectionId === sectionId) {
    dispatch(setShowSectionId(-1));
  } else {
    dispatch(setShowSectionId(sectionId));
  }
  dispatch(setShowQuestionId(-1));
};

const changeShowQuestionId = async (
  dispatch,
  { currentQuestionId, questionId }
) => {
  if (currentQuestionId === questionId) {
    dispatch(setShowQuestionId(-1));
  } else {
    dispatch(setShowQuestionId(questionId));
  }
};

const Action = {
  getData,
  changeShowSectionId,
  changeShowQuestionId,
};

export default Action;
