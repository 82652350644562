import React, { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { Provider, useDispatch, useSelector } from "react-redux";
import ButtonGenExcel from "../../components/ButtonGenExcel/ButtonGenExcel";
import StoreHelper from "../../services/store-helper";
import UseAuth from "../auth/Auth";
import Leaderboard from "./components/leaderboard/Leaderboard";
import Insight from "./components/insight/Insight";
import Revenue from "./components/revenue/Revenue";
import TopSellingProducts from "./components/topselling/TopSellingProducts";
import Action from "./redux/Action";
import reducerSlice, { setPopupSuspend } from "./redux/Reducer";
import FilterDashboard from "./components/FilterDashboard";
import PopupNewAcc from "./components/PopupNewAcc";
import InsightSuspend from "./components/insight/InsightSuspend";
import SuspendInformation from "../../components/SuspendInformation/SuspendInformation";

const Dashboard = ({ getBreadcrums }) => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  const MainComponent = () => {
    const dispatch = useDispatch();
    const data = UseAuth.getUser();
    const t = useTranslation();
    const filter = useSelector((state) => state.reducer.filterInNumber);
    const popup = useSelector((state) => state.reducer.popup);
    const statusMerchant = useSelector((state) => state.reducer.statusMerchant);
    const currentStatus = useSelector((state) => state.reducer.currentStatus);
    const popupSuspend = useSelector((state) => state.reducer.popupSuspend);

    store.getState();
    useEffect(() => {
      Action.getRevenueData(dispatch, 0);
      Action.getTopSelling(dispatch, { page: 1, time: 0 });
      Action.getLeaderBoard(dispatch, 0);
      Action.getStatusMerchant(dispatch);
      Action.checkStatus(dispatch, data.merchantStatus);
      Action.getBalance();
      Action.getCheckedMerchant(dispatch);
      getBreadcrums(t("dashboard.title"));
    }, []);

    return (
      <>
        <div>
          <div className="flex flex-row items-center px-2 gap-x-2">
            <i className="mt-1.5">
              <img
                className="h-full w-10"
                src={process.env.REACT_APP_ASSETS_IMAGE + "back-icon.svg"}
                alt="back-icon"
              />
            </i>
            <h2 className="text-green-primary font-bold">
              {t("dashboard.title")}
            </h2>
          </div>

          <div className="flex items-center px-2">
            <div className="text-lg">
              {t("dashboard.greeting", { params: data.merchantName })}
            </div>
            <div className="flex-1 flex justify-end items-stretch text-right">
              <FilterDashboard store={store} />
              <div className="border mx-5" />
              <ButtonGenExcel
                title={t("dashboard.generate_excel")}
                onClick={() => {
                  Action.generateExcel(dispatch, filter);
                }}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-2 my-1">
          <div className={`${currentStatus === -1 || (!statusMerchant.statusMerchantHoliday || !statusMerchant.statusMerchant)
            ? "lg:col-span-4 md:col-span-12"
            : "hidden"
            }`}>
            {currentStatus === -1 ? <InsightSuspend /> : <Insight statusMerchant={statusMerchant} />}
          </div>
          <div
            className={`${currentStatus === -1 || !statusMerchant.statusMerchantHoliday || !statusMerchant.statusMerchant
              ? "lg:col-span-8 md:col-span-12"
              : "lg:col-span-12"
              }`}>
            <Revenue
              status={!statusMerchant.statusMerchantHoliday || !statusMerchant.statusMerchant} />
          </div>
        </div>

        <div className="flex flex-row">
          <div className="basis-3/5 my-1 mr-1">
            <TopSellingProducts store={store} />
          </div>
          <div className="basis-2/5 my-1 ml-1">
            <Leaderboard store={store} />
          </div>
        </div>

        {popup ? (
          <PopupNewAcc
            title={t("dashboard.congratulations")}
            subtitle={t("dashboard.greet_newacc")}
            onConfirm={() => {
              Action.changeStatusApprove(dispatch, data.merchantsId, data);
            }}
          />
        ) : ""}

        {popupSuspend ? (
          <SuspendInformation
            onClick={() => {
              Action.redirectToFeedbacks();
            }}
            onCancel={() => {
              dispatch(setPopupSuspend(false));
            }} />
        ) : ""}
      </>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default Dashboard;
