import React, { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { Provider, useDispatch, useSelector } from "react-redux";
import ModalImage from "../../../../components/ModalImage/ModalImage";
import StoreHelper from "../../../../services/store-helper";
import Action from "./redux/Action";
import reducerSlice from "./redux/Reducer";
import GeneralUtility from "../../../../utils/general-utility";

const LegalDocuments = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  store.getState();

  const MainComponent = () => {
    const t = useTranslation();
    const dispatch = useDispatch();

    const data = useSelector((state) => state.reducer.data);
    const modalImage = useSelector((state) => state.reducer.modalImage);
    const image = useSelector((state) => state.reducer.image);

    useEffect(() => {
      Action.getData(dispatch);
    }, []);

    return (
      <>
        <div className="flex flex-col gap-y-4 md:h-[27em] px-3 overflow-y-auto">
          {/* upload KTP */}
          <div className="flex flex-row gap-x-3">
            <div className="w-44">
              <div className="text-md font-bold">
                {t("register.upload_ktp")}
              </div>
              <div className="opacity-40">
                {t("general.support_files")} jpeg,jpg,png
              </div>
              <div className="opacity-40">{t("general.max_size")} 1Mb</div>
            </div>
            <div>
              <div
                className="flex flex-col justify-center text-center cursor-pointer"
                onClick={() => {
                  Action.changeModalImage(dispatch, {
                    showModal: true,
                    title: "ktp.jpg",
                    img: data.ktpFile,
                  });
                }}
              >
                <img
                  className="h-10"
                  src={process.env.REACT_APP_ASSETS_IMAGE + "file-icon.svg"}
                  alt="uploaded-icon"
                />
                <p className="font-bold text-xs pt-1">ktp.jpg</p>
              </div>
            </div>
          </div>
          <hr />

          {/* NIK */}
          <div className="flex flex-row items-center gap-x-3">
            <div className="w-44">
              <div className="text-md font-bold">
                {t("register.nik")}
                <span className="text-gold-secondary">*</span>
              </div>
            </div>
            <div className="flex-1 text-md">{data.ktpNik}</div>
          </div>
          <hr />

          {/* upload NPWP */}
          <div className="flex flex-row gap-x-3">
            <div className="w-44">
              <div className="text-md font-bold">
                {t("register.upload_npwp")}
              </div>
              <div className="opacity-40">
                {t("general.support_files")} jpeg,jpg,png
              </div>
              <div className="opacity-40">{t("general.max_size")} 1Mb</div>
            </div>
            <div>
              <div
                className="flex flex-col justify-center text-center cursor-pointer"
                onClick={() => {
                  Action.changeModalImage(dispatch, {
                    showModal: true,
                    title: "npwp.jpg",
                    img: data.npwpFile,
                  });
                }}
              >
                <img
                  className="h-10"
                  src={process.env.REACT_APP_ASSETS_IMAGE + "file-icon.svg"}
                  alt="uploaded-icon"
                />
                <p className="font-bold text-xs pt-1">npwp.jpg</p>
              </div>
            </div>
          </div>
          <hr />

          {/* NPWP Number */}
          <div className="flex flex-row items-center gap-x-3">
            <div className="w-44">
              <div className="text-md font-bold">
                {t("register.npwp_number")}
                <span className="text-gold-secondary">*</span>
              </div>
            </div>
            <div className="flex-1 text-md">
              {GeneralUtility.npwpFromInt(data.npwpNumber)}
            </div>
          </div>
          <hr />

          {/* NIB */}
          <div className="flex flex-row items-center gap-x-3 pb-2">
            <div className="w-44">
              <div className="text-md font-bold">
                {t("register.nib")}
                <span className="text-gold-secondary">*</span>
              </div>
            </div>
            <div className="flex-1 text-md">{data.merchantNib}</div>
          </div>
        </div>

        {modalImage ? (
          <ModalImage
            onCancel={({ showModal }) => {
              Action.changeModalImage(dispatch, { showModal: showModal });
            }}
            title={image.title}
            img={image.src}
          />
        ) : null}
      </>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default LegalDocuments;
