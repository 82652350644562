import React from "react";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RouteName from "../../../../services/routename";

const Leaderboard = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.reducer.isLoadingLeaderboard);
  const leaderboardData = useSelector((state) => state.reducer.leaderboardData);
  const data = [
    {
      id: 1,
      name: t("dashboard.new_order"),
      total: leaderboardData.newOrder,
      type: "NEW_ORDER",
    },
    {
      id: 2,
      name: t("dashboard.on_delivery"),
      total: leaderboardData.onDelivery,
      type: "ON_SHIPMENT",
    },
    {
      id: 3,
      name: t("dashboard.completed"),
      total: leaderboardData.completed,
      type: "COMPLETED",
    },
  ];

  return (
    <div className="section-grey px-5 py-3 h-[40vh] overflow-y-auto">
      <div className="flex justify-between items-center">
        <h2 className="text-sm py-3 font-semibold tracking-widest text-[#A7ADA9]">
          {t("dashboard.merchant_leaderboard").toUpperCase()}
        </h2>
      </div>
      {isLoading ? (
        <>
          <div className="w-full section-white p-1 mb-2">
            <Skeleton height="40px" />
          </div>
          <div className="w-full section-white p-1 mb-2">
            <Skeleton height="40px" />
          </div>
          <div className="w-full section-white p-1 mb-2">
            <Skeleton height="40px" />
          </div>
        </>
      ) : (
        <>
          {data.map((items, index) => (
            <div
              className="w-full rounded-xl bg-white p-2 px-3 mb-2"
              key={index}
            >
              <div className="flex items-center">
                <div className="flex-1">
                  <div>
                    <div className="text-sm text-gold-secondary font-bold">
                      {items.name}
                    </div>
                    <h3 className="font-extrabold">{items.total}</h3>
                  </div>
                </div>
                <div
                  className="flex cursor-pointer"
                  onClick={() => {
                    navigate(RouteName.orders, {
                      state: { subType: items.type },
                    });
                  }}
                >
                  <img
                    className="h-3"
                    style={{ transform: "rotate(270deg)" }}
                    src={process.env.REACT_APP_ASSETS_IMAGE + "arrow.svg"}
                    alt="lowercase"
                  />
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Leaderboard;
