import Cookies from "js-cookie";
import { setPage } from "./Reducer";

const getPage = async (store, { page }) => {
  const redirectSuspend = Cookies.get("redirectedPageBySuspendAccount");
  const redirect = redirectSuspend || page;
  store.dispatch(setPage(redirect));
};

const Action = {
  getPage,
};

export default Action;
