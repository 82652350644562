import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "merchant application",
  initialState: {
    status: 2,
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { setStatus } = reducerSlice.actions;

export default reducerSlice;
