import React from "react";

const StatusVariant = ({ status, changeStatus }) => {
  let filterTimeout;
  return (
    <label className="inline-flex relative items-center cursor-pointer">
      <input
        type="checkbox"
        name="status"
        defaultChecked={status}
        onChange={(e) => {
          clearTimeout(filterTimeout);
          filterTimeout = setTimeout(() => {
            changeStatus(e.target.checked);
          }, 2000);
        }}
        className="sr-only peer "
      />
      <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-primary"></div>
    </label>
  );
};

export default StatusVariant;
