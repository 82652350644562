import React from "react";
import { Provider, useDispatch } from "react-redux";
import StoreHelper from "../../../services/store-helper";
import reducerSlice from "./redux/Reducer";
import { useTranslation } from "react-multi-lang";
import Status from "./components/Status";
import ButtonGold from "../../../components/ButtonGold/ButtonGold";
import { useLocation } from "react-router-dom";
import UseAuth from "../Auth";
import Action from "./redux/Action";

const MerchantApplication = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  store.getState();
  const location = useLocation();
  const statusNow = location.state.merchantStatus;

  const MainComponent = () => {
    const t = useTranslation();
    return (
      <div
        className="h-screen w-screen"
        style={{
          backgroundImage: `url(${
            process.env.REACT_APP_ASSETS_IMAGE + "bg-merchantapplication.jpg"
          })`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="pl-16 py-8">
          <img
            className="h-14"
            src={process.env.REACT_APP_ASSETS_IMAGE + "te-logo.svg"}
            alt="Tanam Emas Logo"
          />
        </div>
        <div className="flex flex-row items-center px-10">
          <div className="w-[42em] m-2 p-3">
            <img
              className=""
              src={
                process.env.REACT_APP_ASSETS_IMAGE +
                "Ilustrasi-mechantapplication.svg"
              }
              alt="merchant-application"
            />
          </div>
          <div className="flex-1 mx-10">
            <h1 className="text-gold-primary font-bold tracking-tight leading-tight">
              {t("merchantapp.title")}
            </h1>
            <div className="text-lg text-gray-secondary tracking-tight pt-5">
              {t("merchantapp.subtitle")}
            </div>
            <div className="border-dashed border-2 border-white border-opacity-20 text-center w-full mt-10 py-8">
              <div className="text-xl font-bold text-white">
                {t("merchantapp.approval_progression")}
              </div>
              <div className="py-8 px-20">
                <Status statusNow={statusNow.merchantStatus} />
              </div>
              <div className="text-md text-gold-primary text-opacity-60">
                {statusNow.merchantStatus === 4
                  ? "Your merchant account is now active! You can start creating your product listing and get some sales."
                  : t("merchantapp.notify")}
              </div>
            </div>
            <div className="text-center pt-5">
              <div>
                {statusNow.merchantStatus === 4 ? (
                  <ButtonGold
                    title={t("merchantapp.dashboard")}
                    onClick={() => {
                      Action.toDashboard(statusNow);
                    }}
                  />
                ) : (
                  <ButtonGold
                    title={t("merchantapp.back")}
                    onClick={() => {
                      UseAuth.logout();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default MerchantApplication;
