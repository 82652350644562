import Cookies from "js-cookie";
import EncryptDecrypt from "../../../services/encrypt-decrypt";
import Action from "../redux/Action";
import ChatRepository from "../../../repositories/ChatRepository";

let getToken = Cookies.get("tokenMerchant");
let token = getToken !== undefined ? EncryptDecrypt.dec(getToken) : null;

export let ws = null;

async function getCounNotifChat() {
    let response = await ChatRepository.getCountMessage();
    const countMessage = response.totalUnreadChat
    if (!response.success) {
        return countMessage;
    }
}

export const initializeWebSocket = async (dispatch, setCountUnreadChat) => {
    const url = process.env.REACT_APP_URL_WEBSOCKET + `/connect?token=${token}`;
    ws = new WebSocket(url);

    ws.onopen = () => {
        console.log('WebSocket connected');
    };

    ws.onmessage = async (evt) => {
        const message = JSON.parse(evt.data);
        const event = message.event;
        const selectedChatCookie = Cookies.get("selectedChatItem") ? EncryptDecrypt.dec(Cookies.get("selectedChatItem")) : null;
        const selectedChatItem = selectedChatCookie ? JSON.parse(selectedChatCookie) : null;
        const chatId = selectedChatItem ? selectedChatItem.id : null;

        // const min = 1;
        // const max = 100;
        // const count = Math.floor(Math.random() * (max - min + 1)) + min;
        // setCountUnreadChat(count)

        if (window.location.pathname !== "/chat") {
            if (event === "RECEIVE_NEW_MESSAGE_FROM_CUSTOMER" || event === "SUCCESS_SEND_MESSAGE_FROM_MERCHANT_TO_CUSTOMER") {
                const countNotif = await getCounNotifChat()
                setCountUnreadChat(countNotif);
            }
        } else {
            if (event === "RECEIVE_NEW_MESSAGE_FROM_CUSTOMER" || event === "SUCCESS_SEND_MESSAGE_FROM_MERCHANT_TO_CUSTOMER") {
                Action.remappingChatList(dispatch);
            }

            if (event === "RECEIVE_NEW_MESSAGE_FROM_CUSTOMER" && (selectedChatItem && chatId)) {
                Action.getDetailChat(dispatch, {chatId});
            }

            if (event === "SUCCESS_SEND_MESSAGE_FROM_MERCHANT_TO_CUSTOMER" && (selectedChatItem && chatId)) {
                Action.remappingChatListWithReadChat(dispatch, {chatId});
            }
        }
    };


    ws.onclose = () => {
        console.log('WebSocket disconnected');
    };
};
