function VarListModel({
  id,
  image,
  imageFilename,
  sizeId,
  size,
  color,
  colorId,
  stock,
  normalPrice,
  promotionalPrice,
  weight,
  status,
  main,
}) {
  let obj = {};
  obj.id = id;
  obj.image = image;
  obj.imageFilename = imageFilename;
  obj.sizeId = sizeId;
  obj.size = size;
  obj.color = color;
  obj.colorId = colorId;
  obj.stock = stock;
  obj.normalPrice = normalPrice;
  obj.promotionalPrice = promotionalPrice;
  obj.weight = weight;
  obj.status = status;
  obj.main = main;
  return obj;
}

export default VarListModel;
