import React, { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import Action from "./redux/Action";
import reducerSlice from "./redux/Reducer";
import { Provider, useDispatch, useSelector } from "react-redux";
import StoreHelper from "../../services/store-helper";
import AccountDetails from "./components/AccountDetails/AccountDetails";
import BankDetails from "./components/bankdetails/BankDetails";
import LegalDocuments from "./components/legaldocuments/LegalDocuments";
import ChangePassword from "./components/changepassword/ChangePassword";
import OperationalHour from "./components/operationalhour/OperationalHour";
import AutoReply from "./components/chat/AutoReply";

const Parent = ({ page }) => {
  if (page === "account_details") {
    return <AccountDetails />;
  } else if (page === "bank_details") {
    return <BankDetails />;
  } else if (page === "legal_documents") {
    return <LegalDocuments />;
  } else if (page === "operational_hour") {
    return <OperationalHour />;
  } else if (page === "auto_reply_chat") {
    return <AutoReply />;
  } else if (page === "change_password") {
    return <ChangePassword />;
  }
};

const MainComponent = ({ getBreadcrums, store }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  store.getState();
  const page = useSelector((state) => state.reducer.page);

  const nav = [
    { name: t("register.account_details"), route: "account_details" },
    { name: t("register.bank_details"), route: "bank_details" },
    { name: t("register.legal_documents"), route: "legal_documents" },
    { name: t("operationalhour.title"), route: "operational_hour" },
    { name: t("chats.auto_reply_chat"), route: "auto_reply_chat" },
    { name: t("register.change_password"), route: "change_password" },
  ];

  useEffect(() => {
    Action.getPage(dispatch, page);
    getBreadcrums(t("accountsettings.title"));
  }, []);

  return (
    <>
      <div className="flex flex-row items-center px-2 gap-x-2">
        <i className="mt-1.5">
          <img
            className="h-full w-10"
            src={process.env.REACT_APP_ASSETS_IMAGE + "back-icon.svg"}
            alt="back-icon"
          />
        </i>
        <h2 className="text-green-primary font-bold">
          {t("accountsettings.title")}
        </h2>
      </div>

      <div className="flex flex-row gap-x-3 pt-3">
        <div className="flex flex-col bg-gray-primary w-72 text-lg h-fit">
          {nav.map((items, index) => (
            <div
              className="cursor-pointer"
              onClick={() => {
                Action.getPage(dispatch, items.route);
              }}
              key={index}
            >
              <div
                className={`${page === items.route ? "font-semibold" : "bg-white "
                  } p-4 leading-tight flex flex-row`}
              >
                <div
                  className={`${page === items.route ? "pl-3" : ""
                    } flex-1 text-md`}
                >
                  {items.name}
                </div>
                <div className="flex text-right">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex-1 bg-gray-primary rounded-xl px-2 py-5">
          <Parent page={page} />
        </div>
      </div>
    </>
  );
};

const AccountSettings = ({ getBreadcrums }) => {
  const store = StoreHelper.generateStoreState(reducerSlice);

  return (
    <Provider store={store}>
      <MainComponent getBreadcrums={getBreadcrums} store={store} />
    </Provider>
  );
};

export default AccountSettings;
