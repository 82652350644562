import { useTranslation } from "react-multi-lang";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";

const HeaderOrder = ({
  onChangeSearch,
  onChangeSort,
  startDateFilter,
  endDateFilter,
  datePickerOnOpen,
  datePickerOnChange,
  datePickerOnClose,
}) => {
  const t = useTranslation();
  let filterTimeout;
  return (
    <div className="flex gap-x-3 pb-3">
      <label className="flex-1 min-w-fit relative block">
        <span className="absolute inset-y-0 left-1.5 flex items-center pl-2">
          <img
            className="h-3.5 w-5 fill-slate-300"
            src={process.env.REACT_APP_ASSETS_IMAGE + "username-icon.svg"}
            alt="username-icon"
          />
        </span>
        <input
          type="text"
          placeholder={t("orders.search_order")}
          className="pl-10 pr-3 w-full block sm:text-sm shadow-sm border border-gray-primary rounded-xl bg-white placeholder:text-slate-40 "
          onChange={(e) => {
            clearTimeout(filterTimeout);
            filterTimeout = setTimeout(() => {
              onChangeSearch(e.target.value);
            }, 500);
          }}
        />
      </label>

      <select
        className="sm:text-sm shadow-sm border border-gray-primary rounded-xl"
        name="postperview"
        id="postperview"
        onChange={(e) => {
          onChangeSort(e.target.value);
        }}
      >
        <option hidden>{t("general.sort_by")}</option>
        <option value="desc">{t("general.newest")}</option>
        <option value="asc">{t("general.oldest")}</option>
      </select>

      <DateRangePicker
        startDateValue={startDateFilter}
        endDateValue={endDateFilter}
        onOpen={() => {
          datePickerOnOpen();
        }}
        onChange={(dateObject) => {
          datePickerOnChange(dateObject);
        }}
        onClose={() => {
          datePickerOnClose();
        }}
        format="DD MMM YYYY"
        placeholder={t("buyback.date_range_placeholder")}
        inputClass="block sm:text-sm shadow-sm border border-gray-primary rounded-xl bg-white  placeholder:text-slate-40 cursor-pointer"
      />
    </div>
  );
};

export default HeaderOrder;
