import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "autoreply",
  initialState: {
    message: "",
    isLoadingAutoReply: true,
    originalMessage: "",
  },
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload
    },
    setIsloadingAutoReply: (state, action) => {
      state.isLoadingAutoReply = action.payload
    },
    setOriginalMessage: (state, action) => {
      state.originalMessage = action.payload
    }
  },
});

export const {
  setMessage,
  setIsloadingAutoReply,
  setOriginalMessage,
} = reducerSlice.actions;

export default reducerSlice;
