import React from "react";
import { useTranslation } from "react-multi-lang";

const EmptyProduct = () => {
  const t = useTranslation();
  return (
    <div className="w-full section-white text-center p-3">
      <h4 className="font-extrabold text-xl">{t("products.empty_title")}</h4>
      <p className="text-lg">{t("products.empty_subtitle")}</p>
      <img
        className="w-full max-h-[15rem] mt-3"
        src={process.env.REACT_APP_ASSETS_IMAGE + "product-empty.svg"}
        alt="empty-order"
      />
    </div>
  );
};

export default EmptyProduct;
