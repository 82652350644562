import React from "react";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";

const Status = ({ statusNow }) => {
  const t = useTranslation();
  const nav = [
    {
      id: 1,
      title: t("merchantapp.submitted"),
      status: statusNow === 2 ? true : false,
    },
    {
      id: 2,
      title: t("merchantapp.reviewed"),
      status: statusNow === 3 ? true : false,
    },
    {
      id: 3,
      title: t("merchantapp.approved"),
      status: statusNow === 4 ? true : false,
    },
  ];
  return (
    <div className="mx-4 p-4">
      <div className="flex items-center">
        {nav.map((items) => (
          <>
            <div
              className={`flex items-center text-white relative ${
                items.status === true ? "" : "opacity-20"
              } `}
            >
              <div className="rounded-full transition duration-500 ease-in-out h-12 w-20 py-3 border-0">
                <div className="w-full">
                  {items.status === true ? (
                    <span className="flex items-center justify-center h-5 w-5 bg-gray-primary rounded-full text-black font-bold mx-auto">
                      <img
                        src={process.env.REACT_APP_ASSETS_IMAGE + "ceklis.svg"}
                        alt="done"
                      />
                    </span>
                  ) : (
                    <span className="flex items-center justify-center h-5 w-5 bg-gray-primary rounded-full text-black font-bold mx-auto">
                      {items.id}
                    </span>
                  )}
                </div>
              </div>
              <div className="absolute top-0 -ml-6 text-center mt-10 w-32 text-sm font-regular text-white">
                {items.title}
              </div>
            </div>
            {items.id !== 3 ? (
              <div
                className={`flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300 ${
                  items.status === true ? "" : "opacity-40"
                }`}
              ></div>
            ) : (
              ""
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default Status;
