import ComplaintRepository from "../../../repositories/ComplaintRepository";
import DateUtility from "../../../utils/date-utility";
import PopupUtility from "../../../utils/popup-utility";
import {
    setCurrentPage,
    setList,
    setLoading,
    setPostPerPage,
    setTotalData,
    setTotalPage,
    setSearch,
    setStatusFilter,
    setFieldName,
    setOrderBy,
    setShowDetail,
    setDetailComplaint,
    setModalDeclined,
    setModalResolve,
    setStartDateFilter,
    setEndDateFilter,
} from "./Reducer";

const getList = async (
    dispatch, {
        currentPage,
        postPerPage,
        search,
        statusFilter,
        fieldName,
        orderBy,
        startDateFilter,
        endDateFilter
    }) => {
    dispatch(setLoading(true));
    dispatch(setCurrentPage(currentPage));
    dispatch(setPostPerPage(postPerPage));
    dispatch(setSearch(search));
    dispatch(setStatusFilter(statusFilter));
    dispatch(setFieldName(fieldName));
    dispatch(setOrderBy(orderBy));
    dispatch(setStartDateFilter(startDateFilter));
    dispatch(setEndDateFilter(endDateFilter));

    const data = {
        page: currentPage,
        search: search || "",
        status: statusFilter || "",
        size: postPerPage || 5,
        fieldName: fieldName || "",
        orderBy: orderBy || "",
        startDate: startDateFilter ? DateUtility.dateFormatApi(startDateFilter) : "",
        endDate: endDateFilter ? DateUtility.dateFormatApi(endDateFilter) : ""
    };

    let response = await ComplaintRepository.getListData(data);

    if (response.error === false) {
        dispatch(setLoading(false));
        dispatch(setList(response.data));
        dispatch(setTotalData(response.total_data));
        dispatch(setTotalPage(response.total_pages));
    } else {
        dispatch(setLoading(true));
        dispatch(setList([]));
        dispatch(setTotalData(0));
        dispatch(setTotalPage(0));
    }

    dispatch(setShowDetail(false));
    dispatch(setDetailComplaint({}));
};

const changeShowDetail = async (dispatch, showDetail, id) => {
    if (id) {
        const response = await ComplaintRepository.getDetail({ id: id });
        const resDetail = response.data.data;

        if (response.error === false) {
            dispatch(setDetailComplaint(resDetail));
        } else {
            dispatch(setDetailComplaint({}));
        }
    } else {
        dispatch(setDetailComplaint({}));
    }
    dispatch(setShowDetail(showDetail));
};

const handleDeclinedSubmit = async (dispatch, data) => {
    const response = await ComplaintRepository.changeStatus(data, "decline");

    if (response.error === false) {
        PopupUtility.success("Complaint has been declined");
        Action.getList(dispatch, {
            currentPage: 0,
            postPerPage: 5,
            search: "",
            statusFilter: "",
            fieldName: "",
            orderBy: ""
        });
    } else {
        PopupUtility.responseValidate(response.message, response.error_message[0]);
    }

    dispatch(setShowDetail(false));
    dispatch(setModalDeclined(false));
};

const handelResolveSubmit = async (dispatch, data = {}) => {
    const response = await ComplaintRepository.changeStatus(data, "resolve");
    if (response.error === false) {
        PopupUtility.success("Complaint has been resolved");
        Action.getList(dispatch, {
            currentPage: 0,
            postPerPage: 5,
            search: "",
            statusFilter: "",
            fieldName: "",
            orderBy: ""
        });
    } else {
        PopupUtility.responseValidate(response.message, response.error_message[0]);
    }

    dispatch(setShowDetail(false));
    dispatch(setModalResolve(false));
};

const Action = {
    getList,
    changeShowDetail,
    handleDeclinedSubmit,
    handelResolveSubmit
};

export default Action;