/* eslint-disable jsx-a11y/img-redundant-alt */

import React from "react";

const NotFound = () => {
  return (
    <div className="h-screen w-screen bg-white items-center text-center">
      The page you request was not found
    </div>
  );
};

export default NotFound;
