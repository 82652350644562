import Cookies from "js-cookie";
import EncryptDecrypt from "../services/encrypt-decrypt";

const getToken = async () => {
  let getToken = Cookies.get("tokenMerchant");
  let token = getToken !== undefined ? EncryptDecrypt.dec(getToken) : null;
  return token;
};

const getRefreshToken = async () => {
  let getToken = Cookies.get("refreshTokenMerchant");
  let refreshToken =
    getToken !== undefined ? EncryptDecrypt.dec(getToken) : null;
  return refreshToken;
};

const getLanguage = async () => {
  let language = Cookies.get("language") ?? "EN";
  return language.toUpperCase();
};

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
const sanitizeNumericInput = (num) =>
  num.toString().replace(/[^0-9.]|\.(?=.*\.)/g, "");

const addSeparator = (num) => {
  if (num !== "" || num !== null || num !== undefined) {
    return addCommas(removeNonNumeric(num));
  }
};

const decimalToInt = (num) => {
  if (num) {
    return parseFloat(num.replace(/,/g, ""));
  } else {
    return 0;
  }
};

const dataURLtoFile = (dataurl, filename) => {
  if (dataurl) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let file = new File([u8arr], filename, { type: mime });
    console.log(file.size / 1000 + " kb");
    console.log(file);
    return file;
  }
};

const npwpFormat = (npwpNumber) => {
  const cleanedNumber = npwpNumber.replace(/\D/g, "");
  let formattedNPWP = "";
  for (let i = 0; i < cleanedNumber.length; i++) {
    if (i === 2 || i === 6 || i === 10) {
      formattedNPWP += ".";
    } else if (i === 12) {
      formattedNPWP += "-";
    }
    formattedNPWP += cleanedNumber.charAt(i);
  }
  return formattedNPWP;
};

const npwpFromInt = (npwpNumber) => {
  let formattedNPWP = "";
  if (npwpNumber) {
    for (let i = 0; i < npwpNumber.length; i++) {
      if (i === 2 || i === 6 || i === 10) {
        formattedNPWP += ".";
      } else if (i === 12) {
        formattedNPWP += "-";
      }
      formattedNPWP += npwpNumber.charAt(i);
    }
  }
  return formattedNPWP;
};

const arrayToString = (arr) => {
  let resultString = arr.join('');

  return resultString;
};

const GeneralUtility = {
  getToken,
  getRefreshToken,
  getLanguage,
  addSeparator,
  decimalToInt,
  dataURLtoFile,
  addCommas,
  removeNonNumeric,
  npwpFormat,
  npwpFromInt,
  sanitizeNumericInput,
  arrayToString
};

export default GeneralUtility;
