import React from "react";

const FormInputRadio = ({
  title,
  subtitle,
  value,
  onChange,
  category,
  type,
}) => {
  return (
    <div className="flex flex-row gap-x-4">
      <div className="w-72">
        <div className="font-bold">
          {title}
          <span className="text-gold-secondary">*</span>
        </div>

        <div className="pt-3 w-60">{subtitle}</div>
      </div>
      <div className="flex-1">
        <div className="flex flex-row items-center gap-x-5">
          {category.map((item, index) => (
            <div className="flex items-center" key={index}>
              <input
                checked={value === item.id ? true : false}
                onChange={(e) => {
                  if (type === "add") {
                    onChange(e.target.value);
                  }
                }}
                id={item.name}
                type="radio"
                value={item.id}
                name="productCategoryId"
                className="w-4 h-4 text-gold-secondary bg-gray-100 border-gray-300 focus:ring-gray-primary"
                disabled={type === "edit" ? true : false}
              />
              <label
                htmlFor={item.name}
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                {item.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FormInputRadio;
