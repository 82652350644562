import Cookies from "js-cookie";
import AccountRepository from "../../../repositories/AccountRepository";
import BalanceRepository from "../../../repositories/BalanceRepository";
import DateUtility from "../../../utils/date-utility";
import PopupUtility from "../../../utils/popup-utility";
import BalanceModel from "../models/BalanceModel";
import { setCurrentStatus, setData, setList, setPopup } from "./Reducer";
import EncryptDecrypt from "../../../services/encrypt-decrypt";
import AuthRepository from "../../../repositories/AuthRepository";
import GeneralUtility from "../../../utils/general-utility";

const getBalance = async (dispatch) => {
  let response = await BalanceRepository.getBalance();
  let response2 = await AccountRepository.getBankDetails();
  if (!response.error) {
    let res = response.data;
    let res2 = response2;
    let data = new BalanceModel({
      totalPrice: res.totalPrice,
      price: res.price,
      shippingPrice: res.shippingPrice,
      shippingInsurance: res.shippingInsurance,
      serviceFee: res.serviceFee,
      dendaCancel: res.dendaCancel,
      biayaAdminWd: res.biayaAdminWd,
      bankDetail: res2.bank.name + " - " + res2.bankNumber,
      name: res2.bankName,
      commisionFee: res.commisionFee,
      dendaServiceFeeCancel: res.dendaServiceFeeCancel,
      priceTotal: res.priceTotal,
      transServiceFee: res.transServiceFee,
      heldBalance: GeneralUtility.addCommas(res.heldBalance || 0)
    });

    Cookies.set("balanceMerchant", EncryptDecrypt.enc(res.price));
    dispatch(setData(data));
  }
};

const getHistory = async (dispatch, { startDateFilter, endDateFilter }) => {
  const formatApiDate = (date) => date ? DateUtility.dateFormatApi(date) : "";

  const payload = {
    startDate: formatApiDate(startDateFilter),
    endDate: formatApiDate(endDateFilter),
  };

  let response = await BalanceRepository.getBalanceHistory(payload);

  if (!response.error) {
    dispatch(setList(response.data));
  }
};

const postWd = async ({
  dispatch,
  data,
}) => {
  let payload = { ...data };
  payload.commission = data.commisionFee;
  delete payload.bankDetail;
  delete payload.name;
  delete payload.totalPrice;
  delete payload.commisionFee;
  let response = await BalanceRepository.postWd(payload);
  dispatch(setPopup(false));
  if (!response.error) {
    getHistory(dispatch, { startDateFilter: "", endDateFilter: "" });
    getBalance(dispatch);
    PopupUtility.success("Success");
  } else {
    PopupUtility.responseValidate("Failed", response.error_message);
  }
};

const getCheckedMerchant = async (dispatch) => {
  let response = await AuthRepository.checkStatusMerchant();
  const status = response.data.data?.status;

  if (!response.error && status === -1) {
    dispatch(setCurrentStatus(status));
  }
};

const Action = {
  getBalance,
  getHistory,
  postWd,
  getCheckedMerchant
};

export default Action;
