import React from "react";

const InputText = ({
  placeholder,
  name,
  value,
  error,
  onChange,
  maxLength,
  height,
  disabled,
  onFocus
}) => {
  return (
    <>
      <input
        type="text"
        name={name}
        onChange={(e) => {
          onChange(e);
        }}
        placeholder={placeholder}
        className={`rounded-xl ${height} w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${error ? "border-red-500 border-1" : "border-0"
          }`}
        value={value}
        maxLength={maxLength}
        disabled={disabled}
        onFocus={(e) => {
          if (onFocus) {
            onFocus(e);
          }
        }}
      />
      <p className="text-red-500">{error}</p>
    </>
  );
};

export default InputText;
