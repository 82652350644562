import React from 'react';
import { useTranslation } from 'react-multi-lang';

const TicketWithdrawalHistory = ({ item }) => {
    const t = useTranslation();
    let statusName;
    let statusColor;
    let statusTextColor;
    switch (item.status) {
        case "COMPLETED":
            statusName = t("general.completed");
            statusColor = "bg-[#A7FFA1]";
            statusTextColor = "text-[#047100]";
            break;
        case "CANCELED":
            statusName = t("general.cancelled");
            statusColor = "bg-[#FF9191]";
            statusTextColor = "text-white";
            break;
        case "PENDING":
            statusName = t("general.pending");
            statusColor = "bg-[#FCB769]";
            statusTextColor = "text-white";
            break;
        default:
            statusName = "";
            statusColor = "";
            statusTextColor = "";
            break;
    }
    return (
        <div className="bg-white rounded-xl m-2">
            <div className="sm:text-sm flex flex-row p-5">
                <div className="flex-1">{item.titleTicket}</div>
                <div>
                    <span className={`px-2 py-1 ${statusColor} ${statusTextColor} rounded-md`}>
                        {statusName}
                    </span>
                </div>
            </div>
            <hr />
            {/* header */}
            <div className="p-5">
                <div className="text-md font-bold">
                    {t("balance.withdrawal")}
                </div>
            </div>
            <hr />
            {/* body */}
            <div className="p-5">
                <div className='mb-3'>
                    <p className='font-bold'>{t("balance.title")}</p>
                    <span className="text-green-600 font-medium">
                        Rp{item.balance}
                    </span>
                </div>
                <div>
                    <p className='font-bold'>{t("balance.withdrawal_fee")}</p>
                    <span className="text-red-600 font-medium">
                        -Rp{item.withdrawalFee}
                    </span>
                </div>
            </div>
            {/* footer */}
            <div className="flex px-5 pb-5">
                <div>{t("balance.total_amount")}</div>
                <div className="flex-1 text-right text-md font-bold">
                    Rp{item.totalAmountWithdraw}
                </div>
            </div>
        </div>
    );
};

export default TicketWithdrawalHistory;