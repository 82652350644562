import Cookies from "js-cookie";
import EncryptDecrypt from "../../../services/encrypt-decrypt";
import Action from "../redux/Action";

let getToken = Cookies.get("tokenMerchant");
let token = getToken !== undefined ? EncryptDecrypt.dec(getToken) : null;

export let ws = null;

export const initializeWebSocket = (dispatch) => {
    ws = new WebSocket(`ws://18.143.243.84:8085/connect?token=${token}`);

    ws.onopen = () => {
        console.log('WebSocket connected');
    };

    ws.onmessage = (evt) => {
        const message = JSON.parse(evt.data);
        const event = message.event;
        const selectedChatCookie = Cookies.get("selectedChatItem") ? EncryptDecrypt.dec(Cookies.get("selectedChatItem")) : null;
        const selectedChatItem = selectedChatCookie ? JSON.parse(selectedChatCookie) : null;
        const chatId = selectedChatItem ? selectedChatItem.id : null;

        if (event === "RECEIVE_NEW_MESSAGE_FROM_CUSTOMER" || event === "SUCCESS_SEND_MESSAGE_FROM_MERCHANT_TO_CUSTOMER") {
            Action.remappingChatList(dispatch);
        }

        if (event === "RECEIVE_NEW_MESSAGE_FROM_CUSTOMER" && (selectedChatItem && chatId)) {
            Action.getDetailChat(dispatch, {chatId});
        }

        if (event === "SUCCESS_SEND_MESSAGE_FROM_MERCHANT_TO_CUSTOMER" && (selectedChatItem && chatId)) {
            Action.remappingChatListWithReadChat(dispatch, {chatId});
        }
    };


    ws.onclose = () => {
        console.log('WebSocket disconnected');
    };
};
