import TakeDownLabel from "../../TakeDownLabel";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Price from "../../Price";
import PromoPrice from "../../PromoPrice";
import Status from "../../Status";
import Stock from "../../Stock";
import Dropdown from "../../Dropdown";
import {setId, setPopup} from "../redux/Reducer";

const ProductList = ({item, index, search, Action, onEdit}) => {

    const dispatch = useDispatch();
    const image = process.env.REACT_APP_ASSETS_IMAGE;
    const orderBy = useSelector((state) => state.reducer.orderBy);
    const fieldName = useSelector((state) => state.reducer.fieldName);
    const [showVariant, setshowVariant] = useState(false);

    const activedVariant = item?.variant?.find(obj => obj.main === true);

    return (
        <>
            <tr
                key={index}
                className="bg-white border-4 border-gray-primary">
                <td className="p-2 my-2">
                    <div className="flex flex-row gap-x-2 items-center">
                        <div className="w-[4.5em] h-[4.5em] border-2 border:bg-gray-primary rounded-xl">
                            <img
                                className="w-full h-full rounded-xl"
                                src={activedVariant ? activedVariant.image : item.image}
                                alt={item.alt}
                            />
                        </div>
                        <div>
                            <div className="font-semibold">
                                {item.productName}
                            </div>
                            <div className="flex flex-row gap-x-1 items-center">
                                <div className="bg-gold-secondary text-white px-2 rounded-full">
                                    {item.type}
                                </div>
                                <img
                                    className="h-6 w-6"
                                    src={image + "eye-show.svg"}
                                    alt="seen"
                                />
                                <p className="text-xs pr-3">{item.viewCount}</p>
                                <img
                                    className="h-3 w-3"
                                    src={image + "cart-icon.svg"}
                                    alt="cart"
                                />
                                <p className="text-xs">{item.salesCount}</p>
                            </div>
                            <div className=" text-green-quaternary text-sm">
                                SKU : {activedVariant ? activedVariant.sku : item.sku}
                            </div>
                            {item.status === -2 ? <TakeDownLabel/> : null}
                        </div>
                    </div>

                    {item.variant === null ? (
                        <> </>
                    ) : (
                        <button
                            className="pl-16 text-gold-secondary mt-3"
                            onClick={() => {
                                setshowVariant(!showVariant);
                            }}
                        >
                            {!showVariant ? (
                                <div className="inline-flex items-center space-x-3 text-gold-secondary">
                                    <p>More variant products</p>
                                    <svg
                                        className="duration-500"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10.219"
                                        height="6.17"
                                        viewBox="0 0 10.219 6.17"
                                    >
                                        <g id="Arrow" transform="translate(1.061 1.061)">
                                            <line
                                                id="Line_9"
                                                data-name="Line 9"
                                                x1="4.049"
                                                y1="4.049"
                                                transform="translate(8.097) rotate(90)"
                                                fill="none"
                                                stroke="#BBAB7F"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.5"
                                            />
                                            <line
                                                id="Line_10"
                                                data-name="Line 10"
                                                x1="4.049"
                                                y2="4.049"
                                                transform="translate(4.049) rotate(90)"
                                                fill="none"
                                                stroke="#BBAB7F"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.5"
                                            />
                                        </g>
                                    </svg>
                                </div>
                            ) : (
                                <div className="inline-flex items-center space-x-3 text-gold-secondary">
                                    <p> Hide variant products</p>
                                    <svg
                                        className="rotate-180 duration-500 mb-1px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10.219"
                                        height="6.17"
                                        viewBox="0 0 10.219 6.17"
                                    >
                                        <g id="Arrow" transform="translate(1.061 1.061)">
                                            <line
                                                id="Line_9"
                                                data-name="Line 9"
                                                x1="4.049"
                                                y1="4.049"
                                                transform="translate(8.097) rotate(90)"
                                                fill="none"
                                                stroke="#BBAB7F"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.5"
                                            />
                                            <line
                                                id="Line_10"
                                                data-name="Line 10"
                                                x1="4.049"
                                                y2="4.049"
                                                transform="translate(4.049) rotate(90)"
                                                fill="none"
                                                stroke="#BBAB7F"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.5"
                                            />
                                        </g>
                                    </svg>
                                </div>
                            )}
                        </button>
                    )}
                </td>
                <Price
                    price={activedVariant ? activedVariant.price : item.price}
                    promoPrice={activedVariant ? activedVariant.promoPrice : item.promoPrice}
                    changePrice={(value) => {
                        Action.changeValue(
                            dispatch,
                            value,
                            item.id,
                            search,
                            1,
                            fieldName,
                            orderBy
                        );
                    }}
                    status={item.status === -2 || item.variant}
                />
                <PromoPrice
                    productPrice={activedVariant ? activedVariant.price : item.price}
                    promoPrice={activedVariant ? activedVariant.promoPrice : item.promoPrice}
                    changePromoPrice={(value) => {
                        Action.changeValue(
                            dispatch,
                            value,
                            item.id,
                            search,
                            2,
                            fieldName,
                            orderBy
                        );
                    }}
                    status={item.status === -2 || item.variant}
                />
                <Stock
                    stock={activedVariant ? activedVariant.stock : item.stock}
                    changeStock={(value) => {
                        Action.changeValue(
                            dispatch,
                            value,
                            item.id,
                            search,
                            3,
                            fieldName,
                            orderBy
                        );
                    }}
                    status={item.status === -2 || item.variant}
                />
                <td>
                    <div className="flex flex-row gap-x-20 items-center">
                        <Status
                            status={item.status}
                            changeStatus={(value) => {
                                Action.changeValue(
                                    dispatch,
                                    value,
                                    item.id,
                                    search,
                                    4,
                                    fieldName,
                                    orderBy
                                );
                            }}
                            statusProduct={item.status === -2}
                            type="product"
                        />
                        <Dropdown
                            status={item.status === -2}
                            onEdit={() => {
                                onEdit(item.id, item.categoryId);
                            }}
                            onCopyLink={() => {
                                Action.copyLink(item)
                            }}
                            onDuplicate={() => {
                                Action.onDuplicate(
                                    dispatch,
                                    item.id,
                                    search,
                                    fieldName,
                                    orderBy
                                );
                            }}
                            onDelete={() => {
                                dispatch(setPopup(true));
                                dispatch(
                                    setId({
                                        id: item.id,
                                        name: item.productName,
                                    })
                                );
                            }}
                        />
                    </div>
                </td>
            </tr>
            {/* JIKA PRODUK PUNYA VARIANT */}
            {item.variant === null ? (
                <></>
            ) : (
                <>
                    {!showVariant ? (
                        <> </>
                    ) : (
                        <>
                            {item.variant.map((vars, index) => (
                                <tr key={index} className="bg-white">
                                    <td className="p-2 my-2 pl-16">
                                        <div className="flex flex-row gap-x-2 items-center">
                                            <div
                                                className="w-[4.5em] h-[4.5em] border-2 border:bg-gray-primary rounded-xl">
                                                <img
                                                    className="w-full h-full rounded-xl"
                                                    src={vars.image ? vars.image : item.image}
                                                    alt={vars.alt}
                                                />
                                            </div>
                                            <div>
                                                <div className="font-medium">
                                                    {vars.size}
                                                    {vars.size && vars.color ? " , " : ""}
                                                    {vars.color}
                                                </div>
                                                <div className="flex flex-row gap-x-1 items-center">
                                                    SKU : {vars.sku}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <Price
                                        price={vars.price}
                                        promoPrice={vars.promoPrice}
                                        changePrice={(value) => {
                                            Action.changeVariant(
                                                dispatch,
                                                value,
                                                vars.id,
                                                search,
                                                1,
                                                fieldName,
                                                orderBy
                                            );
                                        }}
                                        status={item.status === -2}
                                    />
                                    <PromoPrice
                                        productPrice={vars.price}
                                        promoPrice={vars.promoPrice}
                                        changePromoPrice={(value) => {
                                            Action.changeVariant(
                                                dispatch,
                                                value,
                                                vars.id,
                                                search,
                                                2,
                                                fieldName,
                                                orderBy
                                            );
                                        }}
                                        status={item.status === -2}
                                    />
                                    <Stock
                                        stock={vars.stock}
                                        changeStock={(value) => {
                                            Action.changeVariant(
                                                dispatch,
                                                value,
                                                vars.id,
                                                search,
                                                3,
                                                fieldName,
                                                orderBy
                                            );
                                        }}
                                        status={item.status === -2}
                                    />
                                    <td>
                                        <div className="flex flex-row gap-x-20 items-center">
                                            {vars.main ? (
                                                <div className="w-11"></div>
                                            ) : (
                                                <Status
                                                    status={vars.active}
                                                    changeStatus={(value) => {
                                                        Action.changeVariant(
                                                            dispatch,
                                                            value,
                                                            vars.id,
                                                            search,
                                                            4,
                                                            fieldName,
                                                            orderBy
                                                        );
                                                    }}
                                                    statusProduct={item.status === -2}
                                                    type="variant"
                                                />
                                            )}
                                            <div className="text-left w-36">
                                                {vars.main ? <>Main</> : <></>}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default ProductList;
