function ProductModel({
  id,
  productName,
  viewCount,
  salesCount,
  status,
  price,
  promoPrice,
  stock,
  image,
  alt,
  type,
  takenDown,
  variant,
  categoryId,
  sku,
}) {
  let obj = {};
  obj.id = id;
  obj.productName = productName;
  obj.viewCount = viewCount;
  obj.salesCount = salesCount;
  obj.status = status;
  obj.price = price;
  obj.promoPrice = promoPrice;
  obj.stock = stock;
  obj.image = image;
  obj.alt = alt;
  obj.type = type;
  obj.takenDown = takenDown;
  obj.variant = variant;
  obj.categoryId = categoryId;
  obj.sku = sku;
  return obj;
}

export default ProductModel;
