import React from "react";
import { useTranslation } from "react-multi-lang";
import ButtonGold from "../../components/ButtonGold/ButtonGold";
import UseAuth from "../auth/Auth";

const MerchantReject = () => {
  const t = useTranslation();
  return (
    <div className="h-screen w-screen overflow-hidden">
      <div className="pl-16 py-8">
        <img
          className="h-14"
          src={process.env.REACT_APP_ASSETS_IMAGE + "te-logo.svg"}
          alt="Tanam Emas Logo"
        />
      </div>
      <div className="text-center px-10">
        <div>
          <h1 className="text-gold-primary font-bold tracking-tight leading-tight">
            {t("merchantreject.title")}
          </h1>

          <div className="text-lg text-gray-secondary tracking-tight pt-5">
            {t("merchantreject.subtitle")}
          </div>

          <div className="pt-5">
            <div>
              <ButtonGold
                title={t("merchantreject.back")}
                onClick={() => {
                  UseAuth.logout();
                }}
              />
            </div>
          </div>

          <div className="pt-10">
            <img
              className="w-full h-[30em]"
              src={
                process.env.REACT_APP_ASSETS_IMAGE +
                "Ilustrasi-mechantreject.svg"
              }
              alt="Tanam Emas Merchant Reject"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantReject;
