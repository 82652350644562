import React from 'react';
import Action from '../redux/Action';
import {useDispatch, useSelector} from 'react-redux';

const ChatBoxMessage = ({refChat}) => {
    const dispatch = useDispatch();
    const chatRoom = useSelector(state => state.reducer.selectedItem);
    const error = useSelector(state => state.reducer.error);
    return (
        <div
            ref={refChat}
            className={`flex flex-col overflow-y-auto p-2 space-y-4 ${error.isError ? "h-[calc(100%-200px)]" : "h-[calc(100%-150px)]"}`}>
            {chatRoom.chat.map((item, index) => {
                return (
                    <React.Fragment key={`${index}-${item.date}`}>
                        <p className="flex items-center justify-center text-xs">{item.date}</p>
                        {item.messages.map((chat, index2) => {
                            return (
                                <React.Fragment key={`${index}-${index2}-${chat.messageId}`}>
                                    {/* receiver : chat text */}
                                    {!chat.isSender && chat.type === "TEXT" ?
                                        (<div
                                            className={`flex self-start mb-5 bg-gray-200 max-w-[75%] rounded-2xl w-fit p-2 ${chat.time.length > 56 ? "" : "gap-3"} whitespace-pre-wrap break-words break-all`}>
                                            <div className="">{chat.content}</div>
                                            <div className="self-end text-[8px] whitespace-nowrap">{chat.time}</div>
                                        </div>) : ""}

                                    {/* receiver : chat image */}
                                    {!chat.isSender && chat.type === "IMAGE" ?
                                        (<div className="flex self-start mb-5 bg-gray-200 max-w-[75%] rounded-2xl p-2">
                                            <div className="relative">
                                                <img
                                                    src={chat.content}
                                                    alt="chat-Img"
                                                    className="rounded-lg w-[250px] h-auto object-cover cursor-pointer"
                                                    onClick={() => {
                                                        Action.changeModalImage(dispatch, {
                                                            showModal: true,
                                                            title: "chat-Img",
                                                            img: chat.content
                                                        });
                                                    }}
                                                />
                                                <div
                                                    className="absolute bottom-1 right-2 text-[8px] text-grey-button bg-opacity-70 px-1 rounded">
                                                    {chat.time}
                                                </div>
                                            </div>
                                        </div>) : ""}

                                    {/* receiver : chat video */}
                                    {!chat.isSender && chat.type === "VIDEO" ?
                                        (<div className="flex self-start mb-5 bg-gray-200 max-w-[75%] rounded-2xl p-2">
                                            <div className="relative">
                                                <video
                                                    src={chat.content}
                                                    controls
                                                    className="rounded-lg w-[250px] h-auto object-contain"
                                                />
                                                <div
                                                    className="absolute bottom-1 right-2 text-[8px] text-grey-button bg-opacity-70 px-1 rounded">
                                                    {chat.time}
                                                </div>
                                            </div>
                                        </div>) : ""}

                                    {/* sender : chat text */}
                                    {chat.isSender && chat.type === "TEXT" ?
                                        (<div
                                            className={`flex self-end mb-5 bg-green-chat-own max-w-[75%] rounded-2xl w-fit p-2 ${chat.content.length > 56 ? "" : "gap-3"} whitespace-pre-wrap break-words break-all`}>
                                            <div className="self-auto">{chat.content}</div>
                                            <div className="self-end text-[8px] whitespace-nowrap">{chat.time}</div>
                                        </div>) : ""}

                                    {/* sender : chat image */}
                                    {chat.isSender && chat.type === "IMAGE" ?
                                        (<div
                                            className="flex self-end mb-5 bg-green-chat-own max-w-[75%] rounded-2xl p-2">
                                            <div className="relative">
                                                <img
                                                    src={chat.content}
                                                    alt="chat-Img"
                                                    className="rounded-lg w-[250px] h-auto object-cover cursor-pointer"
                                                    onClick={() => {
                                                        Action.changeModalImage(dispatch, {
                                                            showModal: true,
                                                            title: "chat-Img",
                                                            img: chat.content
                                                        });
                                                    }}
                                                />
                                                <div
                                                    className="absolute bottom-1 right-2 text-[8px] text-grey-button bg-opacity-70 px-1 rounded">
                                                    {chat.time}
                                                </div>
                                            </div>
                                        </div>) : ""}

                                    {/* sender : chat video */}
                                    {chat.isSender && chat.type === "VIDEO" ?
                                        (<div
                                            className="flex self-end mb-5 bg-green-chat-own max-w-[75%] rounded-2xl p-2">
                                            <div className="relative">
                                                <video
                                                    src={chat.content}
                                                    controls
                                                    className="rounded-lg w-[250px] h-auto object-contain"
                                                />
                                                <div
                                                    className="absolute bottom-1 right-2 text-[8px] text-grey-button bg-opacity-70 px-1 rounded">
                                                    {chat.time}
                                                </div>
                                            </div>
                                        </div>) : ""}
                                </React.Fragment>
                            );
                        })}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default ChatBoxMessage;