import React from "react";
import HolidayDate from "./holidayDate/HolidayDate";
import OperationalMain from "./operationalMain/OperationalMain";

const OperationalHour = () => {
  return (
    <div className="md:h-[43em] px-3 overflow-y-auto">
      <OperationalMain />
      <hr />
      <HolidayDate />
    </div>
  );
};

export default OperationalHour;
