import { useTranslation } from "react-multi-lang";
import GeneralUtility from "../../../../utils/general-utility";

const OrderInfo = ({ productList, shipping }) => {
  const t = useTranslation();
  let productPrice = 0;
  for (let i = 0; i < productList.length; i++) {
    const element = productList[i];
    productPrice += element.price * element.quantity;
  }
  return (
    <div className="absolute bg-white rounded-lg drop-shadow-md ml-2 left-full min-w-max ">
      <div className="p-1">
        <div className="flex">
          <p className="flex-1 mr-3">
            {t("orders.total_price_item", { params: productList.length })}{" "}
          </p>
          <p className="font-bold">
            Rp {GeneralUtility.addCommas(productPrice)}
          </p>
        </div>
        <div className="flex">
          <p className="flex-1 mr-3">{t("orders.total_shipping_insurance_cost")} </p>
          <p className="font-bold">
            Rp {GeneralUtility.addSeparator(shipping)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderInfo;
