import React, { useState } from "react";
import GeneralUtility from "../../../../utils/general-utility";

const PromoPriceVariant = ({ promoPrice, changePromoPrice }) => {
  let filterTimeout;
  const [data, setData] = useState(GeneralUtility.addCommas(promoPrice));

  return (
    <td className="font-semibold">
      <label className="flex-1 min-w-fit relative block">
        <span className="absolute inset-y-0 left-1.5 flex items-center pl-2">
          <p>Rp</p>
        </span>
        <input
          type="text"
          name="promoPrice"
          value={data}
          onChange={(e) => {
            setData(GeneralUtility.addSeparator(e.target.value));
            clearTimeout(filterTimeout);
            filterTimeout = setTimeout(() => {
              if (e.target.value !== "") {
                changePromoPrice(e.target.value);
              } else {
                changePromoPrice(0);
              }
            }, 2000);
          }}
          onFocus={(e) => {
            e.target.select();
          }}
          className="pl-10 pr-3 text-right w-full block text-sm shadow-sm border border-gray-primary rounded-xl bg-gray-primary"
        />
      </label>
    </td>
  );
};

export default PromoPriceVariant;
