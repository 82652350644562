import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import ButtonGreen from "../../../../../../components/ButtonGreen/ButtonGreen";
import ButtonWhite from "../../../../../../components/ButtonWhite/ButtonWhite";
import DatePickerComponent from "../../../../../../components/DatePickerComponent/DatePickerComponent";
import Action from "../redux/Action";
import { setEvent, setShow } from "../redux/Reducer";
import DateUtility from "../../../../../../utils/date-utility";
import PopupConfirmation from "../../../../../../components/PopupConfirmation/PopupConfirmation";
import PopupUtility from "../../../../../../utils/popup-utility";

const HolidayForm = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const event = useSelector((state) => state.reducer.event);
  const show = useSelector((state) => state.reducer.show);
  let startDate = new Date(event.startDate);
  let endDate = new Date(event.endDate);
  return (
    <div className="grid grid-rows-3 gap-y-3">
      <div className="flex">
        <div className="flex basis-1/2 items-center">
          <div className="basis-1/6 mr-5 font-semibold text-md">
            {t("holidaydate.start_date")}
          </div>
          <DatePickerComponent
            value={startDate}
            format="MMM DD, YYYY"
            onChange={(dateObject) => {
              let dat = { ...event };
              let startDate = new Date(dateObject);
              dat.startDate = DateUtility.dateFormatApi(startDate);
              dispatch(setEvent(dat));
            }}
            minDate={new Date()}
            placeholder={t("holidaydate.select_date_placeholder")}
            inputClass="block sm:text-sm shadow-sm border border-gray-primary rounded-xl bg-white placeholder:text-slate-40 cursor-pointer"
          />
        </div>
        <div className="flex basis-1/2 items-center">
          <div className="basis-1/6 mr-5 font-semibold text-md">
            {t("holidaydate.end_date")}
          </div>
          <DatePickerComponent
            value={endDate}
            format="MMM DD, YYYY"
            onChange={(dateObject) => {
              let dat = { ...event };
              let endDate = new Date(dateObject);
              let startDate = new Date(dat.startDate);
              if (startDate < endDate) {
                dat.endDate = DateUtility.dateFormatApi(endDate);
                dispatch(setEvent(dat));
              } else {
                PopupUtility.responseValidate(
                  "Warning",
                  t("End date cannot be less than start date")
                );
                dat.endDate = DateUtility.dateFormatApi("");
                dispatch(setEvent(dat));
              }
            }}
            minDate={new Date()}
            placeholder={t("holidaydate.select_date_placeholder")}
            inputClass="block sm:text-sm shadow-sm border border-gray-primary rounded-xl bg-white placeholder:text-slate-40 cursor-pointer"
          />
        </div>
      </div>
      <div className="pt-6 flex justify-end gap-x-6">
        <ButtonWhite
          title={t("general.cancel")}
          onClick={() => {
            Action.changeShowForm(dispatch, { showForm: false });
          }}
        />
        <ButtonGreen
          title={t("general.save")}
          onClick={() => {
            if (
              event.startDate !== "NaN-undefined-NaN" &&
              event.endDate !== "NaN-undefined-NaN"
            ) {
              dispatch(setShow(true));
            } else {
              PopupUtility.responseValidate(
                "Warning",
                t("Start date and End date must be filled!")
              );
            }
          }}
        />
      </div>
      {show ? (
        <PopupConfirmation
          title={t("holidaydate.confirmation_title")}
          subtitle={t("general.confirmation_subtitle")}
          onCancel={() => {
            dispatch(setShow(false));
          }}
          onDelete={() => {
            Action.saveForm(dispatch, event);
            dispatch(setShow(false));
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default HolidayForm;
