import React, { useState } from "react";
import GeneralUtility from "../../../../utils/general-utility";

const WeightVariant = ({ weight, changeWeight }) => {
  let filterTimeout;
  const [data, setData] = useState(weight);

  return (
    <td className="font-semibold">
      <label className="flex-1 min-w-fit relative block">
        <input
          type="text"
          name="weight"
          value={data}
          onChange={(e) => {
            setData(e.target.value);
            clearTimeout(filterTimeout);
            filterTimeout = setTimeout(() => {
              changeWeight(e.target.value);
            }, 2500);
          }}
          onFocus={(e) => {
            e.target.select();
          }}
          className="text-left w-full block text-sm shadow-sm border border-gray-primary rounded-xl bg-gray-primary"
        />
        <span className="absolute inset-y-0 right-2.5 flex items-center">
          <p>gr</p>
        </span>
      </label>
    </td>
  );
};

export default WeightVariant;
