const dashboard = "/dashboard";
const management = "/management";
const buyback = "/buyback";
const goldpickup = "/goldpickup";
const goldpricing = "/goldpricing";
const generateqr = "/generateqr";
const login = "/";
const register = "/register";
const forgotpassword = "/forgotpassword";
const newpassword = "/newpassword";
const newpasswordcustomer = "/customer/newpassword";
const helpcenter = "/helpcenter";
const accountsettings = "/accountsettings";
const appcustomization = "/appcustomization";
const merchantapplication = "/merchantapplication";
const merchantreject = "/merchantreject";
const orders = "/orders";
const products = "/product";
const balance = "/balance";
const jnelabel = "/label";
const complaint = "/complaint";
const freezemode = "/freeze";
const chat = "/chat";

const RouteName = {
  dashboard,
  management,
  buyback,
  goldpickup,
  goldpricing,
  generateqr,
  login,
  register,
  forgotpassword,
  newpassword,
  newpasswordcustomer,
  helpcenter,
  accountsettings,
  appcustomization,
  merchantapplication,
  merchantreject,
  orders,
  products,
  balance,
  jnelabel,
  complaint,
  freezemode,
  chat
};

export default RouteName;
