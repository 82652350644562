import DatePicker from "react-multi-date-picker";

const DatePickerComponent = ({
  value,
  onChange,
  format,
  placeholder,
  inputClass,
  minDate,
  maxDate
}) => {
  return (
    <DatePicker
      value={value}
      onChange={(dateObject) => {
        onChange(dateObject);
      }}
      format={format}
      placeholder={placeholder}
      inputClass={inputClass}
      minDate={minDate || null}
      maxDate={maxDate || null}
    />
  );
};

export default DatePickerComponent;
