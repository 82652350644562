import OperationalHourRepository from "../../../../../../repositories/OperationalHourRepository";
import PopupUtility from "../../../../../../utils/popup-utility";
import { setEvent, setShowForm } from "./Reducer";

const getHolidateData = async (dispatch) => {
  let response = await OperationalHourRepository.getHolidateDates();

  if (!response.error) {
    dispatch(setEvent(response.data));
  }
};

const saveForm = async (dispatch, event) => {
  //save data
  let data = { ...event };
  let response;

  data.status = null;
  response = await OperationalHourRepository.updateHolidateDates(data);
  PopupUtility.success("Changes saved successfully");

  //fetch data again
  dispatch(setShowForm(false));
};

const changeShowForm = async (dispatch, { showForm }) => {
  dispatch(setShowForm(showForm));
};

const Action = {
  getHolidateData,
  saveForm,
  changeShowForm,
};

export default Action;
