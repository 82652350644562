import {setId, setPage, setTypeForm} from "./Reducer";

const getPage = async (dispatch, {page}) => {
    dispatch(setPage(page));
};

const onEdit = async (dispatch, id, typeForm) => {
    dispatch(setId(id));
    dispatch(setTypeForm(typeForm));
    getPage(dispatch, {page: "edit"});
};

const Action = {
    getPage,
    onEdit
};

export default Action;
