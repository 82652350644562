import React, { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { Provider, useDispatch, useSelector } from "react-redux";
import StoreHelper from "../../../../services/store-helper";
import Action from "./redux/Action";
import reducerSlice, {
  setShowConfirmPass,
  setShowNewPass,
  setShowPass,
} from "./redux/Reducer";

const ChangePassword = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  store.getState();

  const MainComponent = () => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const showPass = useSelector((state) => state.reducer.showPass);
    const showNewPass = useSelector((state) => state.reducer.showNewPass);
    const showConfirmPass = useSelector(
      (state) => state.reducer.showConfirmPass
    );
    const security = useSelector((state) => state.reducer.security);
    const errorSecurity = useSelector((state) => state.reducer.errorSecurity);
    const canSave = useSelector((state) => state.reducer.canSave);

    useEffect(() => {
      Action.validate(dispatch, security);
    }, [security]);
    return (
      <form>
        <div className="flex flex-col gap-y-4 md:h-[37em] px-3 overflow-y-auto">
          {/* Password */}
          <div className="flex flex-row items-center gap-x-3 pt-2">
            <div className="w-44">
              <div
                className={`text-md font-bold ${
                  errorSecurity.password ? "pb-4" : " "
                }`}
              >
                {t("newpassword.current_pass")}
                <span className="text-gold-secondary">*</span>
              </div>
            </div>
            <div className="flex-1">
              <label className="relative block">
                <input
                  type={`${showPass ? "text" : "password"}`}
                  name="password"
                  onChange={(e) => {
                    Action.handleChange(dispatch, e, security);
                  }}
                  placeholder={t("newpassword.input_password")}
                  className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${
                    errorSecurity.password
                      ? "border-red-500 border-1"
                      : "border-0"
                  }`}
                  value={security.password}
                />
                <span
                  className="absolute inset-y-0 right-1.5 items-center"
                  onClick={() => dispatch(setShowPass(!showPass))}
                >
                  <img
                    className="h-full w-7 cursor-pointer"
                    src={
                      process.env.REACT_APP_ASSETS_IMAGE +
                      "eye-" +
                      (showPass ? "hide" : "show") +
                      ".svg"
                    }
                    alt="eye-hide"
                  />
                </span>
              </label>
              <p className="text-red-500">{errorSecurity.password}</p>
            </div>
          </div>
          <hr />

          {/* New Password */}
          <div className="flex flex-row items-center gap-x-3">
            <div className="w-44">
              <div
                className={`text-md font-bold ${
                  errorSecurity.new_password ? "pb-4" : " "
                }`}
              >
                {t("newpassword.new_pass")}
                <span className="text-gold-secondary">*</span>
              </div>
            </div>
            <div className="flex-1">
              <label className="relative block">
                <input
                  type={`${showNewPass ? "text" : "password"}`}
                  name="new_password"
                  onChange={(e) => {
                    Action.handleChange(dispatch, e, security);
                  }}
                  placeholder={t("newpassword.input_new_pass")}
                  className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${
                    errorSecurity.password
                      ? "border-red-500 border-1"
                      : "border-0"
                  }`}
                  value={security.new_password}
                />
                <span
                  className="absolute inset-y-0 right-1.5 items-center"
                  onClick={() => dispatch(setShowNewPass(!showNewPass))}
                >
                  <img
                    className="h-full w-7 cursor-pointer"
                    src={
                      process.env.REACT_APP_ASSETS_IMAGE +
                      "eye-" +
                      (showNewPass ? "hide" : "show") +
                      ".svg"
                    }
                    alt="eye-hide"
                  />
                </span>
              </label>
              <p className="text-red-500">{errorSecurity.new_password}</p>
            </div>
          </div>
          <hr />

          {/* Confirm Password */}
          <div className="flex flex-row items-center gap-x-3">
            <div className="w-44">
              <div
                className={`text-md font-bold ${
                  errorSecurity.confirm_password ? "pb-4" : " "
                }`}
              >
                {t("newpassword.confirm_pass")}
                <span className="text-gold-secondary">*</span>
              </div>
            </div>
            <div className="flex-1">
              <label className="relative block">
                <input
                  type={`${showConfirmPass ? "text" : "password"}`}
                  name="confirm_password"
                  onChange={(e) => {
                    Action.handleChange(dispatch, e, security);
                  }}
                  placeholder={t("newpassword.input_confirm_pass")}
                  className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${
                    errorSecurity.confirm_password
                      ? "border-red-500 border-1"
                      : "border-0"
                  }`}
                  value={security.confirm_password}
                />
                <span
                  className="absolute inset-y-0 right-1.5 items-center"
                  onClick={() => dispatch(setShowConfirmPass(!showConfirmPass))}
                >
                  <img
                    className="h-full w-7 cursor-pointer"
                    src={
                      process.env.REACT_APP_ASSETS_IMAGE +
                      "eye-" +
                      (showConfirmPass ? "hide" : "show") +
                      ".svg"
                    }
                    alt="eye-hide"
                  />
                </span>
              </label>
              <p className="text-red-500">{errorSecurity.confirm_password}</p>
            </div>
          </div>
          <hr />

          {/* Validation */}
          <div className="flex flex-row items-center gap-x-3">
            <div className="w-44"></div>
            <div className="font-medium py-5">
              <p className="font-bold text-md">{t("newpassword.rules")}</p>
              <div className="flex flex-row gap-x-12 pt-2">
                <div className="flex flex-col text-center">
                  <img
                    className="h-9"
                    src={
                      process.env.REACT_APP_ASSETS_IMAGE +
                      `${
                        errorSecurity.lowercase
                          ? "lowercase-inactive.svg"
                          : "lowercase.svg"
                      }`
                    }
                    alt="lowercase"
                  />
                  <p>{t("newpassword.lowercase")}</p>
                </div>
                <div className="flex flex-col text-center">
                  <img
                    className="h-9"
                    src={
                      process.env.REACT_APP_ASSETS_IMAGE +
                      `${
                        errorSecurity.uppercase
                          ? "uppercase-inactive.svg"
                          : "uppercase.svg"
                      }`
                    }
                    alt="uppercase"
                  />
                  <p>{t("newpassword.uppercase")}</p>
                </div>
                <div className="flex flex-col text-center">
                  <img
                    className="h-9"
                    src={
                      process.env.REACT_APP_ASSETS_IMAGE +
                      `${
                        errorSecurity.number
                          ? "number-inactive.svg"
                          : "number.svg"
                      }`
                    }
                    alt="number"
                  />
                  <p>{t("newpassword.number")}</p>
                </div>
                <div className="flex flex-col text-center">
                  <img
                    className="h-9"
                    src={
                      process.env.REACT_APP_ASSETS_IMAGE +
                      `${errorSecurity.char ? "char-inactive.svg" : "char.svg"}`
                    }
                    alt="char"
                  />
                  <p>{t("newpassword.char")}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row-reverse gap-x-2 pt-5 pb-3">
            <button
              onClick={(e) => {
                e.preventDefault();
                if (canSave) {
                  Action.handleSubmit(dispatch, e, security);
                }
              }}
              className={`button md:w-36 text-white border-2 text-xs tracking-widest font-bold py-3 rounded-xl ${
                canSave
                  ? "hover:bg-gray-primary hover:text-green-quaternary border-green-secondary bg-green-secondary"
                  : "border-gray-secondary bg-gray-secondary"
              }`}
            >
              {t("general.save").toUpperCase()}
            </button>
          </div>
        </div>
      </form>
    );
  };
  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default ChangePassword;
