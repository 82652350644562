import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const success = (message, title, timer) => {
  MySwal.fire({
    toast: true,
    title: title,
    position: "top",
    text: message,
    showConfirmButton: false,
    timer: timer || 1500,
    background: "#5D7D73",
    color: "#fff",
    width: 600,
  });
};

const responseValidate = (title, message) => {
  const defaultMessage = "Oops, something went wrong. Tanam Emas team has been notified and will look into this.";
  MySwal.fire({
    icon: "error",
    title: title,
    html: message || defaultMessage,
  });
};

const PopupUtility = {
  success,
  responseValidate,
};

export default PopupUtility;