import React from 'react';

const ImageModal = ({title, image, onCancel}) => {
    return (
        <div
            className="fixed inset-0 bg-gray-700 bg-opacity-70 flex justify-center items-center z-50 overflow-y-auto"
            onClick={onCancel}>
            <div
                className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full"
                onClick={(e) => e.stopPropagation()}>
                <button
                    className="absolute top-2 right-4 text-2xl text-white font-bold"
                    onClick={onCancel}>
                    &times;
                </button>
                <img
                    src={image}
                    alt={title}
                    className="w-full h-auto rounded"/>
            </div>
        </div>
    );
};

export default ImageModal;
