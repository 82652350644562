import {useDispatch, useSelector} from 'react-redux';
import Action from '../redux/Action';
import UploadMedia from '../../../components/ChatMedia/UploadMedia';
import {useTranslation} from 'react-multi-lang';

const ChatSender = () => {
    const dispatch = useDispatch();
    const t = useTranslation();

    const isUploadOpen = useSelector((state) => state.reducer.isUploadOpen);
    const bodyChat = useSelector((state) => state.reducer.bodyChat);
    const selectedItem = useSelector((state) => state.reducer.selectedItem);
    const error = useSelector((state) => state.reducer.error);
    const rows = useSelector((state) => state.reducer.rowsTextarea);
    const buttonLoading = useSelector((state) => state.reducer.buttonLoading);

    return (
        <div className='absolute bottom-0 left-0 right-0'>
            <div
                className={`bg-gray-primary flex rounded-2xl items-center p-2 mt-auto absolute ${error.isError ? "bottom-5 border-red-500" : "bottom-0 border-transparent"} border left-0 right-0`}>
                <img
                    className="h-10 w-10 fill-slate-300 cursor-pointer"
                    src={process.env.REACT_APP_ASSETS_IMAGE + "icon-add-image.png"}
                    onClick={() => {
                        Action.uploadMedia(dispatch, {showMedia: true});
                    }}
                    alt={"icon-add-image"}
                />
                {isUploadOpen ?
                    <UploadMedia
                        position={"col"}
                        onClose={() => {
                            Action.uploadMedia(dispatch, {showMedia: false});
                        }}
                        onUpload={(selectedFile) => {
                            Action.uploadMediaToAws(dispatch, {
                                media: selectedFile,
                                selectedItem,
                            });
                        }}
                        disabled={buttonLoading}
                    /> : ""}
                <div className="flex items-center justify-center h-full mx-2">
                    <div className="h-6 border-l-2 border-gray-400"></div>
                </div>
                <textarea
                    rows={rows}
                    value={bodyChat}
                    className="bg-gray-primary border-none align-middle flex-grow h-full text-sm p-2 focus:outline-none resize-none focus:border-transparent focus:ring-0 leading-4"
                    placeholder="Type Something Here"
                    onChange={(e) => {
                        Action.handleChangeTextMessage(dispatch, e);
                    }}
                    onKeyDown={(e) => {
                        Action.handleKeyDownTextMessage(dispatch, {
                            e,
                            selectedItem,
                            bodyChat,
                            rows,
                            error: error.isError
                        });
                    }}
                />

                {bodyChat !== "" && !error.isError ? (
                    <button onClick={() => Action.sendMessage(dispatch, {selectedItem, content: bodyChat})}>
                        <img
                            className="h-10 w-10 fill-slate-300 ml-2"
                            src={process.env.REACT_APP_ASSETS_IMAGE + "chat-icon-send-active.png"}
                            alt="chat-icon-send-active"/>
                    </button>
                ) : (
                    <img
                        className="h-10 w-10 fill-slate-300 ml-2"
                        src={process.env.REACT_APP_ASSETS_IMAGE + "icon-sent-disable.png"}
                        alt="icon-sent-disable"/>
                )}
            </div>
            {error.isError && error.type === "LENGTH" ?
                <div className="text-red-500 text-left">{t("chats.error_length")}</div> : ""}
            {error.isError && error.type === "PHONE_NUMBER" ?
                <div className="text-red-500 text-left">{t("chats.error_phone_number")}</div> : ""}
        </div>
    );
};

export default ChatSender;