import { useDispatch, useSelector } from "react-redux";
import Action from "../redux/Action";
import FaqQuestion from "./FaqQuestion";

const FaqSection = ({ section }) => {
  const dispatch = useDispatch();
  const showSectionId = useSelector((state) => state.reducer.showSectionId);

  return (
    <div className="bg-gray-primary rounded-xl mb-3">
      <button
        className="w-full flex p-3"
        onClick={() => {
          Action.changeShowSectionId(dispatch, {
            currentSectionId: showSectionId,
            sectionId: section.id,
          });
        }}
      >
        <p className="flex-1 text-left text-lg font-bold opacity-60 m-1">
          {section.sectionTitle}
        </p>
        <div className="text-lg font-bold">
          {showSectionId === section.id ? "-" : "+"}
        </div>
      </button>
      {showSectionId === section.id ? (
        <div className="flex gap-2 px-3">
          <div className="basis-1/2">
            {section.questions
              .filter((item) => item.id % 2 === 0)
              .map((question, index) => (
                <FaqQuestion key={index} question={question} />
              ))}
          </div>
          <div className="basis-1/2">
            {section.questions
              .filter((item) => item.id % 2 !== 0)
              .map((question, index) => (
                <FaqQuestion key={index} question={question} />
              ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FaqSection;
