import React, { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import Action from "../redux/Action";
import { setSecurity, setShowConfirmPass, setShowPass } from "../redux/Reducer";

const Security = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const showPass = useSelector((state) => state.reducer.showPass);
  const showConfirmPass = useSelector((state) => state.reducer.showConfirmPass);
  const security = useSelector((state) => state.reducer.security);
  const errorSecurity = useSelector((state) => state.reducer.errorSecurity);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setSecurity({ ...security, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = Action.validateStep4(dispatch, security);
    if (Object.keys(isValid).length === 0) {
      Action.getPage(dispatch, "termsconditions");
    }
  };

  useEffect(() => {
    Action.validateStep4(dispatch, security);
  }, [security]);
  return (
    <form>
      <div className="flex flex-col gap-y-4 md:h-[30em] overflow-y-auto">
        {/* Password */}
        <div className="flex flex-row items-center gap-x-3 pt-2">
          <div className="w-44">
            <div
              className={`text-md font-bold ${
                errorSecurity.password ? "pb-4" : " "
              }`}
            >
              {t("newpassword.password")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <label className="relative block">
              <input
                type={`${showPass ? "text" : "password"}`}
                name="password"
                onChange={handleChange}
                placeholder={t("newpassword.input_password")}
                className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${
                  errorSecurity.password
                    ? "border-red-500 border-1"
                    : "border-0"
                }`}
              />
              <span
                className="absolute inset-y-0 right-1.5 items-center"
                onClick={() => dispatch(setShowPass(!showPass))}
              >
                <img
                  className="h-full w-7 cursor-pointer"
                  src={
                    process.env.REACT_APP_ASSETS_IMAGE +
                    "eye-" +
                    (showPass ? "hide" : "show") +
                    ".svg"
                  }
                  alt="eye-hide"
                />
              </span>
            </label>
            <p className="text-red-500">{errorSecurity.password}</p>
          </div>
        </div>
        {/* Confirm Password */}
        <div className="flex flex-row items-center gap-x-3 pb-2">
          <div className="w-44">
            <div
              className={`text-md font-bold ${
                errorSecurity.confirm_password ? "pb-4" : " "
              }`}
            >
              {t("newpassword.confirm_pass")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="flex-1">
            <label className="relative block">
              <input
                type={`${showConfirmPass ? "text" : "password"}`}
                name="confirm_password"
                onChange={handleChange}
                placeholder={t("newpassword.input_confirm_pass")}
                className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${
                  errorSecurity.confirm_password
                    ? "border-red-500 border-1"
                    : "border-0"
                }`}
              />
              <span
                className="absolute inset-y-0 right-1.5 items-center"
                onClick={() => dispatch(setShowConfirmPass(!showConfirmPass))}
              >
                <img
                  className="h-full w-7 cursor-pointer"
                  src={
                    process.env.REACT_APP_ASSETS_IMAGE +
                    "eye-" +
                    (showConfirmPass ? "hide" : "show") +
                    ".svg"
                  }
                  alt="eye-hide"
                />
              </span>
            </label>
            <p className="text-red-500">{errorSecurity.confirm_password}</p>
          </div>
        </div>
        {/* Validation */}
        <div className="font-medium py-5">
          <p className="font-bold text-md">{t("newpassword.rules")}</p>
          <div className="flex flex-row gap-x-16 pt-2">
            <div className="flex flex-col text-center">
              <img
                className="h-9"
                src={
                  process.env.REACT_APP_ASSETS_IMAGE +
                  `${
                    errorSecurity.lowercase
                      ? "lowercase-inactive.svg"
                      : "lowercase.svg"
                  }`
                }
                alt="lowercase"
              />
              <p>{t("newpassword.lowercase")}</p>
            </div>
            <div className="flex flex-col text-center">
              <img
                className="h-9"
                src={
                  process.env.REACT_APP_ASSETS_IMAGE +
                  `${
                    errorSecurity.uppercase
                      ? "uppercase-inactive.svg"
                      : "uppercase.svg"
                  }`
                }
                alt="uppercase"
              />
              <p>{t("newpassword.uppercase")}</p>
            </div>
            <div className="flex flex-col text-center">
              <img
                className="h-9"
                src={
                  process.env.REACT_APP_ASSETS_IMAGE +
                  `${
                    errorSecurity.number ? "number-inactive.svg" : "number.svg"
                  }`
                }
                alt="number"
              />
              <p>{t("newpassword.number")}</p>
            </div>
            <div className="flex flex-col text-center">
              <img
                className="h-9"
                src={
                  process.env.REACT_APP_ASSETS_IMAGE +
                  `${errorSecurity.char ? "char-inactive.svg" : "char.svg"}`
                }
                alt="char"
              />
              <p>{t("newpassword.char")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row-reverse gap-x-2 pt-5">
        <button
          onClick={handleSubmit}
          className="button md:w-36 hover:bg-gray-primary text-white border-2 hover:text-green-quaternary border-gold-secondary text-xs tracking-widest bg-gold-secondary font-bold py-3 rounded-xl"
        >
          {t("register.next").toUpperCase()}
        </button>
      </div>
    </form>
  );
};

export default Security;
