import React, { useState, useRef } from "react";
import GeneralUtility from "../../../utils/general-utility";
import PopupUtility from "../../../utils/popup-utility";

const PromoPrice = ({ productPrice, promoPrice, changePromoPrice, status }) => {
  const [data, setData] = useState(GeneralUtility.addCommas(promoPrice));
  const prevBlurValueRef = useRef(promoPrice);

  const handleInputChange = (e) => {
    setData(GeneralUtility.addSeparator(e.target.value));
  };

  const handleInputKeyUp = (e) => {
    if (e.key === "Enter") {
      const floatPromoPrice = parseFloat(e.target.value.split(",").join(""));
      const floatProductPrice = parseFloat(productPrice);

      if (floatProductPrice <= floatPromoPrice) {
        setData(promoPrice);
        PopupUtility.responseValidate(
          "Validation",
          "Promo price cannot be greater than or equal to the product price"
        );
      } else if (floatPromoPrice < 1) {
        setData(promoPrice);
        PopupUtility.responseValidate(
          "Validation",
          "Promo Price cannot be 0"
        );
      } else {
        changePromoPrice(e.target.value);
      }
    }

  };

  const handleInputFocus = (e) => {
    e.target.select();
  };

  const handleInputBlur = (e) => {
    const value = e.target.value;
    if (value === GeneralUtility.addCommas(prevBlurValueRef.current)) {
      setData(promoPrice);
    } else {
      const floatPromoPrice = parseFloat(value.split(",").join(""));
      const floatProductPrice = parseFloat(productPrice);
      if (floatProductPrice <= floatPromoPrice) {
        setData(promoPrice);
        PopupUtility.responseValidate(
          "Validation",
          "Promo price cannot be greater than or equal to the product price"
        );
      } else if (floatPromoPrice < 1) {
        setData(promoPrice);
        PopupUtility.responseValidate("Validation", "Promo Price cannot be 0");
      } else {
        changePromoPrice(value);
      }
    }
    prevBlurValueRef.current = value;
  };

  return (
    <td className={`font-semibold ${status ? "opacity-50" : ""}`}>
      <label className="flex-1 min-w-fit relative block">
        <span className="absolute inset-y-0 left-1.5 flex items-center pl-2">
          <p>Rp</p>
        </span>
        <input
          type="text"
          name="promoPrice"
          value={GeneralUtility.addCommas(data)}
          onChange={handleInputChange}
          onKeyUp={handleInputKeyUp}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          className={`${status ? "cursor-not-allowed" : "cursor-pointer"} pl-10 pr-3 text-right w-32 block text-sm shadow-sm border border-gray-primary rounded-xl bg-gray-primary`}
          disabled={status}
        />
      </label>
    </td>
  );
};

export default PromoPrice;
