import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import Action from "../redux/Action";
import ChatSender from "./ChatSender";
import BarDetailChat from "./BarDetailChat";
import ChatBoxMessage from "./ChatBoxMessage";
import ImageModal from "./ImageModal";
import {setBodyChat, setError, setRowsTextarea} from "../redux/Reducer";

const DetailChat = () => {
    const dispatch = useDispatch();

    const modalImage = useSelector((state) => state.reducer.modalImage);
    const image = useSelector((state) => state.reducer.image);
    const selectedItem = useSelector((state) => state.reducer.selectedItem);

    const chatContainerRef = useRef(null);

    const scrollToBottom = () => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
            dispatch(setBodyChat(""));
            dispatch(setError({
                isError: false,
                type: ""
            }));
            dispatch(setRowsTextarea(1));
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [selectedItem]);

    if (!selectedItem || Object.keys(selectedItem).length === 0) return null;

    return (
        <>
            <div className="flex flex-col bg-white relative h-full">
                {/* bar detail chat */}
                <BarDetailChat/>

                {/* chatbox messages */}
                <ChatBoxMessage refChat={chatContainerRef}/>

                {/* chat sender */}
                <ChatSender/>
            </div>

            {modalImage ? (
                <ImageModal
                    title={image.title}
                    image={image.src}
                    onCancel={() => {
                        Action.changeModalImage(dispatch, {showModal: false});
                    }}
                />
            ) : ""}
        </>
    );
};

export default DetailChat;
