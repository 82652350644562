import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import Action from '../../redux/Action';
import ButtonGreen from '../../../../components/ButtonGreen/ButtonGreen';
import { useTranslation } from 'react-multi-lang';

const InsightSuspend = () => {
    const t = useTranslation();
    const isLoading = useSelector((state) => state.reducer.isLoadingBuyback);
    return (
        <div className="section-grey px-4 py-3 h-[49vh] overflow-y-auto">
            <h2 className="text-sm p-2 mb-2 font-semibold tracking-widest text-[#A7ADA9]">
                {t("dashboard.insight").toUpperCase()}
            </h2>

            {isLoading ? (
                <div className="w-full p-3 mb-2">
                    <Skeleton height="220px" />
                </div>
            ) : (
                <div className="p-2">
                    <div className="w-full text-center">
                        <img
                            className="w-fit-content relative mx-auto max-h-24 mb-3"
                            src={process.env.REACT_APP_ASSETS_IMAGE + "suspended.png"}
                            alt="suspended-merchant"
                        />
                        <div className="rounded-xl p-3 text-left border-2 border-gold-secondary bg-[#f5f0e1]">
                            <div className="font-bold">{t("general.suspend_account")}</div>
                            <div className="font-medium pt-2">{t("general.desc_suspended_account")}</div>
                        </div>
                        <div className="items-center gap-2 mt-4 flex justify-end">
                            <ButtonGreen
                                title={t("general.go_helpcenter").toUpperCase()}
                                onClick={() => {
                                    Action.redirectToFeedbacks();
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InsightSuspend;