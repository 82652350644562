import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "LegalDocuments",
  initialState: {
    data: {},
    loading: true,
    modalImage: false,
    image: {
      title: "",
      src: "",
    },
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setModalImage: (state, action) => {
      state.modalImage = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload;
    },
  },
});

export const { setData, setImage, setLoading, setModalImage } =
  reducerSlice.actions;

export default reducerSlice;
