import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "aboutus",
  initialState: {
    loading: true,
    data: {}
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const {
  setLoading,
  setData,
} = reducerSlice.actions;

export default reducerSlice;
