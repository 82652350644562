import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Provider, useSelector } from "react-redux";
import StoreHelper from "../../../../services/store-helper";
import FaqSection from "./component/FaqSection";
import Action from "./redux/Action";
import reducerSlice from "./redux/Reducer";

const FreqAskQuest = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  const MainComponent = () => {
    const loading = useSelector((state) => state.reducer.loading);
    const data = useSelector((state) => state.reducer.data);

    useEffect(() => {
      Action.getData(store);
    }, []);

    return loading ? (
      <div className="bg-gray-primary rounded-xl p-3">
        <Skeleton count={3} height={"2rem"} />
      </div>
    ) : (
      data.map((section, index) => <FaqSection key={index} section={section} />)
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default FreqAskQuest;
