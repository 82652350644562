import React from "react";
import { useTranslation } from "react-multi-lang";
import Action from "../redux/Action";
import { useDispatch } from "react-redux";

const NavRegister = ({ activePage }) => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const nav = [
    { id: 1, title: t("register.account_details"), route: "accountdetails" },
    { id: 2, title: t("register.bank_details"), route: "bankdetails" },
    { id: 3, title: t("register.legal_documents"), route: "legaldocuments" },
    { id: 4, title: t("register.security"), route: "security" },
    { id: 5, title: t("register.terms_conditions"), route: "termsconditions" },
  ];

  return (
    <>
      <div className="text-md text-gray-secondary font-bold">
        {t("register.title").toUpperCase()}
      </div>

      <div>
        <ol className="relative border-l-2 border-gray-secondary border-opacity-60 ml-5 mt-10">
          {nav.map((item, index) => (
            <li className="mb-12 ml-7 flex items-center" key={index}>
              <span
                className={`absolute flex items-center justify-center ${activePage === item.route
                    ? "ring-4 ring-gray-secondary ring-opacity-20"
                    : ""
                  } w-7 h-7 -left-4 rounded-full bg-gray-tertiary`}
              >
                <div
                  className={`rounded-full flex items-center justify-center ${activePage === item.route
                      ? "w-5 h-5 bg-green-secondary"
                      : "w-3 h-3 ring-4 ring-gray-secondary ring-opacity-20 "
                    }`}
                >
                  <div className="text-white text-sm">
                    {activePage === item.route ? (
                      item.id
                    ) : (
                      <div
                        className={`h-1.5 w-1.5 bg-gray-400 rounded-full`}
                      ></div>
                    )}
                  </div>
                </div>
              </span>
              <p
                className={`text-green-quinary  ${activePage === item.route
                    ? "font-bold"
                    : "font-semibold text-opacity-60"
                  }`}
                onClick={() => {
                  // Action.getPage(dispatch, item.route);
                }}>
                {item.title}
              </p>
            </li>
          ))}
        </ol>
      </div>
    </>
  );
};

export default NavRegister;
