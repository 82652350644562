import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "ProfilDropdown",
  initialState: {
    showDropdown: false,
  },
  reducers: {
    setShowDropdown: (state, action) => {
      state.showDropdown = action.payload;
    },
  },
});

export const { setShowDropdown } = reducerSlice.actions;

export default reducerSlice;
