import Cookies from "js-cookie";
import EncryptDecrypt from "../../../../services/encrypt-decrypt";
import RouteName from "../../../../services/routename";

const toDashboard = async (response) => {
  let user = {};
  user.userId = response.userId;
  user.merchantsId = response.merchantsId;
  user.merchantName = response.merchantName;
  user.merchantCode = response.merchantCode;
  user.merchantStatus = response.merchantStatus;
  user.merchantStatusName = response.merchantStatusName;
  user.email = response.email;
  user.balance = response.balance;

  Cookies.set("token", EncryptDecrypt.enc(response.auth.token, { expires: 1 }));
  Cookies.set("userMerchant", EncryptDecrypt.enc(user), { expires: 1 });
  Cookies.set("merchantPhoto", EncryptDecrypt.enc(response.merchantPhoto), {
    expires: 1,
  });
  window.location = RouteName.dashboard;
};

const Action = {
  toDashboard,
};

export default Action;
