import React from "react";
import { BrowserRouter } from "react-router-dom";
import Base from "./pages/base/Base";

function App() {
  document.title = process.env.REACT_APP_NAME;
  return (
    <BrowserRouter>
      <Base />
    </BrowserRouter>
  );
}

export default App;
