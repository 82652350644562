import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
    name: "complaints",
    initialState: {
        startDateFilter: "",
        endDateFilter: "",
        showDetail: false,
        list: [],
        detailComplaint: {},
        loading: true,
        currentPage: 0,
        postPerPage: 5,
        totalData: 0,
        totalPage: 0,
        search: "",
        fieldName: "",
        orderBy: "",
        statusFilter: "",
        modalDeclined: false,
        modalResolve: false
    },
    reducers: {
        setStartDateFilter: (state, action) => {
            state.startDateFilter = action.payload;
        },
        setEndDateFilter: (state, action) => {
            state.endDateFilter = action.payload;
        },
        setShowDetail: (state, action) => {
            state.showDetail = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        setDetailComplaint: (state, action) => {
            state.detailComplaint = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setPostPerPage: (state, action) => {
            state.postPerPage = action.payload;
        },
        setTotalData: (state, action) => {
            state.totalData = action.payload;
        },
        setTotalPage: (state, action) => {
            state.totalPage = action.payload;
        },
        setSearch: (state, action) => {
            state.search = action.payload;
        },
        setFieldName: (state, action) => {
            state.fieldName = action.payload;
        },
        setOrderBy: (state, action) => {
            state.orderBy = action.payload;
        },
        setStatusFilter: (state, action) => {
            state.statusFilter = action.payload;
        },
        setModalDeclined: (state, action) => {
            state.modalDeclined = action.payload;
        },
        setModalResolve: (state, action) => {
            state.modalResolve = action.payload;
        }
    }
});

export const {
    setStartDateFilter,
    setEndDateFilter,
    setShowDetail,
    setList,
    setDetailComplaint,
    setLoading,
    setCurrentPage,
    setPostPerPage,
    setTotalData,
    setTotalPage,
    setSearch,
    setFieldName,
    setOrderBy,
    setStatusFilter,
    setModalDeclined,
    setModalResolve
} = reducerSlice.actions;

export default reducerSlice;