import OperationalItemModel from "../pages/accountsettings/components/operationalhour/operationalMain/model/OperationalItemModel";
import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";
import DateUtility from "../utils/date-utility";

const BASE_API = process.env.REACT_APP_BASE_API;

const getOperationalHours = async () => {
  const url = BASE_API + Endpoint.operational_hour;
  const response = await CallApi({ url, method: "POST" });
  const data = typeof response != "undefined" ? response.data : null;
  const data2 = typeof data != "undefined" && data != null ? data.data : [];

  let array = [];
  for (let i = 0; i < 7; i++) {
    const element = data2[i];
    let item;
    if (element && element.dayOfWeek === i + 1) {
      item = new OperationalItemModel({
        id: element.id,
        day: element.dayOfWeek,
        openTime: DateUtility.operationalHour(element.startTime),
        closeTime: DateUtility.operationalHour(element.endTime),
        isOpen: element.status,
        type:
          element.startTime === "00:00:00" && element.endTime === "23:59:00"
            ? "24hour"
            : "manual",
      });
    } else {
      item = new OperationalItemModel({
        id: "",
        day: i + 1,
        openTime: "",
        closeTime: "",
        isOpen: false,
        type: "manual",
      });
    }
    array.push(item);
  }

  let operasional = {};
  operasional.error = response.error;
  operasional.message = response.message;
  operasional.data = array;

  return operasional;
};

const getHolidateDates = async () => {
  const url = BASE_API + Endpoint.holiday_date;
  const response = await CallApi({ url, method: "POST" });
  const data = typeof response != "undefined" ? response.data : null;
  const data2 = typeof data != "undefined" && data != null ? data.data : {};

  let holiday = {};
  holiday.error = response.error;
  holiday.message = response.message;
  holiday.data = data2;

  return holiday;
};

const updateOperationalHours = async (data) => {
  const url = BASE_API + Endpoint.update_operational_hour;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const updateHolidateDates = async (data) => {
  const url = BASE_API + Endpoint.update_holiday_date;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const OperationalHourRepository = {
  getOperationalHours,
  getHolidateDates,
  updateOperationalHours,
  updateHolidateDates,
};

export default OperationalHourRepository;
