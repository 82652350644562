import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import InputText from "../../../../components/FormInput/InputText";
import InputTextCustom from "../../../../components/FormInput/InputTextCustom";
import GeneralUtility from "../../../../utils/general-utility";
import ButtonGold from "../../../../components/ButtonGold/ButtonGold";
import ProductRepository from "../../../../repositories/ProductRepository";
import PopupUtility from "../../../../utils/popup-utility";
import ButtonWhite from "../../../../components/ButtonWhite/ButtonWhite";
import { useNavigate } from "react-router-dom";
import RouteName from "../../../../services/routename";

const DEFAULT_DATA = {
  brandName: "",
  edition: "",
  weight: "",
  gramsId: "",
  gramsValue: "",
  lengthd: "",
  width: "",
  height: "",
  description: "",
  stock: "",
  price: "",
  promoPrice: "",
  packagingWeight: ""
};

const GoldBarForm = ({
  id,
  setImages,
  type,
  goldbarId,
  images,
  onSucces,
  updateImg,
}) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState(DEFAULT_DATA);
  const [error, setError] = useState({});
  const [valid, setValid] = useState(false);
  const [gram, setGram] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const getDetail = async () => {
    if (id) {
      const response = await ProductRepository.detailProduct({ id: id });
      let resp = response.data.data;
      let datas = {};
      let images = {};
      if (resp.images) {
        images.idPhoto1 = resp.images[0] ? resp.images[0].id : null;
        images.idPhoto2 = resp.images[1] ? resp.images[1].id : null;
        images.idPhoto3 = resp.images[2] ? resp.images[2].id : null;
        images.idPhoto4 = resp.images[3] ? resp.images[3].id : null;
        images.idPhoto5 = resp.images[4] ? resp.images[4].id : null;
        images.photo1 = resp.images[0] ? resp.images[0].image : "";
        images.photo2 = resp.images[1] ? resp.images[1].image : "";
        images.photo3 = resp.images[2] ? resp.images[2].image : "";
        images.photo4 = resp.images[3] ? resp.images[3].image : "";
        images.photo5 = resp.images[4] ? resp.images[4].image : "";
      }
      datas.gramsId = resp.gram !== null ? resp.gram.id + " " + resp.gram.value : "";
      datas.price = GeneralUtility.addCommas(resp.price);
      datas.promoPrice = GeneralUtility.addCommas(resp.promoPrice);
      datas.brandName = resp.brand;
      datas.description = resp.description;
      datas.edition = resp.edition;
      datas.height = resp.height;
      datas.lengthd = resp.length;
      datas.width = resp.width;
      datas.id = resp.id;
      datas.weight = resp.weight;
      datas.stock = resp.stock;
      datas.packagingWeight = resp.packagingWeight;
      setData(datas);
      setImages(images);
    } else {
      setData(DEFAULT_DATA);
      // setImages({
      //   0: { photo1: "", photo1name: "" },
      //   1: { photo2: "", photo2name: "" },
      //   2: { photo3: "", photo3name: "" },
      //   3: { photo4: "", photo4name: "" },
      //   4: { photo5: "", photo5name: "" },
      // });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "lengthd" ||
      name === "width" ||
      name === "height" ||
      name === "stock"
    ) {
      setData({ ...data, [name]: GeneralUtility.removeNonNumeric(value) });
    } else if (name === "gramsId") {
      // ambil data-valuegram
      const selectedOption = e.target.options[e.target.selectedIndex];
      const valueGram = selectedOption.getAttribute("data-valuegram");
      setData({ ...data, gramsId: value, weight: valueGram });
    } else if (name === "price" || name === "promoPrice") {
      setData({ ...data, [name]: GeneralUtility.addSeparator(value) });
    } else if (name === "weight" || name === "packagingWeight") {
      setData({ ...data, [name]: GeneralUtility.sanitizeNumericInput(value) });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.brandName) {
      errors.brandName = "Brand Name is required!";
    }

    // if (!values.weight) {
    //   errors.weight = "Total weight is required!";
    // } else if (parseInt(values.weight) === 0) {
    //   errors.weight = "Total weight cannot be zero!";
    // } else if (
    //   values.gramsId &&
    //   parseInt(values.gramsId.split(" ")[1]) > parseFloat(values.weight)
    // ) {
    //   errors.weight = "Total weight should not be lighter than weight of gold!";
    // }
    // else if (!values.weight.match("^[0-9.]+$")) {
    //   errors.weight =
    //     "Total weight can only be filled with numbers and dot for decimal";
    // }

    if (!values.lengthd) {
      errors.lengthd = "Length is required!";
    } else if (parseInt(values.lengthd) === 0) {
      errors.lengthd = "Length cannot be zero!";
    }

    if (!values.width) {
      errors.width = "Width is required!";
    } else if (parseInt(values.width) === 0) {
      errors.width = "Width cannot be zero!";
    }

    if (!values.height) {
      errors.height = "Height is required!";
    } else if (parseInt(values.height) === 0) {
      errors.height = "Height cannot be zero!";
    }

    if (!values.gramsId) {
      errors.gramsId = "Weight of gold is required!";
    }

    if (!values.stock) {
      errors.stock = "Stock is required!";
    }
    // else if (parseInt(values.stock) <= 0) {
    //   errors.stock = "Stock cannot be zero!";
    // }

    if (!values.price) {
      errors.price = "Price is required!";
    } else if (parseFloat(values.price.replace(/,/g, "")) === 0) {
      errors.price = "Price cannot be zero!";
    }

    if (values.promoPrice) {
      let price = values.price ? parseInt(values.price.replace(/,/g, "")) : 0;
      let promoPrice = values.promoPrice
        ? parseInt(values.promoPrice.replace(/,/g, ""))
        : 0;

      if (promoPrice >= price) {
        errors.promoPrice = "Promotional Price must be less equal than the price";
      }
    }

    if (!values.packagingWeight) {
      errors.packagingWeight = "Packaging Weight is required!";
    } else if (parseFloat(values.packagingWeight) === 0) {
      errors.packagingWeight = "Packaging Weight cannot be zero!";
    }

    setError(errors);
    return errors;
  };

  const getGram = async () => {
    const gram = await ProductRepository.getGram();
    setGram(gram ?? []);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res;

    if (type === "add") {
      let form = new FormData();
      if (images["0"]?.photo1 && images["0"]?.photo1 !== "") {
        form.append(
          "images",
          GeneralUtility.dataURLtoFile(images["0"]?.photo1, images["0"]?.photo1name),
          images["0"]?.photo1name
        );
      }
      if (images["1"]?.photo2 && images["1"]?.photo2 !== "") {
        form.append(
          "images",
          GeneralUtility.dataURLtoFile(images["1"]?.photo2, images["1"]?.photo2name),
          images["1"]?.photo2name
        );
      }
      if (images["2"]?.photo3 && images["2"]?.photo3 !== "") {
        form.append(
          "images",
          GeneralUtility.dataURLtoFile(images["2"]?.photo3, images["2"]?.photo3name),
          images["2"]?.photo3name
        );
      }
      if (images["3"]?.photo4 && images["3"]?.photo4 !== "") {
        form.append(
          "images",
          GeneralUtility.dataURLtoFile(images["3"]?.photo4, images["3"]?.photo4name),
          images["3"]?.photo4name
        );
      }
      if (images["4"]?.photo5 && images["4"]?.photo5 !== "") {
        form.append(
          "images",
          GeneralUtility.dataURLtoFile(images["4"]?.photo5, images["4"]?.photo5name),
          images["4"]?.photo5name
        );
      }

      form.append("brand", data.brandName);
      form.append("edition", data.edition ? data.edition : "");
      form.append("weight", data.weight);
      form.append("gramsId", data.gramsId.split(" ")[0]);
      form.append("length", data.lengthd);
      form.append("height", data.height);
      form.append("width", data.width);
      form.append("description", data.description ? data.description : "");
      form.append("stock", data.stock);
      form.append("price", GeneralUtility.decimalToInt(data.price));
      form.append("productCategoryId", goldbarId);
      form.append("canBuyback", true);
      form.append("packagingWeight", data.packagingWeight);

      if (data.promoPrice) {
        let promo = GeneralUtility.decimalToInt(data.promoPrice);
        if (parseInt(promo) > 0) {
          form.append("promoPrice", parseInt(promo));
        } else {
          form.append("promoPrice", 0);
        }
      }
      setLoading(true);
      setDisabled(true);
      res = await ProductRepository.addProduct(form);
    } else {
      let form = new FormData();

      form.append("id", data.id);
      form.append("brandName", data.brandName);
      form.append("edition", data.edition ? data.edition : "");
      form.append("weight", data.weight);
      form.append("gramsId", data.gramsId.split(" ")[0]);
      form.append("length", data.lengthd);
      form.append("height", data.height);
      form.append("width", data.width);
      form.append("description", data.description ? data.description : "");
      form.append("stock", data.stock);
      form.append("price", GeneralUtility.decimalToInt(data.price));
      form.append("packagingWeight", data.packagingWeight);

      if (data.promoPrice) {
        let promo = GeneralUtility.decimalToInt(data.promoPrice);
        if (parseInt(promo) > 0) {
          form.append("promoPrice", parseInt(promo));
        } else {
          form.append("promoPrice", 0);
        }
      }

      if (updateImg) {
        updateImg.forEach((value, index) => {
          form.append(`images[${index}].id`, value.id);
          if (value.action === "update" || value.action === "add") {
            const file = GeneralUtility.dataURLtoFile(value.image, value.filename);
            form.append(
              `images[${index}].image`,
              file
            );
          }

          form.append(`images[${index}].action`, value.action);
        });
      }

      setLoading(true);
      setDisabled(true);
      res = await ProductRepository.editProduct(form);
    }

    setLoading(false);
    setDisabled(false);
    onSucces();
    if (!res.error) {
      // getPage(dispatch, { page: "all" });
      // onCancel(dispatch);
      setData({
        brandName: "",
        edition: "",
        weight: "",
        gramsId: "",
        gramsValue: 0,
        lengthd: 0,
        width: 0,
        height: 0,
        description: "",
        stock: 0,
        price: 0,
        promoPrice: 0,
      });

      if (type === "add") {
        PopupUtility.success("A new product has been successfully added.");
      } else {
        PopupUtility.success("Product has been successfully updated.");
      }
    } else {
      const message = `
        <p class="text-lg mb-3 font-extrabold">${res.message}</p>
        <p class="text-sm font-bold text-gray-400 tracking-wider">Trace Id : ${res.trace_id}</p>
      `;
      PopupUtility.responseValidate("Add Product Failed", message);
    }
  };

  function allIdPhotoNull(obj) {
    for (let key in obj) {
      if (key.startsWith('idPhoto') && obj[key] !== null) {
        return false;
      }
    }
    return true;
  }

  const checkProductImagesIsNull = () => {
    if (type === "add") {
      const imagesCount = Object.keys(images);
      setDisabled(imagesCount.length <= 0);
    }

    if (type === "edit") {
      const filteredData = updateImg.filter(item => item.action !== "delete");
      const isNull = allIdPhotoNull(images);
      if (filteredData.length <= 0 && isNull) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  };

  useEffect(() => {
    if (valid) {
      validate(data);
    } else {
      getGram();
      getDetail();
      setValid(true);
    }
  }, [data]);

  useEffect(() => {
    const num = Object.keys(error).length;
    setDisabled(num > 0);
    checkProductImagesIsNull();
  }, [error]);

  useEffect(() => {
    validate(data);
    checkProductImagesIsNull();
  }, []);

  useEffect(() => {
    checkProductImagesIsNull();
  }, [images, updateImg]);

  return (
    <>
      {/* Brand Name */}
      <div className="flex flex-row gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">
            {t("products.brand")}
            <span className="text-gold-secondary">*</span>
          </div>

          <div className="pt-3 w-60">{t("products.category_subtitle")}</div>
        </div>
        <div className="flex-1">
          <InputText
            placeholder={t("products.input_brand")}
            name={"brandName"}
            value={data.brandName}
            error={error.brandName}
            onChange={(e) => {
              handleChange(e);
            }}
            maxLength={255}
            height={""}
          />
        </div>
      </div>

      {/* Edition */}
      <div className="flex flex-row gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">{t("products.edition")}</div>

          <div className="pt-3 w-60">{t("products.condition_edition")}</div>
        </div>
        <div className="flex-1">
          <InputText
            placeholder={t("products.input_edition")}
            name="edition"
            required={false}
            value={data.edition}
            error={error.edition}
            onChange={(e) => {
              handleChange(e);
            }}
            maxLength={"255"}
            height={""}
          />
        </div>
      </div>

      {/* Weight */}
      <div className="flex flex-row gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">
            {t("products.gold_weight")}
            <span className="text-gold-secondary">*</span>
          </div>

          <div className="pt-3 w-60">{t("products.condition_gold_weight")}</div>
        </div>
        <div className="flex-1">
          <div className="flex flex-row gap-x-5">
            <div className="flex-1">
              <select
                className={`text-sm border rounded-xl w-full bg-white font-medium ${error.gramsId ? "border-red-500" : "border-gray-primary"
                  }`}
                name="gramsId"
                value={data.gramsId ? data.gramsId : "default"}
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <option value="default" disabled>
                  {t("products.input_gramasi")}
                </option>
                {gram.map((item, index) => (
                  <option value={item.id + " " + item.value} data-valuegram={item.value} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
              <p className="text-red-500">{error.gramsId}</p>
            </div>
            {/* <div className="flex-1">
              <InputTextCustom
                placeholder={t("products.input_weight")}
                name="weight"
                value={data.weight}
                error={error.weight}
                onChange={(e) => {
                  handleChange(e);
                }}
                onFocus={(e) => {
                  e.target.select();
                }}
                maxLength={10}
                unit="gr"
              />
            </div> */}
          </div>
        </div>
      </div>

      {/* Dimension */}
      <div className="flex w gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">
            {t("products.dimension")}
            <span className="text-gold-secondary">*</span>
          </div>

          <div className="pt-3 w-60">{t("products.condition_dimension")}</div>
        </div>
        <div className="flex-1">
          <div className="flex flex-row gap-x-5">
            {/* length */}
            <div className="flex-1">
              <p className="pl-3 pb-2 font-bold text-green-secondary">
                {t("products.length")}
              </p>
              <InputTextCustom
                placeholder={t("products.input_length")}
                name="lengthd"
                value={data.lengthd}
                error={error.lengthd}
                onChange={(e) => {
                  handleChange(e);
                }}
                onFocus={(e) => {
                  e.target.select();
                }}
                maxLength={20}
                unit="cm"
              />
            </div>

            {/* width */}
            <div className="flex-1">
              <p className="pl-3 pb-2 font-bold text-green-secondary">
                {t("products.width")}
              </p>
              <InputTextCustom
                placeholder={t("products.input_width")}
                name="width"
                value={data.width}
                error={error.width}
                onChange={(e) => {
                  handleChange(e);
                }}
                onFocus={(e) => {
                  e.target.select();
                }}
                maxLength={20}
                unit="cm"
              />
            </div>

            {/* height */}
            <div className="flex-1">
              <p className="pl-3 pb-2 font-bold text-green-secondary">
                {t("products.height")}
              </p>
              <InputTextCustom
                placeholder={t("products.input_height")}
                name="height"
                value={data.height}
                error={error.height}
                onChange={(e) => {
                  handleChange(e);
                }}
                onFocus={(e) => {
                  e.target.select();
                }}
                maxLength={20}
                unit="cm"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Packging Weight */}
      <div className="flex flex-row gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">
            {t("products.packging_weight")}
            <span className="text-gold-secondary">*</span>
          </div>

          <div className="pt-3 w-60">{t("products.desc_packing_weight")}</div>
        </div>
        <div className="flex-1">
          <InputTextCustom
            placeholder={t("products.placeholder_packging_weight")}
            name="packagingWeight"
            value={data.packagingWeight}
            error={error.packagingWeight}
            onChange={(e) => {
              handleChange(e);
            }}
            maxLength={"20"}
            unit={"gr"}
            onFocus={(e) => {
              e.target.select();
            }}
          />
        </div>
      </div>

      {/* Description */}
      <div className="flex flex-row gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">{t("products.description")}</div>
          <div className="pt-3 w-60">{t("products.condition_description")}</div>
        </div>
        <div className="flex-1">
          <textarea
            name={"description"}
            onChange={(e) => {
              handleChange(e);
            }}
            placeholder={t("products.input_description")}
            className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${error.description ? "border-red-500 border-1" : "border-0"
              }`}
            value={data.description}
            maxLength={1000}
            rows={3}
          />
          <p className="text-red-500">{error.description}</p>
        </div>
      </div>

      {/* Stock */}
      <div className="flex flex-row gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">{t("products.stock_product")}</div>
          <div className="pt-3 w-60">{t("products.condition_stock")}</div>
        </div>
        <div className="flex-1">
          <InputText
            placeholder={t("products.input_stock")}
            name={"stock"}
            value={data.stock}
            error={error.stock}
            onChange={(e) => {
              handleChange(e);
            }}
            maxLength={20}
            height={""}
            onFocus={(e) => {
              e.target.select();
            }}
          />
        </div>
      </div>

      {/* Price */}
      <div className="flex w gap-x-4 pt-8">
        <div className="w-72">
          <div className="font-bold">
            {t("products.price_product")}
            <span className="text-gold-secondary">*</span>
          </div>
          <div className="pt-3 w-60">{t("products.condition_price")}</div>
        </div>
        <div className="flex-1">
          <div className="flex flex-row gap-x-5">
            {/* price */}
            <div className="flex-1">
              <p className="pl-3 pb-2 font-bold text-green-secondary">
                {t("products.normal_price")}
              </p>
              <label className="relative block">
                <span className="absolute inset-y-0 left-2.5 flex items-center">
                  <p className="font-bold text-green-secondary">Rp</p>
                </span>
                <input
                  type="text"
                  name="price"
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder={t("products.input_price")}
                  className={`pl-10 rounded-xl w-full text-right text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${error.price ? "border-red-500 border-1" : "border-0"
                    }`}
                  value={data.price}
                  maxLength={100}
                />
              </label>
              <p className="text-red-500">{error.price}</p>
            </div>

            {/* promotional price */}
            <div className="flex-1">
              <p className="pl-3 pb-2 font-bold text-green-secondary">
                {t("products.promotional_price")}
              </p>
              <label className="relative block">
                <span className="absolute inset-y-0 left-2.5 flex items-center">
                  <p className="font-bold text-green-secondary">Rp</p>
                </span>
                <input
                  type="text"
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  name="promoPrice"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder={t("products.input_promotional")}
                  className={`pl-10 rounded-xl w-full text-right text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${error.promoPrice ? "border-red-500 border-1" : "border-0"
                    }`}
                  value={data.promoPrice}
                  maxLength={100}
                />
              </label>
              <p className="text-red-500">{error.promoPrice}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="items-stretch gap-x-2 py-4 flex justify-end">
        {type === "add" ? (
          <>
            <ButtonWhite
              title={t("products.cancel").toUpperCase()}
              onClick={(e) => {
                navigate(RouteName.products, { replace: true });
              }}
            />
            <ButtonGold
              title={t("products.add").toUpperCase()}
              onClick={(e) => {
                handleSubmit(e);
              }}
              disabled={disabled}
              loading={isLoading}
            />
          </>
        ) : (
          <>
            <ButtonWhite
              title={t("products.cancel").toUpperCase()}
              onClick={(e) => {
                navigate(RouteName.products, { replace: true });
              }}
            />
            <ButtonGold
              title={t("products.save").toUpperCase()}
              onClick={(e) => {
                handleSubmit(e);
              }}
              disabled={disabled}
              loading={isLoading}
            />
          </>
        )}
      </div>
    </>
  );
};

export default GoldBarForm;
