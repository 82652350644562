import OrderRepository from "../../../../../repositories/OrderRepository";
import DateUtility from "../../../../../utils/date-utility";
import PopupUtility from "../../../../../utils/popup-utility";
import {
  setEndDateFilter,
  setHasMoreItems,
  setInvoiceDetail,
  setIsEmptyList,
  setPage,
  setList,
  setLoadingData,
  setSearch,
  setShowInvoiceModal,
  setShowTrackingModal,
  setSort,
  setStartDateFilter,
  setTrackingDetail,
} from "./Reducer";
import ActionSidebar from "../../../../../components/layout/sidebar/redux/Action";

const getList = async (
  dispatch,
  { currentList, search, sort, startDateFilter, endDateFilter, page }
) => {
  dispatch(setSearch(search));
  dispatch(setSort(sort));
  dispatch(setStartDateFilter(startDateFilter));
  dispatch(setEndDateFilter(endDateFilter));
  dispatch(setLoadingData(true));
  dispatch(setIsEmptyList(false));
  let list;
  let data = {
    status: "COMPLETED",
    page: page,
    size: 10,
  };

  if (search) {
    data.search = search;
  }

  if (startDateFilter) {
    data.startDate = DateUtility.dateFormatApi(startDateFilter);
  } else {
    data.startDate = "";
  }

  if (endDateFilter) {
    data.endDate = DateUtility.dateFormatApi(endDateFilter);
  } else {
    data.endDate = "";
  }

  if (sort) {
    data.orderBy = sort;
  } else {
    data.orderBy = "";
  }

  if (currentList) {
    list = currentList;
  } else {
    list = [];
  }

  const response = await OrderRepository.getListOrder(data);
  if (!response.error) {
    dispatch(setLoadingData(false));
    dispatch(setList([...list, ...response.data]));
    if (response.data.length > 0) {
      dispatch(setPage(page + 1));
    } else {
      dispatch(setHasMoreItems(false));
      if (list.length === 0 && response.data.length === 0) {
        dispatch(setIsEmptyList(true));
      }
    }
  }
};

const showInvoiceModal = (dispatch, { showModal, invoiceDetail }) => {
  if (showModal) {
    dispatch(setInvoiceDetail(invoiceDetail));
  }
  dispatch(setShowInvoiceModal(showModal));
};

const showTrackingModal = async (dispatch, { showModal, id }) => {
  if (showModal) {
    dispatch(setLoadingData(true));
    const response = await OrderRepository.getTrackingOrder({
      orderMerchantId: id,
    });
    if (!response.error) {
      dispatch(setTrackingDetail(response.data));
    } else {
      PopupUtility.responseValidate("Failed", response.message);
    }
    dispatch(setLoadingData(false));
  }
  dispatch(setShowTrackingModal(showModal));
};

const setPickUp = async (dispatch, { id }) => {
  const response = await OrderRepository.setPickUpOrder({
    orderMerchantId: id,
  });
  if (!response.error) {
    dispatch(setList([]));
    dispatch(setHasMoreItems(true));
    emptyList(dispatch);
    getList(dispatch, { currentList: [], page: 0 });
    PopupUtility.success(
      "Your pickup arrangements are being processed. Our courier will be with you shortly"
    );
    ActionSidebar.getTotalNotif(dispatch);
  } else {
    PopupUtility.responseValidate("Failed", response.message);
  }
};

const emptyList = async (dispatch) => {
  dispatch(setList([]));
  dispatch(setHasMoreItems(true));
};

const Action = {
  getList,
  showInvoiceModal,
  showTrackingModal,
  setPickUp,
  emptyList,
};

export default Action;
