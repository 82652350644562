import HelpCenterRepository from "../../../../../repositories/HelpCenterRepository";
import PopupUtility from "../../../../../utils/popup-utility";
import {
  setCategory,
  setCategoryList,
  setFormErrors,
  setLoading,
  setRemarks,
  setTopic,
  setTopicList,
} from "./Reducer";

const getCategoryAndTopic = async (store) => {
  store.dispatch(setLoading(true));
  let responseCategory = await HelpCenterRepository.getCategories();
  if (responseCategory) {
    store.dispatch(setCategoryList(responseCategory));
  }
  let responseTopic = await HelpCenterRepository.getTopics();
  if (responseTopic) {
    store.dispatch(setTopicList(responseTopic));
  }
  store.dispatch(setLoading(false));
};

const changeCategory = async (dispatch, { category }) => {
  dispatch(setCategory(category));
};

const changeTopic = async (dispatch, { topic }) => {
  dispatch(setTopic(topic));
};

const changeRemarks = async (dispatch, { remarks }) => {
  dispatch(setRemarks(remarks));
};

const sendForm = async (dispatch, { category, topic, remarks }) => {
  let formErrors = {};
  if (category === undefined || category === "") {
    formErrors.category = "Category is required!";
  }
  if (topic === undefined || topic === "") {
    formErrors.topic = "Topic is required!";
  }
  if (remarks === undefined || remarks === "") {
    formErrors.remarks = "Remarks is required!";
  }
  dispatch(setFormErrors(formErrors));
  if (Object.keys(formErrors).length == 0) {
    const data = {
      categoryId: category,
      topicId: topic,
      remarks: remarks,
      orderMerchantId: "",
    };
    let response = await HelpCenterRepository.postFeedback(data);

    if (!response.error) {
      PopupUtility.success("Your feedback has been sent.");
      dispatch(setCategory(""));
      dispatch(setTopic(""));
      dispatch(setRemarks(""));
    } else {
      PopupUtility.responseValidate("Failed", response.message);
    }
  }
};

const Action = {
  getCategoryAndTopic,
  changeCategory,
  changeTopic,
  changeRemarks,
  sendForm,
};

export default Action;
