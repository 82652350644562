import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "all-products",
  initialState: {
    loading: false,
    popup: false,
    promotional: false,
    validate: false,
    search: null,
    list: [],
    currentPage: 0,
    hasMoreItems: true,
    id: {},
    fieldName: "updatedAt",
    sortBy: null,
    sortByDirection: null,
    isEmptyList: false,
    size: 5,
    orderBy: "",
    total: 0,
    totalPage: 0,
    categoryList: [],
    categoryId: null
  },
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    setPopup: (state, action) => {
      state.popup = action.payload;
    },
    setPromotional: (state, action) => {
      state.promotional = action.payload;
    },
    setValidate: (state, action) => {
      state.validate = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setHasMoreItems: (state, action) => {
      state.hasMoreItems = action.payload;
    },
    setFieldName: (state, action) => {
      state.fieldName = action.payload;
    },
    setSortByDirection: (state, action) => {
      state.sortByDirection = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setIsEmptyList: (state, action) => {
      state.isEmptyList = action.payload;
    },
    setSize: (state, action) => {
      state.size = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setTotalPage: (state, action) => {
      state.totalPage = action.payload;
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    }
  },
});

export const {
  setList,
  setLoading,
  setSearch,
  setId,
  setPopup,
  setPromotional,
  setValidate,
  setCurrentPage,
  setHasMoreItems,
  setFieldName,
  setSortByDirection,
  setSortBy,
  setIsEmptyList,
  setSize,
  setOrderBy,
  setTotalPage,
  setTotal,
  setCategoryList,
  setCategoryId
} = reducerSlice.actions;
export default reducerSlice;
