import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import StoreHelper from "../../../services/store-helper";
import NavRegister from "./components/NavRegister";
import reducerSlice from "./redux/Reducer";
import { useTranslation } from "react-multi-lang";
import Action from "./redux/Action";
import AccountDetails from "./components/AccountDetails";
import BankDetails from "./components/BankDetails";
import LegalDocuments from "./components/LegalDocuments";
import Security from "./components/Security";
import TermsConditions from "./components/TermsConditions";
import RouteName from "../../../services/routename";
import { Link } from "react-router-dom";

const Register = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  store.getState();

  const MainComponent = () => {
    const page = useSelector((state) => state.reducer.page);
    const t = useTranslation();
    const dispatch = useDispatch();

    const ActivePage = () => {
      if (page === "accountdetails") {
        return <AccountDetails />;
      } else if (page === "bankdetails") {
        return <BankDetails />;
      } else if (page === "legaldocuments") {
        return <LegalDocuments />;
      } else if (page === "security") {
        return <Security />;
      } else if (page === "termsconditions") {
        return <TermsConditions />;
      }
    };

    useEffect(() => {
      Action.getPage(dispatch, "accountdetails");
    }, []);

    return (
      <div className="h-screen w-screen">
        <div className="md:pl-16 pl-5 py-8">
          <img
            className="h-14"
            src={process.env.REACT_APP_ASSETS_IMAGE + "te-logo.svg"}
            alt="Tanam Emas Logo"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 px-1 md:px-16 gap-2 items-center">
          <div className="hidden md:block col-span-12 md:col-span-4">
            <h1 className="leading-none text-gold-primary font-bold">
              Create your
              <br />
              account!
            </h1>
            <h3 className="text-green-white pt-5">
              Apply for your merchant account to start selling your <br />
              products and organize your shop.
            </h3>
          </div>
          <div className="col-span-8">
            <div className="section flex flex-col md:flex-row p-4">
              <div className="md:w-48">
                <NavRegister activePage={page} />
              </div>
              <div className="flex-1">
                <div className="text-md text-gray-secondary font-bold">
                  {t("register.form").toUpperCase()}
                </div>
                <div className="pt-9">
                  <ActivePage />
                </div>
              </div>
            </div>
            <div className="text-center text-white text-md pt-5">
              {t("login.already_account")}
              <Link to={RouteName.login}>
                {" "}
                <span className="text-gold-primary underline underline-offset-4">
                  {t("login.login")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default Register;
